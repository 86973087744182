import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  AskBidQuestionMutation,
  AskBidQuestionMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const askBidQuestion = graphql(`
  mutation AskBidQuestion($req: QualifyQuestionRequest!) {
    askBidQuestion(QualifyQuestionRequest: $req) {
      answer
      sources {
        document
        documentId
        annotations
        chunkNumber
        locations {
          pageNumber
          bbox {
            x
            y
            width
            height
          }
        }
      }
    }
  }
`);

export function useAskBidQuestion(
  options?: UseMutationOptions<
    AskBidQuestionMutation,
    unknown,
    AskBidQuestionMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(askBidQuestion, {
    ...options,
  });

  return { data: data?.askBidQuestion, ...rest };
}
