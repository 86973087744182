import React, { useState } from "react";
import { IdcardOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";

import AccountBriefingButton from "components/actions/GetAccountBriefingButton";
import { TextLink } from "components/actions/Links";
import SaveBuyer from "components/buyers/SaveBuyer";
import { SaveToButton } from "components/opportunities/saving/SaveToButton";
import BuyerCategoriesWithPopover from "components/table_components/BuyerCategoriesWithPopover";
import Property from "lib/core_components/Property";
import { OPPORTUNTIES } from "lib/featureFlags/generated/dvcVariableTypes";
import { useIsBuyerInList } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { EventNames } from "lib/tracking";
import { OpportunityEntityType } from "lib/types/graphQLEnums";
import { BuyerDetails } from "lib/types/models";
import HeaderStatisticCards from "./HeaderStatisticCards";

import css from "./BuyerHeader.module.scss";

export type Props = {
  buyer: BuyerDetails;
  onHeaderChange: ({ trackingType, route }: { trackingType: string; route: string }) => void;
};

export default function BuyerHeader({ buyer, onHeaderChange }: Props): JSX.Element {
  const { enhanced_data, metadata } = buyer;
  const employeeCount = React.useMemo(() => {
    switch (enhanced_data.employee_count) {
      case null:
      case undefined:
      case "Unknown":
        return null;
      default:
        return enhanced_data.employee_count.replace(/ to /, "-");
    }
  }, [enhanced_data.employee_count]);

  const annualSpend = React.useMemo(() => {
    if (!enhanced_data.annual_spend) return null;

    const currency = "£"; // for now we only have this data for uk buyers
    // these are the categories we receive from oscar. Obviously this is less flexible compared with
    // parsing the vlues programatically, but this is pretty easy to maintain and add to as well
    const valueMapping: Record<string, string> = {
      "20 M - 100 M": `${currency}20-100M`,
      "Over 1 Billion": `Over ${currency}1B`,
      "5 M - 20 M": `${currency}5-20M`,
      "100 M - 500 M": `${currency}100-500M`,
      "0 - 1 M": `0-${currency}1M`,
      "500 M - 1 Billion": `${currency}500M - 1B`,
      "1 M - 5 M": `${currency}1-5M`,
    };

    const formattedValue = valueMapping[enhanced_data.annual_spend];

    if (formattedValue) {
      return formattedValue;
    } else {
      return enhanced_data.annual_spend;
    }
  }, [enhanced_data.annual_spend]);

  const hasStotlesBuyerCategories = buyer.categories && buyer.categories.length > 0;

  const url = enhanced_data.url || buyer.url;

  const hasAccountBriefingPermission = window.currentUser?.has_account_briefing_permission;

  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);

  const {
    isSaved: { savedListNames },
  } = useIsBuyerInList(buyer.guid);

  return (
    <div className={css.wrapper}>
      <div className={css.inlineTitleActions}>
        <span className={css.title}>
          <Logo url={metadata?.logo_url} alt={buyer.name} />
          <h1>{buyer.name}</h1>
        </span>

        <span className={css.inlineButtons}>
          {!window.guestUser && !isOpportunitiesEnabled && (
            <SaveBuyer
              buyerGuid={buyer.guid}
              buyerName={buyer.name}
              triggerType="button"
              contextSource="buyer-profile"
            />
          )}
          {!window.guestUser && isOpportunitiesEnabled && (
            <SaveToButton
              entityType="buyer"
              entityId={buyer.guid}
              defaultOpportunityValues={{
                name: buyer.name,
                assignedToId: window.currentUser?.guid,
                entities: [
                  {
                    entityId: buyer.guid,
                    entityType: OpportunityEntityType.Organisation,
                  },
                ],
              }}
              listCount={savedListNames.length}
            />
          )}
          {hasAccountBriefingPermission && (
            <AccountBriefingButton
              briefingType="account"
              buyer={buyer}
              contextName="Header call to action"
            />
          )}
        </span>
      </div>
      <div className={css.stats}>
        <Property className={css.statistic} title="No. of employees" value={employeeCount} />
        <Property className={css.statistic} title="Annual spend" value={annualSpend} />
        <Property
          className={css.statistic}
          title="Location"
          value={enhanced_data.location_summary}
        />

        {hasStotlesBuyerCategories ? (
          <Property
            className={css.statistic}
            title={<>Buyer type </>}
            value={
              <BuyerCategoriesWithPopover
                buyerCategories={buyer.categories}
                buyerName={buyer.name}
                size="default"
              />
            }
          />
        ) : (
          <Property
            className={css.statistic}
            title="Buyer type"
            value={
              enhanced_data.oscar_category ? enhanced_data.oscar_category.join(" > ") : undefined
            }
          />
        )}
        {url && (
          <Property
            className={css.statistic}
            title="Website"
            value={
              <TextLink
                to={url}
                targetType="new-tab"
                showIcon={false}
                eventName={EventNames.buyerUrlClicked}
                eventAttributes={{
                  "Context source": "Basic information header",
                }}
              >
                {url}
              </TextLink>
            }
          />
        )}
      </div>
      <HeaderStatisticCards onHeaderChange={onHeaderChange} buyer={buyer} />
    </div>
  );
}

function Logo({ url, alt }: { url: string | null | undefined; alt: string }) {
  const [isError, setIsError] = useState(false);
  return url && !isError ? (
    <img src={url} height={48} width={48} alt={alt} onError={() => setIsError(true)} />
  ) : (
    <IdcardOutlined className={css.placeholderIcon} />
  );
}
