import React from "react";

import { OpportunityStageSelect } from "../OpportunityStageSelect";
import { FilterContentProps, StyledFilterContent } from "./utils";

function StageFilterContent({ control }: FilterContentProps) {
  return (
    <StyledFilterContent aria-label="Stage filter">
      <OpportunityStageSelect
        name="stageIds"
        control={control}
        label=""
        mode="multiple"
        placeholder="Select stage"
      />
    </StyledFilterContent>
  );
}

export default StageFilterContent;
