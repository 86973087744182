import React, { useCallback, useEffect, useState } from "react";
import { DoubleRightOutlined, SearchOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Drawer } from "antd5";

import FileViewer, {
  FileViewerContextProvider,
  useFileViewerInstance,
} from "../../lib/core_components/FileViewer";
import { DOCUMENT_HIGHLIGHTING_V_2 } from "../../lib/featureFlags";
import { Text } from "../../styles/utility-components";
import { searchText } from "./apryseTextSearcher";
import { Scorecard_Source } from "./types";

import css from "./DocumentViewer.module.scss";

type SourceData = Omit<Scorecard_Source, "__typename">;
type DocumentDrawerProps = {
  open: boolean;
  source: SourceData;
  onClose: () => void;
};

type DocumentViewerProps = {
  documentUrl: string;
  fileExtension?: string;
  source: SourceData;
  open: boolean;
};

export default function DocumentDrawer({ open, source, onClose }: DocumentDrawerProps) {
  const documentUrl = source.documentLink;
  // TODO: this should be done in the backend
  const fileExtension = source.document?.split(".").pop();
  return (
    <FileViewerContextProvider>
      <Drawer
        open={open}
        onClose={onClose}
        styles={{
          body: { padding: 0 },
        }}
        forceRender
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: this property does exist in ant but types are not right
        width={1200}
        closeIcon={null}
      >
        <div className={css.previewWrapper}>
          <div className={css.controls}>
            <Button
              title="Close"
              icon={<DoubleRightOutlined className={css.closeIcon} />}
              onClick={onClose}
              className={css.controlsBtn}
            />
          </div>
          {documentUrl && (
            <DocumentViewer
              documentUrl={documentUrl}
              fileExtension={fileExtension}
              source={source}
              open={open}
            />
          )}
        </div>
      </Drawer>
    </FileViewerContextProvider>
  );
}

function DocumentViewer({ documentUrl, fileExtension, source, open }: DocumentViewerProps) {
  const { instance } = useFileViewerInstance();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [documentReady, setIsDocumentReady] = useState(false);

  const isHighlightingV2Enabled = useVariableValue(DOCUMENT_HIGHLIGHTING_V_2, true);

  const search = useCallback(() => {
    if (!instance) return;

    const { documentViewer } = instance.Core;

    if (!searchTerm) {
      documentViewer.clearSearchResults();
      return;
    }

    if (documentViewer) {
      documentViewer.clearSearchResults();
      searchText(instance, searchTerm);
    }
  }, [instance, searchTerm]);

  useEffect(() => {
    setIsDocumentReady(false);
  }, [documentUrl]);

  useEffect(() => {
    const documentViewer = instance?.Core.documentViewer;
    if (!documentViewer) {
      return;
    }

    const onDocumentReady = () => {
      setIsDocumentReady(true);

      if (!isHighlightingV2Enabled) {
        search();
      }
    };

    // Waiting for documentLoaded event is not sufficient and gives incomplete results
    // Instead, wait for the last event of the document lifecycle
    documentViewer.addEventListener("annotationsLoaded", onDocumentReady);

    // Clean up event listeners
    return () => {
      documentViewer.removeEventListener("annotationsLoaded", onDocumentReady);
    };
  }, [instance, isHighlightingV2Enabled, search]);

  useEffect(() => {
    if (!isHighlightingV2Enabled) {
      return;
    }

    if (!instance || !documentReady) {
      return;
    }

    if (!open) {
      return;
    }

    const { documentViewer, annotationManager, Annotations } = instance.Core;
    annotationManager.deleteAnnotations(annotationManager.getAnnotationsList(), {
      force: true,
    });

    if (!source) {
      documentViewer.setCurrentPage(1, false);
      return;
    }

    for (let lIdx = 0; lIdx < source.locations.length; ++lIdx) {
      const location = source.locations[lIdx];
      const pageNumber = location.pageNumber;
      const bbox = location.bbox;
      if (bbox) {
        const rectAnnotation = new Annotations.RectangleAnnotation({
          PageNumber: pageNumber,
          X: bbox.x - 10,
          Y: bbox.y - 5,
          Width: bbox.width + 20,
          Height: bbox.height + 10,
          StrokeColor: new Annotations.Color(0, 96, 255, 0.8),
          StrokeThickness: 2,
        });
        rectAnnotation.ReadOnly = true;
        if (lIdx === 0) {
          // Jump to the first annotation after it's been drawn
          const jumper = () => {
            annotationManager.jumpToAnnotation(rectAnnotation);
            annotationManager.removeEventListener("annotationsDrawn", jumper);
          };
          annotationManager.addEventListener("annotationsDrawn", jumper);
        }
        annotationManager.addAnnotation(rectAnnotation);
        annotationManager.redrawAnnotation(rectAnnotation);
      } else {
        if (lIdx === 0) {
          // jump to page if there is no bbox
          documentViewer.setCurrentPage(pageNumber, false);
        }
      }
    }
  }, [open, documentReady, instance, source, isHighlightingV2Enabled]);

  useEffect(() => {
    if (isHighlightingV2Enabled) {
      return;
    }
    if (!instance) {
      return;
    }

    if (open) {
      if (documentReady && source?.annotations && source.annotations.length > 0) {
        // only when open and document is ready
        // Just pull the first highlight for now
        setSearchTerm(source.annotations[0] || undefined);
        search();
      }
    } else {
      setSearchTerm(undefined);
    }
  }, [open, documentUrl, documentReady, search, instance, source, isHighlightingV2Enabled]);

  return (
    <div className={css.container}>
      <div className={css.header}>
        <Text h2>{source.document}</Text>
        {instance && (
          <Button
            className={css.searchBtn}
            onClick={() => {
              const isSearchPanelOpen = instance.UI.isElementOpen("searchPanel");

              if (isSearchPanelOpen) {
                instance.UI.closeElements(["searchPanel"]);
              } else {
                instance.UI.openElements(["searchPanel"]);
              }
            }}
          >
            <SearchOutlined />
          </Button>
        )}
      </div>
      <FileViewer
        documentUrl={documentUrl}
        fileExtension={fileExtension}
        disabledElements={[
          "header",
          "toolsHeader",
          "textPopup",
          "contextMenuPopup",
          "printModal",
          "errorModal",
        ]}
      />
    </div>
  );
}
