import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  QualifyBidMutation,
  QualifyBidMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const qualifyBid = graphql(`
  mutation QualifyBid($input: QualifyBidRequest!) {
    qualifyBid(QualifyBidRequest: $input) {
      result {
        groupName
        questions {
          answerId
          title
          answer {
            answer
          }
          sources {
            documentId
            annotations
            chunkNumber
            locations {
              pageNumber
              bbox {
                x
                y
                width
                height
              }
            }
          }
        }
      }
    }
  }
`);

export function useQualifyBid(
  options?: UseMutationOptions<QualifyBidMutation, unknown, QualifyBidMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(qualifyBid, {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
      await queryClient.invalidateQueries({ queryKey: ["bid_qualify_bid"] });
    },
  });

  return { data: data?.qualifyBid, ...rest };
}
