import React, { useCallback, useEffect, useState } from "react";
import { CheckOutlined, DownloadOutlined, DownOutlined, LinkOutlined } from "@ant-design/icons";
import { App, Dropdown, Tooltip } from "antd5";
import { ItemType } from "antd5/es/menu/interface";
import classNames from "classnames";

import { DocumentSlideout } from "components/bid_module/qualification_report_section/DocumentSlideout";
import Button from "components/ui/button/Button";
import { copyToClipboard } from "lib/clipboard";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { useGetBidDocumentUrl } from "lib/hooks/api/bid/bid_document/useGetBidDocumentUrl";
import { useFindBidById } from "lib/hooks/api/bid/bids/useFindBidById";
import { useUpdateBidPublicity } from "lib/hooks/api/bid/bids/useUpdateBidPublicity";
import { getBidDocumentUrlForExternalUser } from "lib/hooks/api/bid/public/getBidDocumentUrlForExternalUser";
import { useGetOrQualifyBid } from "lib/hooks/api/bid/scorecard/useGetOrQualifyBid";
import { useQualifyBid } from "lib/hooks/api/bid/scorecard/useQualifyBid";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { exportDataToCsv } from "../../../lib/exportArrayToCsv";
import { Scorecard_Result_Questions_Sources } from "../../../lib/generated/app-service-gql/graphql";
import { grey700, red500, sysPrimaryDefault, yellow600 } from "../../../lib/themes/colors";
import { Flex, Text } from "../../../styles/utility-components";
import { DetailsContent, DetailsHeader } from "../../app_layout/DetailsLayout";
import LoadingState from "../../buyer_details/question_panel/LoadingState";
import DocumentDrawer from "../DocumentViewer";
import { Scorecard_Source } from "../types";
import StotlesAiModal from "./ask_question_modal/StotlesAiModal";
import { QualificationReportSection } from "./QualificationReportSection";
import { DeleteBidModal, RegenerateScorecardModal } from "./ReportModals";

import css from "./QualificationReport.module.scss";

/**
 * Generates a partial SHA-256 hash (8 char length) from a random 32-byte array.
 * @returns
 */
async function generateRandomSHA256(): Promise<string> {
  // Generate a random 32-byte array (256 bits)
  const randomValues = new Uint8Array(32);
  window.crypto.getRandomValues(randomValues);

  // Hash the random data with SHA-256
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", randomValues);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");

  return hash.slice(0, 8);
}

/**
 * Hook that gets the signature from the URL query parameters, if we get it
 * from the backend we use that instead.
 * @param existingHash
 * @returns
 */
function useSignatureFromURL(): string | null {
  const [signature, setSignature] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const signatureFromURL = queryParams.get("sig"); // Extract the signature
    setSignature(signatureFromURL);
  }, []);

  return signature;
}

export function QualificationReport({
  noticeId,
  procurementStageId,
  noticeTitle,
  buyerName,
  buyerId,
  bidId,
}: {
  noticeId: string;
  procurementStageId: string;
  noticeTitle: string;
  buyerName: string;
  buyerId: string;
  bidId: string;
}) {
  const { logEvent } = useTracking();
  const { message } = App.useApp();
  const [resultData, setResultData] = useState<Scorecard_Source | null>(null);
  const [documentDrawerOpen, setDocumentDrawerOpen] = useState(false);

  // Documents slideout open by default
  const [slideOutOpen, setSlideOutOpen] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLinkCopied, setisLinkCopied] = useState(false);
  const [debug, setDebug] = useState(false);
  const { mutate, isLoading: isQualifying } = useQualifyBid();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const [isAskAiModalOpen, setIsAskAiModalOpen] = useState(false);

  const urlShareSignature = useSignatureFromURL();

  const {
    data,
    isLoading: isQuerying,
    refetch,
  } = useGetOrQualifyBid(
    {
      bidId,
      procurementStageId,
    },
    urlShareSignature, // For checking whether or not the user can access the report
  );

  // Just using this query for now to determine read/write access
  const { data: bidInfo } = useFindBidById(bidId, {
    enabled: !isQuerying && !!data && !!window.currentUser,
  });

  const { mutateAsync: getBidDocumentUrlMutation } = useGetBidDocumentUrl();

  const { mutate: updateBidPublicity } = useUpdateBidPublicity(procurementStageId);

  const onSelectDocument = useCallback(
    async (
      source: Scorecard_Result_Questions_Sources,
      trackingContext: EventNames,
      trackingData: EventData,
    ) => {
      if (!source.documentId) {
        return;
      }

      let documentUrl = "";

      if (window.currentUser) {
        const { getBidDocumentUrl } = await getBidDocumentUrlMutation({ id: source.documentId });

        documentUrl = getBidDocumentUrl.documentUrl;
      } else {
        if (!urlShareSignature) {
          console.error("No signature found");
          return;
        }
        const resp = await getBidDocumentUrlForExternalUser(
          bidId,
          source.documentId,
          urlShareSignature,
        );
        documentUrl = resp.documentUrl;
      }

      setDocumentDrawerOpen(true);
      setResultData({
        ...source,
        documentLink: documentUrl,
      });

      logEvent(trackingContext, {
        ...trackingData,
        "Record GUID": noticeId,
      });
    },
    [bidId, getBidDocumentUrlMutation, logEvent, noticeId, urlShareSignature],
  );

  const hasEditAccess =
    window.currentUser && window.currentUser?.company.guid === bidInfo?.companyId;

  const handleCSVExport = React.useCallback(() => {
    if (data) {
      const questionsAndAnswers = data.flatMap((item) =>
        item.questions.map((question) => ({
          question: question.title,
          answer: question.answer?.answer || null,
        })),
      );
      const csvData = [
        { question: "Scorecard link", answer: window.location.href },
        { question: "Question", answer: "Answer" },
        ...questionsAndAnswers,
      ];
      exportDataToCsv("scorecard.csv", csvData, false);

      logEvent(EventNames.exportedData, {
        "Record GUID": noticeId,
        "Action type": "Button clicked",
        "Data type": "Bid qualification scorecard",
      });
    }
  }, [data, logEvent, noticeId]);

  const isLoading = isQuerying || isQualifying;

  // Updated clipboard copy logic
  async function copySignedUrl() {
    let shareUrl = window.location.href;

    // Generate the hash
    const signature = urlShareSignature ?? (await generateRandomSHA256());

    if (!shareUrl.includes(signature)) {
      // Append the hash as a query parameter (e.g., ?s=...), if it doesn't
      // exist in the url already
      shareUrl = `${shareUrl}${shareUrl.includes("?") ? "&" : "?"}sig=${signature}`;
    }

    const clipboardContent = {
      plain: shareUrl,
    };

    const copiedToClipboard = copyToClipboard(clipboardContent);

    if (copiedToClipboard) {
      logEvent(EventNames.qualReportLinkCopied, {
        "Action type": "Link clicked",
      });
      setisLinkCopied(true);

      if (!urlShareSignature) {
        updateBidPublicity({ input: { bidId, publicHash: signature } });
      }

      return;
    }

    void message.error(`Could not copy link to clipboard`, 2);
  }

  const menuItems: ItemType[] = [
    {
      key: "shareLink",
      label: isLinkCopied ? (
        <span className={css.menuItem} style={{ color: sysPrimaryDefault }}>
          <CheckOutlined />
          Link copied
        </span>
      ) : (
        <span
          className={css.menuItem}
          onClick={async () => copySignedUrl()}
          style={{ color: grey700 }}
        >
          <LinkOutlined />
          Copy link to report
        </span>
      ),
    },
    {
      key: "download",
      label: (
        <span className={css.menuItem} onClick={handleCSVExport} style={{ color: grey700 }}>
          <DownloadOutlined />
          Download CSV
        </span>
      ),
      disabled: !data || data.length == 0,
    },
  ];

  if (isLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100vw"
        flexDirection="column"
      >
        <LoadingState />
        <Text h2>Analysing documents...</Text>
      </Flex>
    );
  }

  return (
    <div className={css.qualificationReportPage}>
      <DetailsHeader className={css.header}>
        <div
          className={classNames(css.headerActions, {
            [css.extraPadding]: !window.currentUser,
          })}
        >
          {hasEditAccess && (
            <Tooltip title={"Regenerate report"} placement="bottom">
              <Button
                onClick={() => setIsRegenerateModalOpen(true)}
                className={css.actionButton}
                icon="refreshCw03"
                buttonStyle="inverse"
                iconProps={{ color: sysPrimaryDefault }}
              />
            </Tooltip>
          )}
          {!window.currentUser && (
            <Tooltip title={"Log in to collaborate"} placement="bottom">
              <span className={css.viewOnlyText}>View-only</span>
            </Tooltip>
          )}
          <Tooltip title={slideOutOpen ? "Hide documents" : "View documents"} placement="bottom">
            <Button
              as="button"
              className={css.actionButton}
              onClick={() => {
                if (!slideOutOpen) {
                  logEvent(EventNames.tenderDocumentsTrayOpened, {
                    "Record GUID": noticeId,
                    "Action type": "Button clicked",
                  });
                }
                setSlideOutOpen(!slideOutOpen);
              }}
              icon="folder"
              buttonStyle="inverse"
              iconProps={{ color: sysPrimaryDefault }}
            />
          </Tooltip>
          <Dropdown
            menu={{ items: menuItems }}
            overlayStyle={{ minWidth: "170px" }}
            open={isDropdownOpen}
            disabled={!data || data.length == 0}
            mouseLeaveDelay={0.5}
            onOpenChange={(open) => {
              if (!open) {
                setTimeout(() => {
                  setDropdownOpen(false);
                  setisLinkCopied(false); // Reset state when closing
                }, 500); // Delay for "Link copied" message visibility
              } else {
                setDropdownOpen(open);
              }
            }}
          >
            <Button
              className={css.menuItem}
              buttonType="primary"
              disabled={!data || data.length == 0}
              style={{ backgroundColor: sysPrimaryDefault }}
            >
              Share <DownOutlined />
            </Button>
          </Dropdown>
          {hasEditAccess && (
            <Tooltip title={"Delete report"} placement="bottom">
              <Button
                as="button"
                buttonType="tertiary"
                onClick={() => setIsDeleteModalOpen(true)}
                className={css.deleteButton}
                icon="trash03"
                iconProps={{ color: red500 }}
              />
            </Tooltip>
          )}
        </div>
      </DetailsHeader>
      <DetailsContent className={css.pageContent}>
        <div className={css.scoreCardWrapper}>
          <div className={css.noticeHeader}>
            <div className={css.titleContent}>
              <div>
                <Text h1 className={css.scorecardTitle}>
                  Qualification Report
                </Text>
                {/* Not using wouter link because of an external user comes in here, we need to direct them to SEO - wouter links don't do that */}
                <EllipsisTooltipTextLink
                  fullText={noticeTitle}
                  containerClassname={css.linkTitle}
                  linkText={noticeTitle}
                  linkProps={{ to: `/records/${noticeId}` }}
                />
                <EllipsisTooltipTextLink
                  fullText={buyerName}
                  containerClassname={classNames(css.linkTitle, css.buyerName)}
                  linkText={buyerName}
                  linkProps={{ to: `/buyers/${buyerId}` }}
                />
              </div>
              {/* For now, only allowing logged in users because we only have a graphql endpoint */}
              {!!window.currentUser && (
                <Button
                  buttonType="secondary"
                  onClick={() => {
                    logEvent(EventNames.stotlesAiOpened, {
                      "Bid ID": bidId,
                      "Record GUID": noticeId,
                    });
                    setIsAskAiModalOpen(true);
                  }}
                  iconProps={{ size: 16, color: yellow600 }}
                  icon={"stars2"}
                  className={css.askAIBtn}
                  blackText
                >
                  Ask StotlesAI
                </Button>
              )}
            </div>
          </div>
          <p className={css.disclaimerText}>
            AI-generated responses may be inaccurate. Verify important information.
          </p>
          <div className={css.qaReportContent}>
            {data?.map((section) => (
              <QualificationReportSection
                key={section.groupName}
                scoreCardResult={section}
                procurementStageId={procurementStageId}
                bidId={bidId}
                debugMode={debug}
                onSelectDocument={onSelectDocument}
                slideOutOpen={slideOutOpen}
              />
            ))}
          </div>

          {window.currentUser?.admin && (
            <Flex gap="24px">
              <Button as="button" buttonType="secondary" onClick={() => setDebug(!debug)}>
                Toggle debug
              </Button>
            </Flex>
          )}
        </div>
        {slideOutOpen && (
          <DocumentSlideout
            bidId={bidId}
            urlShareSignature={urlShareSignature}
            onSelectDocument={(source) =>
              onSelectDocument(source, EventNames.tenderDocumentOpened, {
                "Action type": "Document reader opened",
                "Document name": source.document,
              })
            }
          />
        )}
        {resultData && (
          <DocumentDrawer
            open={documentDrawerOpen}
            source={resultData}
            onClose={() => setDocumentDrawerOpen(false)}
          />
        )}
      </DetailsContent>
      <DeleteBidModal
        bidId={bidId}
        noticeId={noticeId}
        procurementStageId={procurementStageId}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <RegenerateScorecardModal
        isOpen={isRegenerateModalOpen}
        onClose={() => setIsRegenerateModalOpen(false)}
        regenerateBidMutation={() =>
          mutate(
            { input: { procurementStageId, bidId } },
            {
              onSuccess: async () => {
                await refetch();
              },
            },
          )
        }
      />
      <StotlesAiModal
        bidId={bidId}
        procurementStageId={procurementStageId}
        isOpen={isAskAiModalOpen}
        onClose={() => setIsAskAiModalOpen(false)}
        onSelectDocument={onSelectDocument}
        documentDrawerOpen={documentDrawerOpen}
      />
    </div>
  );
}
