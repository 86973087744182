import React, { useCallback, useMemo, useState } from "react";
import { DeleteOutlined, EditOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, message, Pagination } from "antd5";
import isEqual from "lodash.isequal";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  convertDocumentFiltersToSearchRequest,
  DEFAULT_PAGINATION,
  DocumentColumns,
  DocumentFilters,
  getDocumentTypeLabel,
  isValidSortField,
} from "components/documents/utils";
import PaywallPopover from "components/paywall/PaywallPopover";
import OrganisationsContainer from "components/record_details/OrganisationsPopover";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { NewRelevanceScore } from "lib/core_components/NewRelevanceScore";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { Table } from "lib/core_components/Table/Table";
import TableBanner from "lib/core_components/TableBanner";
import { DeleteDocumentModal } from "lib/documents/DocumentComponents";
import {
  DocumentsDto,
  DocumentsSearchResponse,
  useDocuments,
  UseSearchDocuments,
} from "lib/hooks/api/documents/useDocuments";
import { useDeleteDocument } from "lib/hooks/api/useDeleteDocument";
import DocumentIcon from "lib/icons/DocumentIcon";
import { ExternalLink } from "lib/icons/ExternalLink";
import PaywallStar from "lib/icons/PaywallStar";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { white } from "lib/themes/colors";
import { EventNames, useTracking } from "lib/tracking";
import { SignalEntityType } from "lib/utils/signalUtils";
import { useURLState } from "../../lib/hooks/useURLState";
import { usePaywall } from "../../lib/providers/Paywall";
import DocumentDrawer from "../document_details/DocumentDrawer";
import { DocumentViewerFilters } from "../document_details/FilterForm";
import { EditDocumentModal } from "./EditDocumentModal";
import { useDocumentsTracking } from "./tracking";

import css from "./DocumentTable.module.scss";

import DocumentSvg from "../../../assets/images/document.svg";

type SortState = Exclude<DocumentFilters["sort"], undefined>;

type DocumentResult = DocumentsSearchResponse["results"][0];

type Props = {
  filters: DocumentFilters;
  columnSettings: DocumentColumns[];
  selectedRows?: string[];
  onSortChange: (sort: SortState) => void;
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: DocumentsDto[]) => void;
  emptyText: JSX.Element;
  showBanner?: boolean;
  disablePreview?: boolean;
  documentProvider?: UseSearchDocuments;
};

function getSortOrder(key: string, sort?: SortState): "ascend" | "descend" | null {
  return sort?.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

function getDocumentColumns({
  filters,
  columnSettings,
  onDocumentClick,
  hasDocumentsDataType,
  disablePreview,
  setDocumentToDelete,
  setDocumentToEdit,
}: {
  filters: DocumentFilters;
  columnSettings: DocumentColumns[];
  onDocumentClick: (document: DocumentResult) => void;
  hasDocumentsDataType: boolean;
  disablePreview?: boolean;
  setDocumentToDelete: (document: DocumentResult) => void;
  setDocumentToEdit: (document: DocumentResult) => void;
}) {
  const sort = filters.sort;

  const title: ColumnType<DocumentsDto> = {
    title: "Document title",
    dataIndex: "title",
    key: "title",
    render: (_, d) => (
      <PaywallPopover featureType="DOCUMENTS">
        <div className={css.titleColumn}>
          <div className={css.titleContainer}>
            <DocumentIcon className={css.documentIcon} />
            <div className={css.title}>
              <EllipsisTooltipTextLink
                fullText={d.title ?? "-"}
                linkProps={{
                  to: `/documents/${d.id}`,
                  onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disablePreview) {
                      onDocumentClick(d);
                    }
                  },
                  className: css.titleText,
                }}
                linkText={d.title}
              />
            </div>
          </div>
          {!hasDocumentsDataType && <PaywallStar className={css.paywallStar} />}
        </div>
      </PaywallPopover>
    ),
    sorter: stringSort((d) => d.title),
    sortOrder: getSortOrder("title", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    sizeConfig: {
      small: 200,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  const buyers: ColumnType<DocumentsDto> = {
    ...commonTableColumns.titleColumn,
    title: "Buyer(s)",
    dataIndex: "buyers",
    key: "buyers",
    render: (_, d) => <OrganisationsContainer orgs={d.buyers ?? []} orgType="buyers" />,
    sizeConfig: {
      small: 200,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  const signalScore: ColumnType<DocumentsDto> = {
    ...commonTableColumns.relevanceColumn,
    title: "Signal score",
    key: "signalScore",
    dataIndex: "signalScore",
    render: (_, d) => (
      <NewRelevanceScore
        relevanceScore={d.signalScore}
        popoverPlacement="bottomLeft"
        id={d.id}
        entityType={SignalEntityType.Document}
      />
    ),
    sorter: numberSort((d: DocumentsDto) => d.signalScore),
    sortOrder: getSortOrder("signalScore", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: {
      title:
        "BETA: This score is calculated based on the strength of your signal settings against this document.",
    },
  };

  const category: ColumnType<DocumentsDto> = {
    title: "Type",
    dataIndex: "category",
    key: "category",
    render: (_, d) => <span>{d.category ? getDocumentTypeLabel(d.category) : "-"}</span>,
    sorter: stringSort((d) => d.category),
    sortOrder: getSortOrder("category", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const publishedAt: ColumnType<DocumentsDto> = {
    title: "Published",
    dataIndex: "publishedAt",
    key: "publishedAt",
    render: (_, d) => (d.publishedAt ? d.publishedAt.split("T")[0] : "-"),
    sorter: stringSort((d) => d.publishedAt),
    sortOrder: getSortOrder("publishedAt", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const userEmail: ColumnType<DocumentsDto> = {
    title: "User email",
    key: "userEmail",
    render: (d) => <div>{d.createdBy ? d.createdBy.email : "Scraped"}</div>,
  };

  const createdAt: ColumnType<DocumentsDto> = {
    title: "Created",
    key: "createdAt",
    render: (d) => <div>{d.createdAt.split("T")[0]}</div>,
    sorter: true,
    sortOrder: getSortOrder("createdAt", sort),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: false,
  };

  const actions: ColumnType<DocumentsDto> = {
    title: "Actions",
    key: "actions",
    render: (d) => (
      <div className={css.actions}>
        <a
          href={d.pdfDocumentUrl}
          target="_blank"
          download
          title="Open in new tab"
          className={css.download}
          onClick={(e) => {
            e.stopPropagation();
          }}
          rel="noreferrer"
        >
          <ExportOutlined />
        </a>
        <EditOutlined
          title="Edit document"
          onClick={(e) => {
            setDocumentToEdit?.(d);
            e.stopPropagation();
          }}
        />
        <DeleteOutlined
          title="Delete document"
          onClick={(e) => {
            setDocumentToDelete?.(d);
            e.stopPropagation();
          }}
          className={css.delete}
        />
      </div>
    ),
  };

  const columns = {
    title,
    buyers,
    signalScore,
    category,
    publishedAt,
    userEmail,
    createdAt,
    actions,
  };

  return columnSettings.map((c) => columns[c]);
}

export function DocumentTable({
  filters,
  columnSettings,
  onSortChange,
  emptyText,
  showBanner,
  disablePreview,
  documentProvider = useDocuments,
}: Props) {
  const { logEvent } = useTracking();
  const documentsTracking = useDocumentsTracking();

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedDocument, setSelectedDocument] = React.useState<DocumentResult | undefined>(
    undefined,
  );
  const [documentToDelete, setDocumentToDelete] = React.useState<DocumentResult | undefined>(
    undefined,
  );
  const [documentToEdit, setDocumentToEdit] = React.useState<DocumentResult | undefined>(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [, setFilters] = useURLState<DocumentViewerFilters>("docViewerFilters", { keywords: [] });
  const { authorised: hasDocumentsDataType } = useCheckSubscription("DOCUMENTS");
  const { openPaywallModal } = usePaywall();

  const { data, isLoading, isError } = documentProvider(
    convertDocumentFiltersToSearchRequest(filters, pagination),
  );

  const { mutate: deleteDocument, isLoading: isDeleteDocumentLoading } = useDeleteDocument({
    onSuccess: () => message.success("Document deleted successfully!"),
    onError: () => message.error("Sorry, an error has occurred while deleting this document"),
  });

  const onDocumentClick = useCallback(
    (document: DocumentResult) => {
      if (!hasDocumentsDataType) {
        openPaywallModal("DOCUMENTS");
      } else {
        setSelectedDocument((prevSelectedDocument) => {
          if (prevSelectedDocument?.id !== document.id) {
            setFilters({ keywords: [] });
          }
          return document;
        });
        setDrawerOpen(true);
        documentsTracking.documentPreviewOpened(document.category ?? "-");
      }
    },
    [documentsTracking, hasDocumentsDataType, openPaywallModal, setFilters],
  );

  const columns = useMemo(() => {
    return getDocumentColumns({
      filters,
      columnSettings,
      onDocumentClick,
      hasDocumentsDataType,
      disablePreview,
      setDocumentToDelete,
      setDocumentToEdit,
    });
  }, [filters, columnSettings, onDocumentClick, hasDocumentsDataType, disablePreview]);

  useDeepCompareEffect(() => {
    setPagination(DEFAULT_PAGINATION);
  }, [filters]);

  function closeDocumentDrawer() {
    setDrawerOpen(false);
  }

  return (
    <div>
      <Table<DocumentsDto>
        ariaLabel="Document Table"
        dataSource={data?.results}
        loading={isLoading}
        columns={columns}
        showSorterTooltip={false}
        rowKey="id"
        onRow={(document) => ({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!disablePreview) {
              onDocumentClick(document);
            }
          },
        })}
        onChange={(_, __, sorter) => {
          if (Array.isArray(sorter)) {
            sorter = sorter[0];
          }
          // If the sort order is not defined, we don't want to trigger a sort without an order
          if (!filters.sort && !sorter.order) {
            return;
          }

          const sortField = isValidSortField(sorter.columnKey?.toString());
          if (sortField) {
            const newSortState: SortState = {
              order: sorter.order === "descend" ? "DESC" : "ASC",
              field: sortField,
            };

            if (!isEqual(filters.sort, newSortState)) {
              onSortChange?.(newSortState);
            }
          }
        }}
        isError={isError}
        locale={{
          emptyText,
        }}
      />
      {data?.totalResults !== undefined && data.totalResults > 0 && (
        <>
          {showBanner && (
            <TableBanner
              title="Can't see the document you're looking for?"
              description="We're still in the process of collecting some document types across certain buyers. You can check our progress in our tracker."
              graphic={<img src={DocumentSvg} alt="Document" className={css.bannerImg} />}
              ctaElement={
                <Button
                  type="primary"
                  href="https://stotles.notion.site/Documents-coverage-591e15112c894a92a78a19827df46237"
                  target="_blank"
                  icon={<ExternalLink fill={white} className={css.linkIcon} />}
                >
                  Open progress tracker
                </Button>
              }
            />
          )}
          <Pagination
            className={css.pagination}
            current={pagination.current}
            defaultPageSize={pagination.pageSize}
            total={data.totalResults}
            onChange={(page: number, pageSize: number) => {
              setPagination({ current: page, pageSize });
              logEvent(EventNames.paginationSelected, { Value: page });
            }}
          />
        </>
      )}
      <DocumentDrawer
        documentId={selectedDocument?.id}
        filters={filters}
        onClose={closeDocumentDrawer}
        open={drawerOpen}
      />
      <DeleteDocumentModal
        isOpen={!!documentToDelete}
        fileName={documentToDelete?.title ?? "-"}
        onDelete={() => deleteDocument({ id: documentToDelete?.id ?? "" })}
        onClose={() => setDocumentToDelete(undefined)}
        isLoading={isDeleteDocumentLoading}
      />
      {documentToEdit && (
        <EditDocumentModal
          selectedDocument={documentToEdit}
          isOpen={!!documentToEdit}
          onClose={() => setDocumentToEdit(undefined)}
        />
      )}
    </div>
  );
}
