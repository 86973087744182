import * as React from "react";
import { useMemo } from "react";

import { TextLink } from "components/actions/Links";
import { DetailsHeader } from "components/app_layout/DetailsLayout";
import FrameworkStage from "components/framework/FrameworkStage";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { TextHighlighter } from "lib/core_components/TextHighlighter";
import { FrameworkDetails } from "lib/hooks/api/frameworks/useDescribeFramework";
import FrameworkIcon from "lib/icons/FrameworkIcon";
import VerifiedProviderIcon from "lib/icons/VerifiedProviderIcon";
import { EventNames, logEvent } from "lib/tracking";
import { OpportunityEntityType, Stages } from "lib/types/graphQLEnums";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { SaveToButton } from "../opportunities/saving/SaveToButton";
import { ScheduleReminderButton } from "../reminders/ScheduleReminderButton";

import css from "./FrameworkHeader.module.scss";

type Props = {
  framework: FrameworkDetails | undefined;
  title?: string[];
};

export default function FrameworkHeader({ framework, title }: Props): JSX.Element {
  return (
    <DetailsHeader className={css.headerContent} borderBottom={false}>
      <span className={css.topLink}>
        <TextLink
          to="/frameworks"
          className={css.backLink}
          onClick={() =>
            logEvent(EventNames.breadcrumbClicked, {
              "Breadcrumb label": "Frameworks",
            })
          }
        >
          Frameworks
        </TextLink>
        <span>/</span>
      </span>
      {framework ? (
        <div className={css.titleContainer}>
          <FrameworkIcon className={css.icons} />
          <div aria-label="framework-header-content">
            <FrameworkHeaderStage framework={framework} />
            <div className={css.title}>
              <h1>
                <TextHighlighter content={framework?.title} wordsToHighlight={title} />
              </h1>
              {framework?.validatedByProvider && (
                <VerifiedProviderIcon className={css.icons} ariaLabel="framework-verified-icon" />
              )}
            </div>
          </div>
          <div className={css.rightSide}>
            <SaveToButton
              entityType="framework"
              entityId={framework.id}
              defaultOpportunityValues={{
                name: framework.title,
                assignedToId: window.currentUser?.guid,
                entities: [
                  {
                    entityId: framework.id,
                    entityType: OpportunityEntityType.Framework,
                  },
                ],
              }}
            />
            <ScheduleReminderButton
              dataType="framework"
              dataId={framework.id}
              dataTitle={framework.title}
              anchorDate={getFrameworkEndDate(framework)}
            />
          </div>
        </div>
      ) : (
        <CentredSpinner />
      )}
    </DetailsHeader>
  );
}

type FrameworkHeaderStageProps = {
  framework: FrameworkDetails;
};

function getFrameworkEndDate(framework: FrameworkDetails) {
  if (
    [Stages.Awarded, Stages.Live, Stages.Expired].includes(framework.stage) &&
    framework.contractPeriod?.endDate
  ) {
    return framework.contractPeriod.endDate;
  }
  if (framework.stage === Stages.Tendering && framework.tenderPeriod?.endDate) {
    return framework.tenderPeriod.endDate;
  }
}
/**
 * Displays stage of the framework and contextual date information
 * @param param0
 * @returns
 */
function FrameworkHeaderStage({ framework }: FrameworkHeaderStageProps): JSX.Element {
  const relativetimeContent = useMemo((): React.ReactNode => {
    if (
      [Stages.Awarded, Stages.Live, Stages.Expired].includes(framework.stage) &&
      framework.contractPeriod?.endDate
    ) {
      return (
        <>
          {/* Only doing it this way to ensure 4px gap */}
          <span>•</span>
          <span>
            {framework.stage === Stages.Expired ? "Ended" : "Ending"}{" "}
            {toHumanRelativeTimeFromNow(new Date(framework.contractPeriod.endDate))}
          </span>
        </>
      );
    } else if (framework.stage === Stages.Tendering && framework.tenderPeriod?.endDate) {
      return (
        <>
          {/* Only doing it this way to ensure 4px gap */}
          <span>•</span>
          <span>
            Closing in {toHumanRelativeTimeFromNow(new Date(framework.tenderPeriod.endDate))}
          </span>
        </>
      );
    }
  }, [framework.contractPeriod?.endDate, framework.stage, framework.tenderPeriod?.endDate]);

  return (
    <span className={css.stageAndRelativeTime}>
      <FrameworkStage stage={framework.stage} />
      {relativetimeContent}
    </span>
  );
}
