import * as React from "react";
import { Tooltip } from "antd5";
import { AbstractTooltipProps } from "antd5/es/tooltip";
import classnames from "classnames";
// this is provided to us because it is a dependency of rc-resize-observer (which is used by several
// of ant's components).
//
// Browser compatibility for the native API: https://caniuse.com/?search=ResizeObserver
//  - it's mostly ok, though pre-chromium edge is probably the main exception (possibly some of the
//  older other browser too).
//  - note that typescript doesn't yet support ResizeObserver by default
//  (https://github.com/microsoft/TypeScript/issues/28502), but this also has the added benefit of
//  giving us types
import ResizeObserver from "resize-observer-polyfill";
import { Link } from "wouter";

import { TextLink, TextLinkProps } from "components/actions/Links";

import css from "./EllipsisTooltip.module.scss";

type EllipsisTooltipProps = {
  fullText: string;
  tooltipProps?: AbstractTooltipProps;
  containerClassname?: string;
  noWrap?: boolean;
};

/**
 * Show some text with ellipsis, and a tooltip revealing the full text on hover, if the ellipsis is
 * active.
 *
 * This is the base component, and then there are some more specific implementations below -
 * the reason being is that it is easier when the type of children are known and to some degree
 * controlled, as opposed to having a fully-flexible generic element.
 */
function EllipsisTooltip({
  fullText,
  tooltipProps,
  containerClassname,
  noWrap,
  children,
}: React.PropsWithChildren<EllipsisTooltipProps>): JSX.Element {
  const [spanElem, setSpanElem] = React.useState<HTMLSpanElement | null>(null);
  const [hasTooltip, setHasTooltip] = React.useState(false);
  const resizeObserver = React.useMemo(() => {
    return new ResizeObserver((entries) => {
      for (const entry of entries) {
        const elem = entry.target as HTMLSpanElement;
        setHasTooltip(elem.offsetWidth < elem.scrollWidth || elem.offsetHeight < elem.scrollHeight);
      }
    });
  }, []);

  React.useEffect(() => {
    if (spanElem) {
      setHasTooltip(
        spanElem.offsetWidth < spanElem.scrollWidth ||
          spanElem.offsetHeight < spanElem.scrollHeight,
      ); // set initial value
      resizeObserver.observe(spanElem);
      return () => {
        resizeObserver.unobserve(spanElem);
      };
    }
  }, [spanElem, resizeObserver]);

  const content = (
    <span
      className={classnames(css.ellipsis, { [css.noWrap]: noWrap }, containerClassname)}
      ref={(elem) => setSpanElem(elem)}
    >
      {children}
    </span>
  );

  if (hasTooltip) {
    return (
      <Tooltip {...tooltipProps} title={fullText}>
        {content}
      </Tooltip>
    );
  } else {
    return content;
  }
}

export function EllipsisTooltipTextLink({
  fullText,
  linkText,
  linkProps,
  tooltipProps,
  noWrap,
  containerClassname,
}: {
  linkProps: TextLinkProps;
  tooltipProps?: EllipsisTooltipProps["tooltipProps"];
  fullText: string;
  linkText: React.ReactNode;
  noWrap?: boolean;
  containerClassname?: string;
}): JSX.Element {
  return (
    <EllipsisTooltip
      fullText={fullText}
      tooltipProps={tooltipProps}
      noWrap={noWrap}
      containerClassname={containerClassname}
    >
      <TextLink {...linkProps}>{linkText}</TextLink>
    </EllipsisTooltip>
  );
}

export function EllipsisTooltipWouterLink({
  fullText,
  linkText,
  linkProps,
  tooltipProps,
  noWrap,
  containerClassname,
}: {
  linkProps: TextLinkProps;
  tooltipProps?: EllipsisTooltipProps["tooltipProps"];
  fullText: string;
  linkText: string;
  noWrap?: boolean;
  containerClassname?: string;
}): JSX.Element {
  return (
    <EllipsisTooltip
      containerClassname={containerClassname}
      fullText={fullText}
      tooltipProps={tooltipProps}
      noWrap={noWrap}
    >
      <Link {...linkProps}>{linkText}</Link>
    </EllipsisTooltip>
  );
}

export function EllipsisTooltipText({
  fullText,
  childText,
  tooltipProps,
  textProps,
  noWrap,
  containerClassname,
}: {
  fullText: string;
  childText?: string;
  tooltipProps?: EllipsisTooltipProps["tooltipProps"];
  textProps?: React.HTMLAttributes<HTMLParagraphElement>;
  noWrap?: boolean;
  containerClassname?: string;
}): JSX.Element {
  return (
    <EllipsisTooltip
      fullText={fullText}
      tooltipProps={tooltipProps}
      noWrap={noWrap}
      containerClassname={containerClassname}
    >
      <p {...textProps} style={{ display: "inline", ...textProps?.style }}>
        {childText ?? fullText}
      </p>
    </EllipsisTooltip>
  );
}
