import React from "react";
import styled from "@emotion/styled";

import { FeatureType } from "components/paywall/paywallUtils";
import { usePaywall } from "lib/providers/Paywall";
import { useSubscription } from "lib/providers/Subscription";
import { EventNames, useTracking } from "lib/tracking";
import { DataTypeNameWithBuyersAndSuppliers } from "lib/types/models";
import { plus } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysPrimaryDefault } from "../../../lib/themes/colors";
import { Flex } from "../../../styles/utility-components";

type Entities = "notice" | "document" | "framework" | "buyer" | "supplier" | "contact";

type Props = {
  entity: Entities;
  url: string;
};

const entityToDataType = (entity: Entities): DataTypeNameWithBuyersAndSuppliers => {
  switch (entity) {
    case "notice":
      return "TENDERS|PRE-TENDERS";
    case "document":
      return "DOCUMENTS";
    case "framework":
      return "FRAMEWORKS";
    case "buyer":
      return "BUYERS";
    case "supplier":
      return "SUPPLIERS";
    case "contact":
      return "CONTACTS";
  }
};

const dataTypeToPaywall = (dataType: DataTypeNameWithBuyersAndSuppliers): FeatureType | null => {
  // Not including BUYERS since the core feature is free which includes adding buyers to an opportunity
  if (["DOCUMENTS", "FRAMEWORKS", "SUPPLIERS", "CONTACTS"].includes(dataType)) {
    return dataType as FeatureType;
  }
  return null;
};

export default function OpportunitySavedEmptyState({ entity, url }: Props) {
  const { logEvent } = useTracking();
  const { openPaywallModal } = usePaywall();
  const subscription = useSubscription();

  const dataType = entityToDataType(entity);
  const hasDataType = subscription.hasDataTypes(dataType);
  const paywall = dataTypeToPaywall(dataType);
  const hasPaywall = paywall && !hasDataType;

  const onClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    if (hasPaywall) {
      evt.preventDefault();
      openPaywallModal(paywall);
    } else {
      logEvent(EventNames.addOpportunityDataClicked, {
        "Data type": entity.toUpperCase(),
      });
    }
  };

  return (
    <StyledLink href={hasPaywall ? undefined : url} onClick={onClick}>
      <Card>
        <UIcon svg={plus} color={sysPrimaryDefault} />
        Add {entity}s
      </Card>
    </StyledLink>
  );
}

const Card = styled(Flex)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,

  height: 32,
  width: "100%",

  padding: 24,

  borderRadius: 4,
  backgroundImage: `repeating-linear-gradient(90deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(90deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px)`,
  backgroundPosition: "left top, right top, left bottom, left top",
  backgroundRepeat: "repeat-x, repeat-y, repeat-x, repeat-y",
  backgroundSize: "100% 1px, 1px 100%, 100% 1px, 1px 100%",

  transition: "all 0.2s ease-in-out",
}));

const StyledLink = styled.a(({ theme }) => ({
  all: "unset",
  cursor: "pointer",
  display: "block",
  width: "100%",
  color: theme.colors.sysPrimaryDefault,

  "&:hover": {
    color: theme.colors.sysPrimaryDefault,
    [`${Card}`]: {
      backgroundColor: theme.colors.sysPrimarySubtle,
      backgroundImage: `repeating-linear-gradient(90deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(90deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px)`,
    },
  },
}));
