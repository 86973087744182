import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  DeleteBidMutation,
  DeleteBidMutationVariables,
} from "../../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const deleteBid = graphql(`
  mutation deleteBid($input: BidDeleteRequest!) {
    deleteBid(BidDeleteRequest: $input) {
      id
    }
  }
`);

export function useDeleteBid(
  procurementStageId: string,
  options?: UseMutationOptions<DeleteBidMutation, unknown, DeleteBidMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useGraphQlMutation(deleteBid, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      void queryClient.invalidateQueries(["bid", variables.input.bidId]);
      void queryClient.invalidateQueries(["bids", [procurementStageId]]);
    },
  });
  return { data: data?.deleteBid, ...rest };
}
