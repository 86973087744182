import { isEmpty } from "lodash";
import qs from "qs";

import {
  OpportunityWorkflow_Transitions,
  SearchOpportunitiesRequest,
} from "lib/generated/app-service-gql/graphql";
import { parseBooleanValue, parseNumberValue } from "lib/hooks/useURLState";
import { routes } from "lib/routes";
import {
  OpportunitySortBy,
  OpportunitySortOrder,
  OpportunityWorkflowTransitionGroup,
} from "lib/types/graphQLEnums";
import { CountryRegion } from "lib/types/models";
import { OpportunityDetails } from "../../lib/hooks/api/opportunities/useOpportunity";

type DateFilter = {
  filter?: { from?: string; relativeFrom?: string; to?: string; relativeTo?: string };
  hideNulls?: boolean;
};
export type ValueFilter = { from?: number; to?: number; hideNulls?: boolean };

export type OpportunityFilters = {
  searchText?: string;
  assignedToIds?: string[];
  stageIds?: string[];
  closeDate?: DateFilter;
  lastUpdatedDate?: DateFilter;
  value?: ValueFilter;
  buyers?: string[];
  buyerLists?: string[];
  buyerCategories?: string[];
  buyerCountryRegions?: CountryRegion;
  order?: OpportunitySortOrder;
  orderBy?: OpportunitySortBy;
};

// Define empty values explicitly as otherwise nested filter form fields fail to be cleared properly
export const EMPTY_OPPORTUNITY_FILTERS: OpportunityFilters = {
  searchText: "",
  assignedToIds: [],
  stageIds: [],
  closeDate: { filter: undefined, hideNulls: undefined },
  lastUpdatedDate: { filter: undefined, hideNulls: undefined },
  value: { from: undefined, to: undefined, hideNulls: undefined },
  buyers: [],
  buyerLists: [],
  buyerCategories: [],
  buyerCountryRegions: { countries: [], regions: [], selected: [] },
  orderBy: OpportunitySortBy.UpdatedAt,
  order: OpportunitySortOrder.Desc,
};

export const DEFAULT_PAGINATION: { pageSize: number; page: number } = {
  pageSize: 10,
  page: 1,
};

export const DEFAULT_WORKFLOW_TRANSITION_GROUPS = [
  OpportunityWorkflowTransitionGroup.Bidding,
  OpportunityWorkflowTransitionGroup.PreEngagement,
  OpportunityWorkflowTransitionGroup.TenderQualification,
];

export function getDefaultOpportunityStageIds(transitions: OpportunityWorkflow_Transitions[]) {
  return (
    transitions
      .filter((transition) => DEFAULT_WORKFLOW_TRANSITION_GROUPS.includes(transition.group))
      .map((transition) => transition.stage.id) ?? []
  );
}

export type SortField = OpportunitySortBy;

export function convertOpportunityFiltersToSearchRequest(
  filters: OpportunityFilters,
): SearchOpportunitiesRequest {
  return {
    searchText: filters.searchText,
    assignedToIds: filters.assignedToIds,
    stageIds: filters.stageIds,
    closeDate: isEmpty(filters.closeDate) ? undefined : filters.closeDate,
    lastUpdatedDate: isEmpty(filters.lastUpdatedDate) ? undefined : filters.lastUpdatedDate,
    value: isEmpty(filters.value) ? undefined : filters.value,
    buyerFilters: {
      buyers: filters.buyers,
      buyerLists: filters.buyerLists,
      buyerCategories: filters.buyerCategories,
      buyerCountryCodes: filters.buyerCountryRegions?.countries,
      buyerRegions: filters.buyerCountryRegions?.regions,
    },
    order: filters.order,
    orderBy: filters.orderBy,
  };
}

export function parseOpportunitiesUrlState(
  state: unknown,
  defaultFilters = {},
): OpportunityFilters {
  if (typeof state !== "object" || state === null) {
    return defaultFilters;
  }

  const filters = state as OpportunityFilters;

  return {
    ...defaultFilters,
    ...filters,
    closeDate: {
      ...filters.closeDate,
      hideNulls: parseBooleanValue(filters.closeDate?.hideNulls),
    },
    lastUpdatedDate: {
      ...filters.lastUpdatedDate,
      hideNulls: parseBooleanValue(filters.lastUpdatedDate?.hideNulls),
    },
    value: {
      from: parseNumberValue(filters.value?.from),
      to: parseNumberValue(filters.value?.to),
      hideNulls: parseBooleanValue(filters.value?.hideNulls),
    },
  };
}

export function generateOpportunitiesSearchUrl(filters: OpportunityFilters): string {
  const params = qs.stringify(
    { opportunityFilters: filters },
    { arrayFormat: "brackets", addQueryPrefix: true },
  );
  return `${routes.opportunities}${params}`;
}

const keysToKeep = ["id", "name", "description", "value", "closeDate"];

export const filterObject = (obj: Record<string, unknown>, keys: string[]) => {
  return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce<Record<string, unknown>>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

export const filterOpportunityDefaultValues = (opportunity: OpportunityDetails) => {
  const filteredPayload = filterObject(opportunity, keysToKeep);
  return {
    ...filteredPayload,
    assignedToId: opportunity.assignedTo?.guid,
    stageId: opportunity.stage.id,
  };
};

export const getOpportunityStageById = (
  id: string,
  transitions: OpportunityWorkflow_Transitions[],
) => {
  return transitions.find((transition) => transition.stage.id === id);
};
