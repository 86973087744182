import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd5";

import { Markdown } from "components/reports/builder_components/Markdown";
import { createUseDebounce } from "lib/debounce";
import { UpdateBidAnswerRequest } from "lib/generated/app-service-gql/graphql";
import { useUpdateBidAnswerById } from "lib/hooks/api/bid/bid_answer/useUpdateBidAnswerById";
import { EventNames, useTracking } from "lib/tracking";

import css from "./EditAnswer.module.scss";

type Props = {
  answerValue: string;
  answerId: string;
};

const MANUAL_ANSWER = "Click to edit";
const DEFAULT_ANSWER_DISPLAY = "";

export function EditAnswer({ answerValue, answerId }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const isManualAnswer = answerValue === MANUAL_ANSWER;

  const { logEvent } = useTracking();

  const [answerDisplay, setAnswerDisplay] = useState<string>(
    isManualAnswer ? DEFAULT_ANSWER_DISPLAY : answerValue,
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [inputWidth, setInputWidth] = useState<number>();

  const { mutateAsync: updateBidAnswerById } = useUpdateBidAnswerById();

  const userDebounce500 = createUseDebounce(200);

  const updateBidAnswer = async (answer: UpdateBidAnswerRequest) => {
    await updateBidAnswerById({ input: answer });
    logEvent(EventNames.qualificationCriteriaEdited, {
      "User input": answer.answer,
    });
  };

  const debouncedUpdateAnswer = userDebounce500(updateBidAnswer);

  useEffect(() => {
    if (containerRef.current) {
      setInputWidth(containerRef.current.offsetWidth); // Set input width to match container width
    }
  }, [isEditing]);

  const handleBlur = async () => {
    setIsEditing(false);

    if (answerDisplay === answerValue || !window.currentUser) return;

    if (!isManualAnswer || answerDisplay !== DEFAULT_ANSWER_DISPLAY) {
      debouncedUpdateAnswer({ answer: answerDisplay, id: answerId });
    }
  };

  return (
    <div ref={containerRef} className={css.editAnswerContainer} onClick={() => setIsEditing(true)}>
      {isEditing ? (
        <Input.TextArea
          className={css.inputContainer}
          style={{ width: inputWidth }}
          placeholder={answerValue === MANUAL_ANSWER ? MANUAL_ANSWER : DEFAULT_ANSWER_DISPLAY}
          value={answerDisplay.replace(/\\n/g, "\n")}
          // Only update answer when focus on input container is lost
          onBlur={handleBlur}
          onChange={(e) => {
            setAnswerDisplay(e.target.value);
          }}
          autoSize
          autoFocus
        />
      ) : (
        <Markdown
          text={answerValue.replace(/\\n/g, "\n")}
          className={css.markdown}
          targetType="new-tab"
          customComponents={{
            // Custom heading renderer for h1, h2, h3
            h1: ({ children }) => <h1 className={css.heading}>{children}</h1>,
            h2: ({ children }) => <h2 className={css.heading}>{children}</h2>,
            h3: ({ children }) => <h3 className={css.heading}>{children}</h3>,
          }}
        />
      )}
    </div>
  );
}
