import * as React from "react";
import { Button } from "antd5";
import { SizeType } from "antd5/lib/config-provider/SizeContext";
import { TooltipProps } from "antd5/lib/tooltip";
import classnames from "classnames";

import RecordStage from "lib/core_components/RecordStage";
import { Status, TenderStage } from "lib/types/models";

import css from "./RecordStageButtons.module.scss";

type Props = {
  onChange: (values: Status[]) => void;
  id?: string;
  className?: string;
  checkedStages: Status[];
  availableStages: Status[];
  disabledStages?: Status[];
  size?: SizeType;
  tooltipPlacement?: TooltipProps["placement"];
};

// Because this is used within Form.Item we have to wrap it in a forwardRef
const RecordStageButtons = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      id,
      className,
      checkedStages,
      availableStages,
      size,
      tooltipPlacement,
      disabledStages,
    },
    ref,
  ) => {
    const handleSelect = React.useCallback(
      (stage) => {
        if (checkedStages.includes(stage)) {
          onChange(checkedStages.filter((v) => v !== stage));
        } else onChange([...checkedStages, stage]);
      },
      [checkedStages, onChange],
    );

    return (
      <div
        className={classnames(css.recordStageButtonContainer, className, {
          [css.small]: size === "small",
        })}
        id={id}
        ref={ref}
      >
        {availableStages.map((s) => {
          return (
            <Button
              size={size}
              key={s}
              className={classnames({
                [css.selected]: checkedStages.includes(s),
              })}
              onClick={() => {
                if (!disabledStages?.includes(s)) handleSelect(s);
              }}
              value={s}
            >
              <RecordStage
                tooltipPlacement={tooltipPlacement}
                className={css.stage}
                stage={s as TenderStage}
              />
            </Button>
          );
        })}
      </div>
    );
  },
);

RecordStageButtons.displayName = "RecordStageButtons";

export default RecordStageButtons;
