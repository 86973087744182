import React from "react";
import { FieldValues } from "react-hook-form";

import { useUsers } from "lib/hooks/api/useUsers";
import { Select, SelectProps } from "./Inputs";

type Props<T extends FieldValues> = Omit<SelectProps<T>, "options">;

export function UserSelect<T extends FieldValues>(props: Props<T>) {
  const { data, isLoading } = useUsers();
  const options =
    data?.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.guid })) || [];
  return <Select {...props} loading={isLoading} options={options} optionFilterProp="label" />;
}
