import React from "react";
import { Controller } from "react-hook-form";
import styled from "@emotion/styled";
import { InputNumber } from "antd5";
import { Flex } from "styles/utility-components";

import { Checkbox } from "components/form_components/Inputs";
import { FilterContentProps, StyledFilterContent } from "./utils";

function ValueFilterContent({ control, data }: FilterContentProps) {
  return (
    <StyledFilterContent aria-label="Value filter">
      <Flex gap={8}>
        <Controller
          name="value.from"
          control={control}
          rules={{ min: 0 }}
          render={({ field, fieldState }) => (
            <StyledInput
              {...field}
              status={fieldState.error && "error"}
              min={0}
              placeholder="From"
            />
          )}
        />
        -
        <Controller
          name="value.to"
          control={control}
          rules={{ min: data?.value?.from || 0 }}
          render={({ field, fieldState }) => (
            <StyledInput {...field} status={fieldState.error && "error"} min={0} placeholder="To" />
          )}
        />
      </Flex>
      <Checkbox
        label=""
        name="value.hideNulls"
        control={control}
        fieldLabel="Hide opportunities that don't have a value"
      />
    </StyledFilterContent>
  );
}

const StyledInput = styled(InputNumber)({
  flex: 1,
});

export default ValueFilterContent;
