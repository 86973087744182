import React from "react";
import { Popover } from "antd5";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { Scorecard_Result_Questions_Sources } from "lib/generated/app-service-gql/graphql";
import { fileSearch02 } from "lib/icons/untitled_ui/SVGs";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { sysPrimaryDefault } from "lib/themes/colors";

import css from "./DocumentSources.module.scss";

type Props = {
  sources: Scorecard_Result_Questions_Sources[];
  onSelectDocument: (source: Scorecard_Result_Questions_Sources) => Promise<void>;
};

export default function DocumentSources({ sources, onSelectDocument }: Props) {
  if (sources.length === 1) {
    return <SourceLink source={sources[0]} onSelectDocument={onSelectDocument} />;
  }

  return <SourcesPopover sources={sources} onSelectDocument={onSelectDocument} />;
}

type SourceLinkProps = {
  source: Scorecard_Result_Questions_Sources;
  onSelectDocument: (source: Scorecard_Result_Questions_Sources) => Promise<void>;
};
function SourceLink({ source, onSelectDocument }: SourceLinkProps) {
  // If document name is not provided, apryse will not find the document
  const linkText = source.document || "--";
  return (
    <span className={css.linkContainer} onClick={() => onSelectDocument(source)}>
      <UIcon svg={fileSearch02} className={css.icon} size={15} color={sysPrimaryDefault} />
      <EllipsisTooltipText
        fullText={linkText}
        containerClassname={css.linkTextContainer}
        textProps={{
          className: css.linkText,
        }}
      />
    </span>
  );
}

function SourcesPopover({ sources, onSelectDocument }: Props) {
  const total = sources.length;

  const content = (
    <div className={css.popoverContent}>
      {sources.map((source, index) => (
        <span key={index}>
          <SourceLink source={source} onSelectDocument={onSelectDocument} />
        </span>
      ))}
    </div>
  );

  return (
    <Popover
      title={<h3 className={css.popoverTitle}>{`${total} sources`}</h3>}
      content={content}
      arrow={false}
      trigger="hover"
      placement="bottom"
    >
      <span className={css.sourceProperty}>{`${total} sources`}</span>
    </Popover>
  );
}
