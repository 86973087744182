import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsPage } from "components/app_layout/DetailsLayout";
import NoticeLists from "components/notices/NoticeLists";

export function MyNoticesPage() {
  return (
    <DetailsPage>
      <NoticeLists />
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(MyNoticesPage, {
    pageName: "Saved notices page",
  }),
);
