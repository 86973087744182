import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  CreateBidMutation,
  CreateBidMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const createBid = graphql(`
  mutation createBid($bid: CreateBidRequest!) {
    createBid(bid: $bid) {
      id
    }
  }
`);
export function useCreateBid(
  options?: UseMutationOptions<CreateBidMutation, unknown, CreateBidMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useGraphQlMutation(createBid, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      void queryClient.invalidateQueries(["bids", [variables.bid.procurementStageId]]);
    },
  });
  return { data: data?.createBid, ...rest };
}
