import React from "react";
import { useRoute } from "wouter";

import AiCard from "lib/core_components/AiCard";
import StatisticsCard, { StatisticsCardProps } from "lib/core_components/StatisticsCard";
import { LAYER_CAKE_FILTERS, useVariableValue } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useBuyerStatsGql } from "lib/hooks/api/buyer/useBuyerStatsGql";
import { useSuppliers } from "lib/hooks/api/suppliers/useSuppliers";
import { useBuyerSignalStats } from "lib/hooks/api/useBuyerSignalStats";
import { BuyerDetails } from "lib/types/models";
import { QuestionDataType, QuestionPanel } from "./question_panel/QuestionPanel";

import css from "./HeaderStatisticCards.module.scss";

type HSCProps = {
  onHeaderChange: ({ trackingType, route }: { trackingType: string; route: string }) => void;
  buyer: BuyerDetails;
};

function HeaderStatisticCards({ onHeaderChange, buyer }: HSCProps): JSX.Element {
  const isLayerCakeEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);
  const { isLoading: isLoadingSignalStats, data } = useBuyerSignalStats(
    { buyerGuids: [buyer.guid] },
    { enabled: !!window.currentUser },
  );

  const { data: buyerStats, isLoading: isLoadingBuyerStats } = useBuyerStatsGql(buyer.guid, {
    enabled: isLayerCakeEnabled,
  });
  const [isAiModalOpen, setIsAiModalOpen] = React.useState(false);
  const stats = data?.signalStats.find((s) => s.buyerGuid === buyer.guid)?.stats;

  const { data: allSuppliers, isLoading: isLoadingSuppliers } = useSuppliers(
    {
      buyerFilters: { buyerId: [buyer.guid] },
      sortOrder: "DESC",
      sort: "signals",
      offset: 0,
      limit: 10,
      signals: { categories: [], ids: [] },
      text: "",
    },
    { enabled: !!window.guestUser },
  );

  const isLoadingStats = isLayerCakeEnabled ? isLoadingBuyerStats : isLoadingSignalStats;

  return (
    <div className={css.container}>
      <QuestionPanel
        dataType={QuestionDataType.Buyer}
        id={buyer.guid}
        organisationName={buyer.name}
        isOpen={isAiModalOpen}
        onClose={() => setIsAiModalOpen(false)}
      />
      {FeatureToggles.isEnabled(Feature.QA_POC) && (
        <AiCard
          title="Ask a question about this buyer"
          onClick={() => {
            setIsAiModalOpen(true);
          }}
        />
      )}
      <Card
        value={
          isLayerCakeEnabled
            ? buyerStats?.allNotices?.totalResults ?? 0
            : stats?.matchingRecords?.count ?? 0
        }
        loading={isLoadingStats}
        objectType="Notice"
        description="Matching my signals"
        onClick={() =>
          onHeaderChange({
            route: "/activity/all",
            trackingType: "Records matching keywords",
          })
        }
        activateOnRoute="/activity/all"
      />
      <Card
        value={
          isLayerCakeEnabled
            ? buyerStats?.upcomingExpiries?.totalResults ?? 0
            : stats?.expiries?.count ?? 0
        }
        loading={isLoadingStats}
        objectType="Upcoming expiry"
        pluralObjectType="Upcoming expiries"
        description="Matching my signals"
        onClick={() =>
          onHeaderChange({
            route: "/activity/upcoming_expiries",
            trackingType: "Upcoming expiries matching keywords",
          })
        }
        activateOnRoute="/activity/upcoming_expiries"
      />
      <Card
        value={
          isLayerCakeEnabled ? buyerStats?.openOpps?.totalResults ?? 0 : stats?.openOps?.count ?? 0
        }
        loading={isLoadingStats}
        objectType="Open opportunity"
        pluralObjectType="Open opportunities"
        description="Matching my signals"
        onClick={() =>
          onHeaderChange({
            route: "/activity/open_opportunities",
            trackingType: "Open opportunities matching keywords",
          })
        }
        activateOnRoute="/activity/open_opportunities"
      />
      {!window.guestUser && (
        <>
          <Card
            value={stats?.partners.count || 0}
            loading={isLoadingSignalStats}
            objectType="Partner"
            description="Connected to buyer"
            onClick={() =>
              onHeaderChange({
                route: "/suppliers/partners",
                trackingType: "Partners connected to this buyer",
              })
            }
            activateOnRoute="/suppliers/partners"
          />
          <Card
            value={stats?.competitors.count || 0}
            loading={isLoadingSignalStats}
            objectType="Competitor"
            description="Connected to buyer"
            onClick={() =>
              onHeaderChange({
                route: "/suppliers/competitors",
                trackingType: "Competitors connected to this buyer",
              })
            }
            activateOnRoute="/suppliers/competitors"
          />
        </>
      )}
      {window.guestUser && (
        <Card
          value={allSuppliers?.pagingInfo.totalResults || 0}
          loading={isLoadingSuppliers}
          objectType="Supplier"
          description="Connected to buyer"
          onClick={() =>
            onHeaderChange({
              route: "/suppliers",
              trackingType: "Suppliers connected to this buyer",
            })
          }
          activateOnRoute="/suppliers"
        />
      )}
    </div>
  );
}

type CardProps = {
  activateOnRoute: string;
} & Omit<StatisticsCardProps, "active">;

function Card({ activateOnRoute, ...rest }: CardProps) {
  const [routeActive] = useRoute(activateOnRoute);

  return <StatisticsCard {...rest} active={routeActive} />;
}

export default HeaderStatisticCards;
