import React, { useEffect } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Tooltip } from "antd5";
import { ItemType } from "antd5/es/menu/interface";

import { NEW_NOTICES_SEARCH, useVariableValue } from "lib/featureFlags";
import { useExportNoticesAsync } from "lib/hooks/api/notices/useExportNoticesAsync";
import { useJob } from "lib/hooks/api/useJob";
import { useRecordSearch } from "lib/hooks/api/useRecordSearch";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import PaywallStar from "lib/icons/PaywallStar";
import { useSubscription } from "lib/providers/Subscription";
import { blue400 } from "lib/themes/colors";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { downloadFileFromLink } from "lib/utils";
import { useSupportChat } from "lib/utils/useSupportChat";
import { useNoticeExport } from "../../lib/hooks/api/notices/useNoticeExport";
import { useNotices } from "../../lib/hooks/api/notices/useNotices";
import { usePaywall } from "../../lib/providers/Paywall";
import { ExportButtonGraphql } from "../actions/ExportButtonGraphql";
import { DEFAULT_PAGINATION } from "./NoticeTable";
import {
  convertNoticeFiltersToApi,
  convertNoticeFiltersToSearchRequest,
  NoticeFilters,
} from "./utils";

import css from "./ExportNoticesButton.module.scss";

const EXPORT_LMIT = 500;

export function ExportNoticesButton({
  filters,
  selectedNotices,
}: {
  filters: NoticeFilters;
  selectedNotices?: string[];
}) {
  // If data sources include BUYERS|SUPPLIERS, then this user is on either the Growth or Expert plan and is authorised to export notices.
  const subscription = useSubscription();
  const authorised = subscription.hasDataTypes("SUPPLIERS") && subscription.hasDataTypes("BUYERS");

  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  if (!authorised) {
    return <ExportNoticesPaywallButton />;
  } else if (useOpenSearch) {
    return <ExportNoticesButtonOpenSearch filters={filters} selectedNotices={selectedNotices} />;
  }
  return <ExportNoticesButtonRails filters={filters} selectedNotices={selectedNotices} />;
}

function ExportNoticesButtonOpenSearch({
  filters,
  selectedNotices,
}: {
  filters: NoticeFilters;
  selectedNotices?: string[];
}) {
  // TODO: Right now, when signals column is active. Two requests will be made to the server.
  // This query doesn't incluide signals calculation, but the other does
  const { data: noticeSearch, isLoading } = useNotices(
    convertNoticeFiltersToSearchRequest(filters, DEFAULT_PAGINATION),
    false,
  );

  const noticeExportMutation = useNoticeExport();

  const totalResults =
    selectedNotices && selectedNotices.length > 0
      ? selectedNotices.length
      : noticeSearch?.pagingInfo.totalResults;

  const noticeSearchRequest = convertNoticeFiltersToSearchRequest(filters, {
    current: 1,
    pageSize: totalResults + 1,
  });

  return (
    <ExportButtonGraphql
      isLoadingTotalResults={isLoading}
      totalResults={totalResults}
      exportMutation={noticeExportMutation}
      exportVariables={{
        filters: noticeSearchRequest,
        selectedNotices: { noticeIds: selectedNotices },
      }}
      exportDataType={EventDataTypes.record}
    />
  );
}

function ExportNoticesButtonRails({
  filters,
  selectedNotices,
}: {
  filters: NoticeFilters;
  selectedNotices?: string[];
}) {
  const { openSupportChat } = useSupportChat();

  const { logEvent, trackingData } = useTracking();
  const [messageApi, contextHolder] = message.useMessage();

  const openGuestAccessPassPaywall = useRestrictedGuestAccess(trackingData);

  const recordFilters = convertNoticeFiltersToApi(filters, DEFAULT_PAGINATION);
  const exportFilters =
    selectedNotices && selectedNotices.length > 0
      ? { guid: selectedNotices }
      : { ...recordFilters, limit: undefined };
  const { isLoading, data } = useRecordSearch(recordFilters);

  const exportCount =
    selectedNotices && selectedNotices.length > 0
      ? selectedNotices.length
      : data?.pagingInfo.totalResults;

  const {
    data: exportData,
    mutate: startExport,
    isLoading: isLoadingStartExport,
    reset,
    variables: exportVariables,
  } = useExportNoticesAsync({ onError: () => messageApi.error("Failed to download file") });
  const { data: jobStatus } = useJob(exportData?.jobId, {
    enabled: !!exportData?.jobId && !isLoadingStartExport,
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (jobStatus) {
      if (jobStatus.finished && jobStatus.state !== "failed" && exportData) {
        downloadFileFromLink(
          exportData.downloadUrl,
          `stotles-notices-export-${new Date().toJSON().slice(0, 10)}.${exportVariables?.format}`,
        );
        logEvent(EventNames.exportedData, {
          Count: exportCount,
          Format: exportVariables?.format,
          "Data type": EventDataTypes.record,
        });
        reset();
      }
      if (jobStatus.state === "failed") {
        reset();
        void messageApi.error("Failed to download file");
      }
    }
  }, [exportCount, exportData, exportVariables?.format, jobStatus, logEvent, messageApi, reset]);

  const isExporting = isLoadingStartExport || (jobStatus && !jobStatus.finished);

  const menuItens: ItemType[] = [
    {
      key: "xlsx",
      label: (
        <span
          onClick={
            window.guestUser
              ? openGuestAccessPassPaywall
              : () => startExport({ filters: exportFilters, format: "xlsx" })
          }
        >
          Export to XLSX
        </span>
      ),
    },
    {
      key: "csv",
      label: (
        <span
          onClick={
            window.guestUser
              ? openGuestAccessPassPaywall
              : () => startExport({ filters: exportFilters, format: "csv" })
          }
        >
          Export to CSV
        </span>
      ),
    },
  ];

  // TODO extract this to separate PaywallExport wrapper component or similar
  const exportLimitReached = exportCount && exportCount > EXPORT_LMIT;
  const hasExportPermission = window.currentUser?.has_export_permission;

  if (data && exportLimitReached && !hasExportPermission) {
    return (
      <Tooltip
        title={
          <span>
            {`Narrow results to ${EXPORT_LMIT} notices or less to export. Need more? `}
            <br />
            <Button
              type="text"
              onClick={() => openSupportChat("Notice Feed")}
              style={{ color: blue400, padding: 0 }}
            >
              Chat with our team
            </Button>{" "}
            to get started.
          </span>
        }
      >
        <Button icon={<DownloadOutlined />} disabled>
          Export All {`(${exportCount})`}
        </Button>
      </Tooltip>
    );
  }

  return (
    <div>
      <Dropdown menu={{ items: menuItens }} disabled={isExporting}>
        <Button
          loading={isLoading || isExporting}
          disabled={isLoading || isExporting}
          icon={<DownloadOutlined className={css.downloadIcon} />}
        >
          Export
          {(selectedNotices === undefined || selectedNotices?.length === 0) &&
            ` All (${exportCount || 0})`}
        </Button>
      </Dropdown>
      {contextHolder}
    </div>
  );
}

function ExportNoticesPaywallButton() {
  const { openPaywallModal } = usePaywall();

  return (
    <Button
      icon={<PaywallStar />}
      className={css.paywallButton}
      onClick={() => openPaywallModal("EXPORT_NOTICES")}
    >
      Export
    </Button>
  );
}
