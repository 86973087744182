import { isDefined } from "lib/utils";

type RangeFilter = { from?: string; to?: string; hideNulls?: boolean };

function isRangeFilterApplied(value: RangeFilter) {
  return value.hideNulls || isDefined(value.from) || isDefined(value.to);
}

export function getAppliedFilters(filters: Record<string, unknown>, omittedFilters?: string[]) {
  const appliedFiltersArray = Object.entries(filters).filter(([key, value]) => {
    if (omittedFilters?.includes(key)) {
      return false;
    }

    if (typeof value === "string") {
      return value.length > 0;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (value && typeof value === "object") {
      if ("from" in value || "to" in value || "hideNulls" in value) {
        return isRangeFilterApplied(value as RangeFilter);
      }

      return Object.values(value).some((v) => (Array.isArray(v) ? v.length > 0 : isDefined(v)));
    }

    return false;
  });

  return appliedFiltersArray.map(([key]) => key);
}
