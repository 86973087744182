import * as React from "react";
import { Route, Switch } from "wouter";

import { useBids } from "../../lib/hooks/api/bid/bids/useBids";
import { QualificationReport } from "./qualification_report_section/QualificationReport";
import { DocumentUploadPage } from "./DocumentUploadPage";

export function BidQualificationPage({
  noticeId,
  procurementStageId,
  noticeTitle,
  buyerName,
  buyerId,
  portalUrl,
}: {
  noticeId: string;
  procurementStageId: string;
  noticeTitle: string;
  buyerName: string;
  buyerId: string;
  portalUrl?: string;
}) {
  // TODO: Determine if this is necessary?
  const { data: bids, isLoading } = useBids(
    { procurementStageIds: [procurementStageId] },
    {
      enabled: !!window.currentUser,
    },
  );

  if (isLoading && !!window.currentUser) {
    return null;
  }

  return (
    <Switch>
      <Route path="/bid_qualification">
        <DocumentUploadPage portalUrl={portalUrl} procurementStageId={procurementStageId} />
      </Route>
      <Route path={`/bid_qualification/:bidId`}>
        {({ bidId }) => (
          <QualificationReport
            noticeId={noticeId}
            bidId={bidId ?? bids?.results?.[0].id}
            procurementStageId={procurementStageId}
            noticeTitle={noticeTitle}
            buyerName={buyerName}
            buyerId={buyerId}
          />
        )}
      </Route>
    </Switch>
  );
}
