import { Control } from "react-hook-form/dist";
import styled from "@emotion/styled";
import { Flex } from "styles/utility-components";

import { OpportunityFilters } from "../utils";
import AssigneeFilterContent from "./Assignee";
import BuyersFilterContent from "./Buyers";
import CloseDateFilterContent from "./CloseDate";
import LastUpdatedDateFilterContent from "./LastUpdatedDate";
import StageFilterContent from "./Stage";
import ValueFilterContent from "./Value";

export interface FilterContentProps {
  control: Control<OpportunityFilters, unknown>;
  data?: OpportunityFilters;
}

export const opportunityFilterContents = {
  assignedTo: AssigneeFilterContent,
  stage: StageFilterContent,
  closeDate: CloseDateFilterContent,
  value: ValueFilterContent,
  lastUpdated: LastUpdatedDateFilterContent,
  buyers: BuyersFilterContent,
};

export const StyledFilterContent = styled(Flex)({
  flexDirection: "column",
  width: "100%",
  gap: 16,
  padding: "24px",
  paddingTop: "2px",
});
