import React, { useState } from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";

import { InRowSaveToButton } from "components/opportunities/saving/InRowSaveToButton";
import BuyerCategoriesWithPopover from "components/table_components/BuyerCategoriesWithPopover";
import {
  EllipsisTooltipTextLink,
  EllipsisTooltipWouterLink,
} from "lib/core_components/EllipsisTooltip";
import { OPPORTUNTIES } from "lib/featureFlags";
import { BuyerCategoryAssignmentDto } from "lib/generated/app-api";
import { useIsBuyerInList } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { EventData, TrackingProvider } from "lib/tracking";
import { OpportunityEntityType } from "lib/types/graphQLEnums";
import { getBuyerSupplierRelationshipDestination } from "../../lib/appDestinations";
import PaywallPopover from "../paywall/PaywallPopover";
import SaveBuyer from "./SaveBuyer";

import css from "./NameColumn.module.scss";

export function NameColumn({
  buyerGuid,
  buyerName,
  categories,
  isSupplierRelationship,
  trackingData,
}: {
  buyerGuid: string;
  buyerName: string;
  categories: BuyerCategoryAssignmentDto[];
  isSupplierRelationship: boolean;
  trackingData?: EventData;
}) {
  const [mouseEntered, setMouseEntered] = useState(false);

  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);
  const {
    isSaved: { isSaved, savedListNames },
  } = useIsBuyerInList(buyerGuid);

  return (
    <TrackingProvider data={{ "Buyer name": buyerName, ...trackingData }}>
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "BUYERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
        trackingData={trackingData}
        isDisabled={isOpportunitiesEnabled && !window.guestUser}
      >
        <div
          className={css.nameColumn}
          onMouseEnter={() => setMouseEntered(true)}
          onMouseLeave={() => setMouseEntered(false)}
        >
          <div className={css.buyerName} onClick={(e) => e.stopPropagation()}>
            {isSupplierRelationship ? (
              <EllipsisTooltipWouterLink
                fullText={buyerName}
                linkText={buyerName}
                linkProps={{
                  to: getBuyerSupplierRelationshipDestination(buyerGuid, "", "buyer"),
                  className: css.titleLink,
                  authorised: !window.guestUser,
                }}
              />
            ) : (
              <EllipsisTooltipTextLink
                fullText={buyerName}
                linkText={buyerName}
                linkProps={{
                  to: `/buyers/${buyerGuid}`,
                  className: css.titleLink,
                  authorised: !window.guestUser,
                }}
              />
            )}
            <BuyerCategoriesWithPopover
              size="default"
              buyerCategories={categories.map((c) => ({
                buyer_category_id: c.buyerCategoryId,
                source: c.source,
              }))}
              buyerName={buyerName}
            />
          </div>
          <div
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
          >
            {!window.guestUser && !isOpportunitiesEnabled && (
              <SaveBuyer
                buyerGuid={buyerGuid}
                buyerName={buyerName}
                triggerType={mouseEntered ? "button" : "bookmark"}
                contextSource="In-row"
              />
            )}
            {!window.guestUser && isOpportunitiesEnabled && (
              <InRowSaveToButton
                entityId={buyerGuid}
                entityType="buyer"
                displayType={mouseEntered ? "button" : "bookmark"}
                isSaved={isSaved}
                listCount={savedListNames.length}
                defaultOpportunityValues={{
                  name: buyerName,
                  assignedToId: window.currentUser?.guid,
                  entities: [
                    {
                      entityId: buyerGuid,
                      entityType: OpportunityEntityType.Organisation,
                    },
                  ],
                }}
              />
            )}
          </div>
        </div>
      </PaywallPopover>
    </TrackingProvider>
  );
}
