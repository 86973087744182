import React from "react";

import { DateRange } from "components/form_components/Inputs";
import { FilterContentProps, StyledFilterContent } from "./utils";

function LastUpdatedDateFilterContent({ control }: FilterContentProps) {
  return (
    <StyledFilterContent aria-label="Last updated date filter">
      <DateRange name="lastUpdatedDate.filter" control={control} label="" showFutureDates={false} />
    </StyledFilterContent>
  );
}

export default LastUpdatedDateFilterContent;
