import * as Sentry from "@sentry/react";

import { BidDocumentsResponse } from "lib/generated/app-service-gql/graphql";

// TODO: Long-term plan is to remove this public API and implement graphql federation instead

/**
 * Fetches all bid documents for a bid for a non-logged in user - hopefully got
 * here via share link
 * @returns
 */
export async function getBidDocumentsForExternalUser(
  bidId: string,
  urlShareSignature: string,
): Promise<BidDocumentsResponse> {
  try {
    const response = await fetch(`${window.app_service_url}/bid-api/getBidDocuments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bidId, urlShareSignature }),
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
    }

    return (await response.json()) as BidDocumentsResponse;
  } catch (error) {
    console.error("Failed to fetch bid documents: ", error);
    Sentry.captureException(error);
    throw error;
  }
}
