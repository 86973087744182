import React, { useState } from "react";
import styled from "@emotion/styled";
import { User } from "@sentry/react";
import { Mentions } from "antd5";
import { Flex, Text } from "styles/utility-components";

import Button from "components/ui/button/Button";
import DropdownMenu from "components/ui/dropdown_menu/DropdownMenu";
import TooltipComponent from "components/ui/tooltip/Tooltip";
import { extractLinkRegions } from "lib/comment_utils";
import {
  TextHighlighterMatch,
  TextMatchHighlighter,
} from "lib/core_components/TextMatchHighlighter";
import { useUsers } from "lib/hooks/api/useUsers";
import { DateGranularity, toHumanRelativeTimeFromNow } from "lib/utils/relative_time";

type BasicComment = {
  id: string;
  content: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  author: {
    guid: string;
    firstName: string;
    lastName: string;
  };
};

type CommentComponentProps = {
  comment: BasicComment;
  user: User;
  enabledEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  editCommentId: string;
  editedComment: string;
  setEditedComment: (comment: string) => void;
  handleUpdate: (id: string) => void;
  setEditCommentId: (id: string) => void;
};

export function CommentComponent({
  comment,
  user,
  enabledEdit,
  handleDelete: handleDeleteReal,
  editCommentId,
  editedComment,
  setEditedComment,
  handleUpdate,
  setEditCommentId,
}: CommentComponentProps) {
  const { data: users } = useUsers();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const userOptions = users?.map((user) => ({
    value: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const parseContentForUserMentions = (content: string) => {
    const possibleMentions = users?.map((u) => {
      return { tag: `@${u.firstName} ${u.lastName}`, guid: u.guid };
    });

    let editableContent = content;

    /**
     * I've done the check this way round, because we can't consistently rely on @"FirstName LastName"
     * as a regex pattern to pull users from the content.
     *
     * We don't validate the user input, so it's possible for the first or last name to be multiple words.
     */
    possibleMentions?.forEach((mention) => {
      const re = new RegExp(mention.tag, "g");
      const match = content?.match(re);
      if (match) {
        editableContent = editableContent.replace(
          mention.tag,
          `@[${mention.tag}](${mention.guid})`,
        );
      }
    });

    let matches: TextHighlighterMatch[] = [];
    if (editableContent) {
      matches = extractLinkRegions(editableContent);
    }

    return <TextMatchHighlighter text={editableContent} matches={matches} newCommentStyle={true} />;
  };

  const handleEditPrompt = () => {
    enabledEdit(comment.id);
    setConfirmDelete(false);
  };

  const handleDeletePrompt = () => {
    setEditCommentId("");
    setConfirmDelete(true);
  };

  const handleDelete = () => {
    handleDeleteReal(comment.id);
    setConfirmDelete(false);
  };

  return (
    <Flex column gap="8px">
      <Flex width="100%" gap="8px" flexWrap="wrap" alignItems="center">
        <Text bold>{comment.author.firstName + " " + comment.author.lastName}</Text>
        {comment.createdAt && (
          <Text ellipsis description color="sysTextSecondary">
            {toHumanRelativeTimeFromNow(comment.createdAt, DateGranularity.second)}
          </Text>
        )}
        <Spacer />
        {comment.updatedAt && comment.updatedAt !== comment.createdAt && (
          <TooltipComponent
            content={toHumanRelativeTimeFromNow(comment.updatedAt, DateGranularity.second)}
          >
            <Text description color="sysTextSecondary">
              Edited
            </Text>
          </TooltipComponent>
        )}
        {user.guid === comment.author.guid && (
          <DropdownMenu
            trigger={
              <Button
                as="button"
                buttonType="tertiary"
                icon="dotsHorizontal"
                iconProps={{ color: "sysTextDefault" }}
              />
            }
            items={[
              <Button
                key="edit"
                as="button"
                buttonType="tertiary"
                icon="edit02"
                justifyContent="left"
                blackText
                onClick={handleEditPrompt}
              >
                Edit
              </Button>,
              <Button
                key="delete"
                as="button"
                buttonType="tertiary"
                buttonStyle="destructive"
                icon="trash01"
                justifyContent="left"
                blackText
                onClick={handleDeletePrompt}
              >
                Delete
              </Button>,
            ]}
            align="end"
            width={160}
          />
        )}
      </Flex>
      <Flex column gap="8px">
        {editCommentId === comment.id ? (
          <>
            <MentionsStyled
              options={userOptions}
              placeholder="Edit comment"
              value={editedComment}
              onChange={(e) => setEditedComment(e)}
              autoSize
            />
            <Flex gap="4px" justifyContent="flex-end">
              <Button as="button" buttonType="secondary" onClick={() => setEditCommentId("")}>
                Cancel
              </Button>
              <Button as="button" buttonType="primary" onClick={() => handleUpdate(comment.id)}>
                Update
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <CommentTextWrapper>
              <Text>{parseContentForUserMentions(comment.content)}</Text>
            </CommentTextWrapper>
            {confirmDelete && (
              <Flex gap="4px" justifyContent="flex-end">
                <Button as="button" buttonType="secondary" onClick={() => setConfirmDelete(false)}>
                  Cancel
                </Button>
                <Button
                  as="button"
                  buttonType="primary"
                  buttonStyle="destructive"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}

const Spacer = styled.div({
  marginLeft: "auto",
});

const CommentTextWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.sysBackgroundAlternative,
  padding: "8px 12px",
  borderRadius: "8px",
}));

const MentionsStyled = styled(Mentions)({
  // To make it line-up with the non-editing comment text
  marginTop: "3px",
  marginBottom: "3px",
});
