import React from "react";
import { Skeleton } from "antd5";
import { Flex, Text } from "styles/utility-components";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import {
  BidDocumentsResponse_Items,
  Scorecard_Result_Questions_Sources,
} from "lib/generated/app-service-gql/graphql";
import { useBidDocuments } from "lib/hooks/api/bid/bid_document/useBidDocuments";
import UIcon from "lib/icons/untitled_ui/UIcon";

import css from "./DocumentSlideout.module.scss";

export function DocumentSlideout({
  bidId,
  urlShareSignature,
  onSelectDocument,
}: {
  bidId: string;
  urlShareSignature: string | null;
  onSelectDocument: (source: Scorecard_Result_Questions_Sources) => Promise<void>;
}) {
  const { data, isLoading } = useBidDocuments({ bidId }, urlShareSignature);

  async function getBidDocumentUrl(document: Pick<BidDocumentsResponse_Items, "id" | "fileName">) {
    await onSelectDocument({
      documentId: document.id,
      document: document.fileName,
      locations: [],
    });
  }

  return (
    <div className={css.tenderDocuments}>
      <Text h2 className={css.title}>
        Tender documents ({data?.items?.length ?? 0})
      </Text>
      <Flex flexDirection="column" style={{ marginTop: "16px" }}>
        {isLoading ? (
          <Skeleton />
        ) : (
          data?.items.map((doc) => (
            <div key={doc.id} className={css.item} onClick={() => getBidDocumentUrl(doc)}>
              <UIcon icon="fileAttachment" className={css.icon} />
              <EllipsisTooltipText fullText={doc.fileName} containerClassname={css.documentText} />
            </div>
          ))
        )}
      </Flex>
    </div>
  );
}
