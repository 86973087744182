// The value of each enum has to match the feature names in flipper.rb
export enum Feature {
  USE_TEXT_SEARCH = "USE_TEXT_SEARCH",
  SUBMIT_BUYER_CLEANING = "SUBMIT_BUYER_CLEANING",
  SPEND_DATA = "SPEND_DATA",
  RELATED_CONTACTS_TOUR = "RELATED_CONTACTS_TOUR",
  FRAMEWORK_DETAILS = "FRAMEWORK_DETAILS",
  SUPPLIER_PROFILE_REF_ORG_INFO = "SUPPLIER_PROFILE_REF_ORG_INFO",
  QA_POC = "QA_POC",
  CALL_OFFS = "CALL_OFFS",
  SIGN_UP_WITH_MICROSOFT = "SIGN_UP_WITH_MICROSOFT",
  SHOW_DEBUG_DATA = "SHOW_DEBUG_DATA",
  RECOMMENDED_KEYWORDS = "RECOMMENDED_KEYWORDS",
  RECOMMENDED_KEYWORDS_SIGNALS = "RECOMMENDED_KEYWORDS_SIGNALS",
}

// TODO: Make it possible to flip features in UI for testing
class FeatureToggles {
  isEnabled(feature: Feature) {
    const context = typeof window === "undefined" ? (global as typeof window) : window;
    return context.features ? context.features[feature] : false;
  }
}

export default new FeatureToggles();
