import React, { useMemo, useState } from "react";
import { isEqual, startCase } from "lodash";
import { Flex } from "styles/utility-components";
import useDeepCompareEffect from "use-deep-compare-effect";

import { InfoButton } from "components/actions/InfoButton";
import { Currency } from "components/app_layout/Typography";
import { FrameworkCell } from "components/frameworks/FrameworkCell";
import { DEFAULT_NOTICE_SORT_ORDER } from "components/my_feed/useMyFeedPageState";
import { AssigneeContainer } from "components/table_components/AssigneeContainer";
import BuyerCategoriesWithPopover from "components/table_components/BuyerCategoriesWithPopover";
import MultipleEntityLinks from "components/table_components/MultipleEntityLinks";
import { NewTitleCell } from "components/table_components/NewTitleCell";
import SuppliersContainer from "components/table_components/SuppliersContainer";
import SignalsContainer from "components/tags/SignalsContainer";
import RedactData from "components/ui/redact_data/RedactData";
import { numberSort, stringSort } from "lib/columnSort";
import RecordStage from "lib/core_components/RecordStage";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { Table } from "lib/core_components/Table/Table";
import { NEW_HIGHLIGHT_LOGIC, OPPORTUNTIES, useVariableValue } from "lib/featureFlags";
import { ColumnSettingFieldEnum } from "lib/generated/app-api";
import { useRestricedViewNotice } from "lib/hooks/useRestrictedRowClick";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { SortState } from "lib/search/types";
import { EventDataTypes, EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { NoticesStages, OpportunityEntityType } from "lib/types/graphQLEnums";
import { formatDate, isDefined } from "lib/utils";
import { SignalEntityType } from "lib/utils/signalUtils";
import { NewRelevanceScore } from "../../lib/core_components/NewRelevanceScore";
import { HIDE_ON_HOVER, SHOW_ON_HOVER } from "../../lib/core_components/ShowOnHover";
import { NoticesDto, useNotices } from "../../lib/hooks/api/notices/useNotices";
import { getSignalTypes } from "../../lib/types/models";
import { SaveToButton } from "../opportunities/saving/SaveToButton";
import {
  MarkAsNotRelevantButton,
  QualificationActions,
} from "../record_qualifications/QualificationActions";
import { QualificationStatus } from "../record_qualifications/QualificationStatus";
import {
  convertNoticeFiltersToSearchRequest,
  getFrontendQualification,
  NoticeFilters,
} from "./utils";

type NoticeColumnTypeMap = {
  [key in ColumnSettingFieldEnum]: ColumnType<NoticesDto>;
};

type NoticeColumns = keyof NoticeColumnTypeMap;

function getSortOrder(sort: SortState, key: string): "ascend" | "descend" | null {
  return sort.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

function getNoticeColumns(
  filters: NoticeFilters,
  columnSettings: NoticeColumns[],
  requiredDataType: ProHelperDataTypes | undefined,
  isNewHighlightingLogicEnabled: boolean,
  isOpportunitiesEnabled: boolean,
): ColumnType<NoticesDto>[] {
  const { sort } = filters;
  const title: ColumnType<NoticesDto> = {
    ...commonTableColumns.titleColumn,
    title: "Title & buyer",
    key: "title",
    dataIndex: "name",
    render: (_, record) => (
      <NewTitleCell notice={record} requiredDataType={requiredDataType} filters={filters} />
    ),
    sorter: stringSort((r: NoticesDto) => r.name),
    sortOrder: getSortOrder(sort, "title"),
    showSorterTooltip: false,
  };

  const stage: ColumnType<NoticesDto> = {
    ...commonTableColumns.stageColumn,
    title: "Stage",
    dataIndex: "stage",
    key: "stage",
    render: (value) => <RecordStage stage={value} />,
    showSorterTooltip: false,
  };

  const value: ColumnType<NoticesDto> = {
    ...commonTableColumns.valueColumn,
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: (value, r) => (
      <Flex justifyContent="end">
        <RedactData isRedacted={r.redacted}>
          <Currency longFormat value={r.value} currency={r.currency || undefined} />
        </RedactData>
      </Flex>
    ),
    sorter: numberSort((r: NoticesDto) => r.value, getSortOrder(sort, "value")),
    sortOrder: getSortOrder(sort, "value"),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: false,
  };

  const relevance_score: ColumnType<NoticesDto> = {
    ...commonTableColumns.relevanceColumn,
    title: "Signal score",
    key: "relevance_score",
    dataIndex: "score",
    render: (_, r) => (
      <NewRelevanceScore
        relevanceScore={r.score}
        popoverPlacement="bottomLeft"
        id={r.guid}
        entityType={SignalEntityType.ProcurementNotice}
        eventAttributes={{
          "Notice guid": r.guid,
          Stage: r.procurementStage?.stage,
          "Signal score": r.score ?? 0,
          "Data type": EventDataTypes.record,
        }}
      />
    ),
    sorter: numberSort((r: NoticesDto) => r.score),
    sortOrder: getSortOrder(sort, "relevance_score"),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: {
      title:
        "BETA: This score is calculated based on the strength of your signal settings against this notice.",
    },
  };

  const close_date: ColumnType<NoticesDto> = {
    ...commonTableColumns.dateColumn,
    key: "close_date",
    dataIndex: "closeDate",
    title: "Close date",
    render: (_, r) => (r.closeDate ? formatDate(new Date(r.closeDate)) : r.closeDate),
    sorter: stringSort((r) => r.closeDate),
    sortOrder: getSortOrder(sort, "close_date"),
  };

  const published_date: ColumnType<NoticesDto> = {
    ...commonTableColumns.dateColumn,
    key: "publish_date",
    title: "Publish date",
    render: (_, r) => (
      <RedactData isRedacted={r.redacted}>
        {r.publishDate ? formatDate(new Date(r.publishDate)) : r.publishDate}
      </RedactData>
    ),
    sorter: stringSort((r) => r.publishDate),
    sortOrder: getSortOrder(sort, "publish_date"),
  };

  const award_date: ColumnType<NoticesDto> = {
    ...commonTableColumns.dateColumn,
    key: "award_date",
    title: "Award date",
    render: (_, r) => (
      <RedactData isRedacted={r.redacted}>
        {r.awardDate ? formatDate(new Date(r.awardDate)) : r.awardDate}
      </RedactData>
    ),
    sorter: stringSort((r) => r.awardDate),
    sortOrder: getSortOrder(sort, "award_date"),
  };

  const expiry_date: ColumnType<NoticesDto> = {
    ...commonTableColumns.dateColumn,
    key: "expiry_date",
    title: "Expiry date",
    render: (_, r) => (
      <RedactData isRedacted={r.redacted}>
        {r.expiryDate ? formatDate(new Date(r.expiryDate)) : r.expiryDate}
      </RedactData>
    ),
    sorter: stringSort((r) => r.expiryDate),
    sortOrder: getSortOrder(sort, "expiry_date"),
  };

  const qualification: ColumnType<NoticesDto> = {
    title: "Qualification",
    key: "qualification",
    sizeConfig: {
      xlarge: 170,
      large: 170,
      medium: 170,
      small: 170,
    },
    showSorterTooltip: false,
    render: (_, r) => {
      return (
        <RedactData isRedacted={r.redacted} bullets={12}>
          {isOpportunitiesEnabled ? (
            <Flex gap={8}>
              <SaveToButton
                entityId={r.procurementStage.id}
                entityListId={r.guid}
                entityType="notice"
                opportunities={r.opportunities}
                compactDisplay
                defaultOpportunityValues={{
                  name: r.name ?? "",
                  value: r.value ?? undefined,
                  assignedToId: window.currentUser?.guid,
                  entities: [
                    {
                      entityId: r.procurementStage.id,
                      entityType: OpportunityEntityType.Notice,
                    },
                    ...r.buyers.map((buyer) => ({
                      entityId: buyer.guid,
                      entityType: OpportunityEntityType.Organisation,
                    })),
                    // dedupe supplier ids as there could be duplicates from lots
                    ...[...new Set(r.suppliers.map((suppliers) => suppliers.guid))].map((id) => ({
                      entityId: id,
                      entityType: OpportunityEntityType.Organisation,
                    })),
                  ],
                }}
              />
              <MarkAsNotRelevantButton
                procurementStage={{
                  id: r.procurementStage.id,
                  stage: r.procurementStage.stage ? r.procurementStage.stage.toString() : "",
                  qualification: r.qualification
                    ? getFrontendQualification(r.qualification)
                    : undefined,
                }}
                notice={{
                  guid: r.guid,
                  name: r.name || "--",
                  buyers: r.buyers,
                }}
                //TODO: replace with signals
                signalTypes={getSignalTypes([])}
                score={r.score}
                contextSource={"In-row"}
              />
            </Flex>
          ) : (
            <div>
              <div className={HIDE_ON_HOVER}>
                <QualificationStatus
                  procurementStage={{
                    id: r.procurementStage.id,
                    stage: r.procurementStage.stage ? r.procurementStage.stage.toString() : "",
                    qualification: r.qualification
                      ? getFrontendQualification(r.qualification)
                      : undefined,
                  }}
                  noticeGuid={r.guid}
                  score={r.score}
                  //TODO: replace with signals
                  signalTypes={getSignalTypes([])}
                />
              </div>
              <div className={SHOW_ON_HOVER}>
                <QualificationActions
                  procurementStage={{
                    id: r.procurementStage.id,
                    stage: r.procurementStage.stage ? r.procurementStage.stage.toString() : "",
                    qualification: r.qualification
                      ? getFrontendQualification(r.qualification)
                      : undefined,
                  }}
                  notice={{
                    guid: r.guid,
                    name: r.name || "--",
                    buyers: r.buyers,
                  }}
                  //TODO: replace with signals
                  signalTypes={getSignalTypes([])}
                  score={r.score}
                  contextSource={"In-row"}
                  showStatusOnNoActions
                  compactDisplay
                />
              </div>
            </div>
          )}
        </RedactData>
      );
    },
  };

  const country: ColumnType<NoticesDto> = {
    ...commonTableColumns.countryColumn,
    title: "Country",
    key: "country",
    render: (_, r) => <RedactData isRedacted={r.redacted}>{r.country}</RedactData>,
    sortOrder: getSortOrder(sort, "country"),
    sorter: stringSort((r) => r.country),
  };

  const is_sme: ColumnType<NoticesDto> = {
    ...commonTableColumns.isSMEColumn,
    title: (
      <div>
        SME suppliers?{" "}
        <InfoButton
          note={
            <div>
              For contracts awarded to multiple suppliers, this will show "Yes" if any of the
              suppliers are SMEs
            </div>
          }
        />
      </div>
    ),
    key: "isSme",
    render: (_, r) => (
      <RedactData isRedacted={r.redacted}>
        {r.stage === NoticesStages.Awarded
          ? r.suppliers?.some((s) => s.isSme)
            ? "Yes"
            : "No"
          : "N/A"}
      </RedactData>
    ),
  };

  const suppliers: ColumnType<NoticesDto> = {
    ...commonTableColumns.supplierColumn,
    title: "Supplier(s)",
    key: "suppliers",
    render: (_, r) => (
      <RedactData isRedacted={r.redacted} bullets={16}>
        <SuppliersContainer recordName={r.name ?? "--"} suppliers={r.suppliers || []} />
      </RedactData>
    ),
  };

  const assignee: ColumnType<NoticesDto> = {
    ...commonTableColumns.assignee,
    title: "Assignee",
    key: "assignee",
    dataIndex: "assignee",
    render: (_, r) => {
      return (
        <TrackingProvider data={{ "Context source": "In-row" }}>
          <RedactData isRedacted={r.redacted}>
            <AssigneeContainer
              recordGuid={r.guid}
              procurementStageId={r.procurementStage.id}
              procurementStageStage={r.stage || ""}
              signals={[]} // r.signals || []}
              score={r.score}
              assignee={r.assignee ?? undefined}
            />
          </RedactData>
        </TrackingProvider>
      );
    },
  };

  const buyer_categories: ColumnType<NoticesDto> = {
    key: "buyer_type",
    title: "Buyer type",
    width: "210px",
    render: (r: NoticesDto) => (
      <RedactData isRedacted={r.redacted}>
        <MultipleEntityLinks
          title={`Types for ${r.buyers.map((b) => b.name).join(", ")}`}
          links={r.buyers.map((b) => (
            <BuyerCategoriesWithPopover
              size="default"
              buyerCategories={b.categoryAssignments.map((assignment) => ({
                buyer_category_id: assignment.categoryId,
                source: assignment.source,
              }))}
              buyerName={b.name}
              key={b.guid}
            />
          ))}
        />
      </RedactData>
    ),
  };

  const framework: ColumnType<NoticesDto> = {
    title: "Framework",
    key: "framework",
    sizeConfig: {
      xlarge: 300,
      large: 250,
      medium: 200,
      small: 170,
    },
    showSorterTooltip: false,
    render: (_, r) => {
      return (
        <RedactData isRedacted={r.redacted} bullets={16}>
          {r.relatedFrameworkProcess ? (
            <FrameworkCell
              frameworkId={r.relatedFrameworkProcess.frameworkId}
              frameworkName={r.relatedFrameworkProcess.frameworkName}
              processType={r.relatedFrameworkProcess.processType}
              filters={isNewHighlightingLogicEnabled ? filters : undefined}
            />
          ) : null}
        </RedactData>
      );
    },
  };

  // Has to be called indicator to match the column name in ColumnSettingFieldEnum
  const indicator: ColumnType<NoticesDto> = {
    title: "Signals",
    key: "signals",
    sizeConfig: {
      xlarge: 250,
      large: 150,
      medium: 150,
      small: 150,
    },
    showSorterTooltip: false,
    render: (_, r) => {
      return (
        <RedactData isRedacted={r.redacted} bullets={12}>
          <SignalsContainer signals={r.signals || []} contextSource="In-row" />
        </RedactData>
      );
    },
  };

  const columnTypeMap: Partial<NoticeColumnTypeMap> = {
    title,
    stage,
    close_date,
    published_date,
    expiry_date,
    award_date,
    value,
    country,
    suppliers,
    is_sme,
    qualification,
    assignee,
    buyer_categories,
    relevance_score,
    framework,
    indicator,
  };

  const columns: (ColumnType<NoticesDto> | undefined)[] = [];

  for (const column of columnSettings) {
    if (
      (window.guestUser && ["assignee", "indicator", "qualification"].includes(column)) ||
      (isOpportunitiesEnabled && column === "assignee")
    ) {
      continue;
    }
    columns.push(columnTypeMap[column]);
  }

  return columns.filter(isDefined);
}

export function NewNoticeTable({
  filters,
  columnSettings,
  onSortChange,
  selectedRows,
  onSelectedRowsChange,
  isPreview,
  requiredDataType,
  emptyText,
}: {
  filters: NoticeFilters;
  columnSettings: NoticeColumns[];
  onSortChange?: (sort: SortState) => void;
  selectedRows?: string[];
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: NoticesDto[]) => void;
  isPreview?: boolean;
  requiredDataType?: ProHelperDataTypes;
  emptyText?: JSX.Element;
}) {
  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);

  const { logEvent } = useTracking();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const includeSignals = columnSettings.includes("indicator");
  const isNewHighlightingLogicEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  const { data, isLoading, isError } = useNotices(
    convertNoticeFiltersToSearchRequest(filters, pagination),
    includeSignals,
  );

  const columns = useMemo(
    () =>
      getNoticeColumns(
        {
          ...filters,
          sort: {
            ...filters.sort,
            field: filters.sort.field || DEFAULT_NOTICE_SORT_ORDER.field,
            order: filters.sort.order || DEFAULT_NOTICE_SORT_ORDER.order,
          },
        },
        columnSettings,
        requiredDataType,
        isNewHighlightingLogicEnabled,
        isOpportunitiesEnabled,
      ),
    [
      filters,
      columnSettings,
      requiredDataType,
      isNewHighlightingLogicEnabled,
      isOpportunitiesEnabled,
    ],
  );

  useDeepCompareEffect(() => {
    onSelectedRowsChange?.([], []);
    setPagination(DEFAULT_PAGINATION);
  }, [filters]);

  const { viewRecord } = useRestricedViewNotice(requiredDataType);
  const { viewRecord: showPaywallModal } = useRestricedViewNotice("Row-click", "AWARDS");

  return (
    <Table<NoticesDto>
      ariaLabel="Notice Table"
      dataSource={data?.results}
      loading={isLoading}
      columns={columns}
      showSorterTooltip={false}
      rowKey={(record) => record.procurementStage.id}
      onRow={(record) => ({
        onClick: (e) => {
          e.stopPropagation();
          e.preventDefault();
          if (record.redacted) {
            showPaywallModal(record.guid);
          } else {
            viewRecord(record.guid);
          }
        },
      })}
      pagination={{
        ...pagination,
        total: isPreview ? DEFAULT_PAGINATION.pageSize : data?.pagingInfo.totalResults || 0,
        onChange: (page, pageSize) => {
          setPagination({ current: page, pageSize });
          logEvent(EventNames.paginationSelected, { Value: page });
        },
        showSizeChanger: true,
      }}
      onChange={(pag, __, sorter) => {
        if (pagination.current !== pag.current || pagination.pageSize !== pag.pageSize) {
          // pagination has changed which is handled by the pagination onChange event
          return;
        }
        if (Array.isArray(sorter)) {
          sorter = sorter[0];
        }
        // If the sort order is not defined, we don't want to trigger a sort without an order
        if (!filters.sort && !sorter.order) {
          return;
        }
        const newSortState: SortState = {
          order: sorter.order === "descend" ? "DESC" : "ASC",
          field: sorter.columnKey as string,
        };
        if (!isEqual(filters.sort, newSortState)) {
          onSortChange?.(newSortState);
          logEvent(EventNames.tableSorted, {
            Context: "Column action",
            "Sorted by": startCase(newSortState.field),
            "Sort order": newSortState.order,
          });
        }
      }}
      rowSelection={
        onSelectedRowsChange &&
        selectedRows && {
          onChange: (selectedRowKeys, selectedRows) => {
            onSelectedRowsChange(selectedRowKeys as string[], selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRows,
        }
      }
      isError={isError}
      locale={{
        emptyText,
      }}
    />
  );
}
