import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableFrameworks } from "components/framework/FilterableFrameworks";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import { useSubscription } from "lib/providers/Subscription";

import css from "./FrameworkListPage.module.scss";

export function FrameworkListPage() {
  const subscription = useSubscription();
  const hasFrameworks = subscription.hasDataTypes("FRAMEWORKS");

  return (
    <DetailsPage>
      <DetailsHeader>
        <h1 className={css.title}>Frameworks</h1>
      </DetailsHeader>
      {hasFrameworks ? (
        <DetailsContent className={css.content}>
          <FilterableFrameworks />
        </DetailsContent>
      ) : (
        <PaywallBanner featureType="FRAMEWORKS" />
      )}
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(FrameworkListPage, {
    pageName: "Framework search",
  }),
);
