import React from "react";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Flex } from "styles/utility-components";

import { OpportunityDetails } from "lib/hooks/api/opportunities/useOpportunity";
import { opportunitySavedCardContainer } from "./shared/styles";
import OpportunitySavedEmptyState from "./OpportunitySavedEmptyState";

type Contact = OpportunityDetails["contacts"][0];

type Props = { contacts: Contact[]; emptyStateUrl: string };

type ContactCardProps = {
  contact: Contact;
  onClick: (() => void) | undefined;
};

export function OpportunityContacts({ contacts, emptyStateUrl }: Props) {
  const handleContactClick = () => () => {
    window.location.href = `/contacts`;
  };

  return (
    <Flex column gap={16}>
      {contacts.map((contact) => (
        <ContactCard key={contact.id} contact={contact} onClick={handleContactClick()} />
      ))}
      <OpportunitySavedEmptyState entity="contact" url={emptyStateUrl} />
    </Flex>
  );
}

function ContactCard({ contact, onClick }: ContactCardProps) {
  return (
    <Container onClick={onClick}>
      <div>
        <ContactName>{`${contact.firstName} ${contact.lastName}`}</ContactName>
        <ContactTitle>{contact.jobTitle}</ContactTitle>
      </div>
      <ContactInfoContainer contact={contact} />
    </Container>
  );
}
function ContactInfoContainer({ contact }: { contact: Contact }) {
  return (
    <>
      <Flex alignItems="center" justifyContent="flex-start" gap={8}>
        <MailOutlined />
        {contact.email ? (
          <ContactInfo>{contact.email}</ContactInfo>
        ) : (
          <EmptyState aria-label="unknown-email">--</EmptyState>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="flex-start" gap={8}>
        <PhoneOutlined />
        {contact.phone ? (
          <ContactInfo>{contact.phone}</ContactInfo>
        ) : (
          <EmptyState aria-label="unknown-phone">--</EmptyState>
        )}
      </Flex>
    </>
  );
}

const EmptyState = styled.div(({ theme }) => ({
  color: theme.colors.sysTextInactive,
}));

const ContactInfo = styled.div(({ theme }) => ({
  color: theme.colors.sysPrimaryDefault,

  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
}));

const ContactTitle = styled.div(() => ({
  fontWeight: "400",
  lineHeight: "22px",

  height: "24px",

  flexShrink: 0,
  justifyContent: "center",

  paddingTop: "4px",
}));

const ContactName = styled.div(() => ({
  fontWeight: "500",
  lineHeight: "22px",

  height: "24px",

  justifyContent: "center",
}));

const Container = styled.div(({ theme }) => ({
  ...opportunitySavedCardContainer(theme),

  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  gap: "16px",

  padding: "24px",
}));
