import React from "react";

import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "components/form_components/CountryRegionSelect";
import { FilterContentProps, StyledFilterContent } from "./utils";

function BuyersFilterContent({ control, data }: FilterContentProps) {
  return (
    <StyledFilterContent aria-label="Buyer filters">
      <BuyerSelect
        name="buyers"
        label=""
        control={control}
        mode="multiple"
        placeholder="Search buyers..."
        disabled={(data?.buyerLists?.length ?? 0) > 0}
        allowClear
      />
      <SelectBuyerList
        name="buyerLists"
        label="Buyer lists"
        control={control}
        placeholder="Select..."
        mode="multiple"
        disabled={(data?.buyers?.length ?? 0) > 0}
        allowClear
      />
      <BuyerTypeSelect
        name="buyerCategories"
        control={control}
        multiple
        label="Buyer type"
        placeholder="Select..."
        allowClear
      />
      <CountryRegionSelect
        name="buyerCountryRegions"
        control={control}
        label="Buyer location"
        placeholder="Select..."
        allowClear
      />
    </StyledFilterContent>
  );
}

export default BuyersFilterContent;
