import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  QualifyQuestionMutation,
  QualifyQuestionMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const qualifyQuestion = graphql(`
  mutation QualifyQuestion($input: QualifyQuestionRequest!) {
    qualifyQuestion(QualifyQuestionRequest: $input) {
      answerId
      title
      answer {
        answer
      }
      sources {
        documentId
        annotations
        chunkNumber
        locations {
          pageNumber
          bbox {
            x
            y
            width
            height
          }
        }
      }
    }
  }
`);

export function useQualifyQuestion(
  options?: UseMutationOptions<
    QualifyQuestionMutation,
    unknown,
    QualifyQuestionMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(qualifyQuestion, {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
      await queryClient.invalidateQueries({ queryKey: ["bid_qualify_bid"] });
    },
  });

  return { data: data?.qualifyQuestion, ...rest };
}
