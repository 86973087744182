import React from "react";
import { hot } from "react-hot-loader/root";
import { Menu } from "antd5";
import { Redirect, Route, Router, Switch, useLocation } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableBuyerTable } from "components/buyers/FilterableBuyerTable";
import { DEFAULT_BUYER_FILTERS } from "components/buyers/useFilterableBuyerTableState";
import { FilterableFrameworks } from "components/framework/FilterableFrameworks";
import { FrameworkColumns } from "components/framework/FrameworkTable";
import { DEFAULT_FILTERS as DEFAULT_FRAMEWORK_FILTERS } from "components/framework/utils";
import { EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { FilterableNoticeTable } from "components/notices/FilterableNoticeTable";
import { NoticeColumns } from "components/notices/NoticeTable";
import { useFilterableNoticeTableState } from "components/notices/useFilterableNoticeTableState";
import { NoticeFilters } from "components/notices/utils";
import {
  getTabConfigFromLocation,
  labelledRoutes,
  PrimaryTab,
  SecondaryTab,
} from "components/supplier_details/supplier_utils";
import SupplierHeader from "components/supplier_details/SupplierHeader";
import {
  LAYER_CAKE_FILTERS,
  NEW_NOTICES_SEARCH,
  NEW_SUPPLIER_FILTER,
  useVariableValue,
} from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import {
  GetBuyerListsPermissionEnum,
  SupplierDetailsDto,
  SupplierDetailsDtoFromJSON,
} from "lib/generated/app-api";
import { useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";
import PaywallStar from "lib/icons/PaywallStar";
import { useConfirmUserDataSubscription, usePaywall } from "lib/providers/Paywall";
import { useSubscription } from "lib/providers/Subscription";
import { EventData, EventNames, logEvent, TrackingProvider } from "lib/tracking";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { ColumnSetting } from "lib/types/models";
import SupplierBuyerRelationshipPage from "./SupplierBuyerRelationshipPage";

import css from "./SupplierProfilePage.module.scss";

const AWARDS_COLUMNS: NoticeColumns[] = [
  "indicator",
  "title",
  "expiry_date",
  "value",
  "stage",
  "qualification",
  "assignee",
];

const FRAMEWORK_COLUMNS: ColumnSetting<FrameworkColumns>[] = [
  "signalScore",
  "title",
  "stage",
  "suppliers",
  "startDate",
  "endDate",
  "awardType",
].map((c) => {
  return {
    field: c,
    visible: true,
  } as ColumnSetting<FrameworkColumns>;
});

export const DEFAULT_SUPPLIER_NOTICES_SORT: NoticeFilters["sort"] = {
  field: "relevance_score",
  order: "DESC",
};

type WrapperProps = {
  supplier: unknown;
};

export function SupplierProfilePageWrapper({ supplier: supplierRaw }: WrapperProps) {
  // Paywall modal which prevents users who aren't subscribed from viewing supplier profile
  useConfirmUserDataSubscription("SUPPLIERS", "SUPPLIERS");

  const supplier = SupplierDetailsDtoFromJSON(supplierRaw);
  return (
    <Router base={`/suppliers/${supplier.id}`}>
      <Switch>
        <Route path="/buyers/saved_buyers">
          <SupplierProfilePage supplier={supplier} />
        </Route>

        {/* buyer supplier relationship page */}
        <Route<{ buyerGuid: string }> path="/buyer/:buyerGuid">
          {({ buyerGuid }) => <Redirect to={`/buyers/${buyerGuid}`} replace={true} />}
        </Route>
        <Route<{ buyerGuid: string }> path="/buyers/:buyerGuid">
          {({ buyerGuid }) => (
            <SupplierBuyerRelationshipPage
              supplierName={supplier.name}
              buyerGuid={buyerGuid}
              supplierGuid={supplier.guid}
            />
          )}
        </Route>

        {/* Default */}
        <Route>
          <SupplierProfilePage supplier={supplier} />
        </Route>
      </Switch>
    </Router>
  );
}

type PageProps = {
  supplier: SupplierDetailsDto;
};
export function SupplierProfilePage({ supplier }: PageProps) {
  const { data: buyerLists } = useBuyerLists(GetBuyerListsPermissionEnum.CreateBuyerListEntries, {
    enabled: !!window.currentUser,
  });

  const subscription = useSubscription();
  const { openPaywallModal } = usePaywall();

  const frameworksEnabled = FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);
  const showFrameworksPaywall = !subscription.hasDataTypes("FRAMEWORKS");
  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const useNewNoticesSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  const [location, navigate] = useLocation();

  const setLocation = React.useCallback(
    (route: string) => navigate(`${route}${window.location.search}`),
    [navigate],
  );

  const [tabConfig, setTabConfig] = React.useState(() =>
    getTabConfigFromLocation(location as PrimaryTab | SecondaryTab),
  );

  const { setNoticeTableFilters } = useFilterableNoticeTableState();

  const handleNavigation = (
    loc: PrimaryTab | SecondaryTab,
    eventName: EventNames,
    eventData: EventData,
  ) => {
    setNoticeTableFilters(undefined);
    setTabConfig(getTabConfigFromLocation(loc));

    setLocation(loc);

    logEvent(eventName, eventData);
  };

  const getDefaultNoticeFilters = (): NoticeFilters => {
    switch (tabConfig.secondary) {
      case SecondaryTab.AWARDS:
        return enableNewSupplierFilter && window.currentUser?.use_supplier_name === false
          ? {
              ...EMPTY_FILTERS,
              supplierMentionType: SupplierMentionType.DirectlyAwarded,
              supplierGuids: [supplier.guid],
              sort: DEFAULT_SUPPLIER_NOTICES_SORT,
            }
          : {
              ...EMPTY_FILTERS,
              stage: ["AWARDED"],
              suppliers: [supplier.id],
              signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
              sort: DEFAULT_SUPPLIER_NOTICES_SORT,
            };
      case SecondaryTab.MENTIONS:
        return {
          ...EMPTY_FILTERS,
          supplierMentionType: SupplierMentionType.MentionedInOnly,
          supplierGuids: [supplier.guid],
          sort: DEFAULT_SUPPLIER_NOTICES_SORT,
        };
      case SecondaryTab.UPCOMING_EXPIRIES:
        return {
          ...EMPTY_FILTERS,
          stage: ["AWARDED"],
          suppliers: [supplier.id],
          supplierGuids: [supplier.guid],
          expiryDate: { filter: { relativeFrom: "PT0S" }, hideNulls: true },

          // Upcoming expiries connected to supplier is the new card -
          // signals aren't of concern
          signals:
            enableNewSupplierFilter && window.currentUser?.use_supplier_name === false
              ? []
              : ["Keywords", "Partners", "Competitors", "CPV codes"],
        };
      default:
        return {
          ...EMPTY_FILTERS,
          suppliers: [supplier.id],
          supplierGuids: [supplier.guid],
        };
    }
  };

  return (
    <DetailsPage>
      <DetailsHeader className={css.noPaddingBottom}>
        <SupplierHeader supplier={supplier} handleNavigation={handleNavigation} />
        <TrackingProvider
          data={{ "Context source": labelledRoutes[tabConfig.secondary || tabConfig.primary] }}
        >
          <Menu mode="horizontal" selectedKeys={[tabConfig.primary]} className={css.menuTabs}>
            <Menu.Item
              key={PrimaryTab.NOTICES}
              onClick={() =>
                handleNavigation(PrimaryTab.NOTICES, EventNames.tabChanged, {
                  "Context source": "Primary Tab",
                  "Tab Selected": labelledRoutes[PrimaryTab.NOTICES],
                })
              }
              className={css.menuTabItem}
            >
              Notices
            </Menu.Item>
            {window.currentUser ? (
              <>
                {frameworksEnabled && (
                  <Menu.Item
                    key={PrimaryTab.FRAMEWORKS}
                    onClick={() => {
                      if (showFrameworksPaywall) {
                        openPaywallModal("FRAMEWORKS");
                      } else {
                        handleNavigation(PrimaryTab.FRAMEWORKS, EventNames.tabChanged, {
                          "Context source": "Primary Tab",
                          "Tab Selected": labelledRoutes[PrimaryTab.FRAMEWORKS],
                        });
                      }
                    }}
                    className={css.menuTabItem}
                  >
                    Frameworks{showFrameworksPaywall && <PaywallStar />}
                  </Menu.Item>
                )}
                <Menu.Item
                  key={PrimaryTab.BUYERS}
                  onClick={() =>
                    handleNavigation(PrimaryTab.BUYERS, EventNames.tabChanged, {
                      "Context source": "Primary Tab",
                      "Tab Selected": labelledRoutes[PrimaryTab.BUYERS],
                    })
                  }
                  className={css.menuTabItem}
                >
                  Buyers
                </Menu.Item>
              </>
            ) : null}
          </Menu>
        </TrackingProvider>
      </DetailsHeader>
      <DetailsContent className={css.supplierProfileContent}>
        {frameworksEnabled && tabConfig.primary === PrimaryTab.FRAMEWORKS ? (
          showFrameworksPaywall ? (
            <></>
          ) : (
            <FilterableFrameworks
              defaultColumns={FRAMEWORK_COLUMNS}
              defaultFilters={{
                ...DEFAULT_FRAMEWORK_FILTERS,
                supplierIds: [supplier.guid],
              }}
              hiddenFilterSections={["suppliers"]}
            />
          )
        ) : tabConfig.primary === PrimaryTab.BUYERS ? (
          <FilterableBuyerTable
            defaultFilters={{
              ...DEFAULT_BUYER_FILTERS,
              supplierIds: [supplier.id],
              buyerLists:
                tabConfig.secondary === SecondaryTab.SAVED_BUYERS
                  ? buyerLists?.all?.map((buyerList) => buyerList.id) || []
                  : [],
            }}
            defaultSortOrder={{ field: "signals", order: "DESC" }}
            hiddenSections={isLayerCakeFiltersEnabled ? ["signals", "suppliers"] : ["suppliers"]}
            isSupplierRelationship
          />
        ) : (
          <FilterableNoticeTable
            defaultFilters={getDefaultNoticeFilters()}
            defaultColumns={
              useNewNoticesSearch ? ["relevance_score", ...AWARDS_COLUMNS] : AWARDS_COLUMNS
            }
            hiddenSections={["suppliers"]}
            paywallEnabled={true}
            location={"suppliers"}
          />
        )}
      </DetailsContent>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(SupplierProfilePageWrapper, (props) => {
    const supplier = SupplierDetailsDtoFromJSON(props.supplier);

    return {
      pageName: "Supplier Profile",
      pageViewProps: { "Supplier name": supplier.name, "Supplier ID": supplier.id },
      trackingData: { "Supplier name": supplier.name, "Supplier ID": supplier.id },
    };
  }),
);
