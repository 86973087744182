import React from "react";

import TeamUserSelect from "components/form_components/TeamUserSelect";
import { FilterContentProps, StyledFilterContent } from "./utils";

function AssigneeFilterContent({ control }: FilterContentProps) {
  return (
    <StyledFilterContent aria-label="Assignee filter">
      <TeamUserSelect
        name="assignedToIds"
        control={control}
        label=""
        placeholder="Select name"
        mode="multiple"
      />
    </StyledFilterContent>
  );
}

export default AssigneeFilterContent;
