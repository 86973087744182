import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  GetBidDocumentUrlMutation,
  GetBidDocumentUrlMutationVariables,
} from "../../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const getBidDocumentUrl = graphql(`
  mutation GetBidDocumentUrl($id: String!) {
    getBidDocumentUrl(id: $id) {
      documentUrl
    }
  }
`);

export function useGetBidDocumentUrl(
  options?: UseMutationOptions<
    GetBidDocumentUrlMutation,
    unknown,
    GetBidDocumentUrlMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(getBidDocumentUrl, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
  });

  return { data: data?.getBidDocumentUrl, ...rest };
}
