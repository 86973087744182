import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsDevCycleInitialized } from "@devcycle/react-client-sdk";
import { Button, Tooltip } from "antd5";
import { Redirect, useLocation } from "wouter";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { NOTICE_AUTO_QUALIFICATION, useVariableValue } from "lib/featureFlags";
import { EventNames, useTracking } from "lib/tracking";
import { useCreateBidDocument } from "../../lib/hooks/api/bid/bid_document/useCreateBidDocument";
import { useCreateBid } from "../../lib/hooks/api/bid/bids/useCreateBid";
import { useUploadNoticeDocument } from "../../lib/hooks/api/bid/useUploadNoticeDocument";
import {
  checkOutlined,
  fileCheck02,
  linkExternal02,
  starts02,
  trash01,
  upload01,
} from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { Text } from "../../styles/utility-components";
import { TextLink } from "../actions/Links";
import { DetailsContent, DetailsPage } from "../app_layout/DetailsLayout";
import FileInput from "../form_components/FileInput";

import css from "./DocumentUploadPage.module.scss";

type UploadForm = {
  files: File[];
  procurementStageId: string;
};

type FileUploadStatus = {
  file: File;
  isError: boolean;
  isUploaded: boolean;
  isProcessed: boolean;
  documentId?: string;
};

export function DocumentUploadPage({
  procurementStageId,
  portalUrl,
}: {
  procurementStageId: string;
  portalUrl: string | undefined;
}) {
  const { logEvent } = useTracking();
  const [, setLocation] = useLocation();
  const [filesUploading, setFilesUploading] = useState<boolean>(false);

  const devCycleReady = useIsDevCycleInitialized();
  const isEnabled = useVariableValue(NOTICE_AUTO_QUALIFICATION, false);

  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const { control, setValue, watch } = useForm<UploadForm>({
    defaultValues: { files: [], procurementStageId },
    mode: "onChange",
  });

  const { files } = watch();

  const [uploadFileStatus, setUploadFileStatus] = useState<FileUploadStatus[]>(
    files.map((file) => ({ file, isError: false, isUploaded: false, isProcessed: false })),
  );

  const { mutateAsync: createBid } = useCreateBid();
  const { mutateAsync: createBidDocument } = useCreateBidDocument();

  const { mutateAsync: uploadMutateAsync, isError } = useUploadNoticeDocument({
    onSettled: (data) => {
      setUploadFileStatus((prev) =>
        prev.map((fileStatus) =>
          fileStatus.file.name === data?.documentId
            ? {
                ...fileStatus,
                isUploaded: !isError,
                isError,
                procurementStageId,
              }
            : fileStatus,
        ),
      );
    },
  });

  const startUpload = async () => {
    if (files.length > 0) {
      setFilesUploading(true);
      // We need bid details before we can attach documents to them
      const bid = await createBid({ bid: { procurementStageId } });
      if (!bid) {
        return;
      }
      setUploadFileStatus(
        files.map((file) => ({ file, isError: false, isUploaded: false, isProcessed: false })),
      );

      for (const file of files) {
        // Temp solution currently getting 503 slow down error on AWS
        const fileDetails = await uploadMutateAsync({ file, procurementStageId });
        await createBidDocument({
          request: {
            bidId: bid.createBid.id,
            fileName: file.name,
            procurementStageId,
            documentId: fileDetails.documentId,
          },
        });
      }

      logEvent(EventNames.qualificationReportCreated, {
        "Action type": "Button clicked",
        "Number of documents": files.length,
      });

      setLocation(`/bid_qualification/${bid.createBid.id}`);
    }
  };

  const updateFiles = (newFiles: File[]) => {
    setValue("files", newFiles);
    setUploadFileStatus(
      newFiles.map((file) => ({ file, isError: false, isUploaded: false, isProcessed: false })),
    );
  };

  // If user is not logged in this is a user not enabled on the
  // feature flag, then redirect to home
  if (!window.currentUser || (devCycleReady && !isEnabled)) {
    return <Redirect to="/" />;
  }

  return (
    <DetailsPage>
      <DetailsContent>
        <form>
          <div className={css.container}>
            <div className={css.leftColumn}>
              <div className={css.title}>
                <Text h1>Upload tender documents</Text>
                <Text h2>Qualify this tender</Text>
              </div>
              <div>
                <Text h3 className={css.stepTitle}>
                  <UIcon svg={linkExternal02} className={css.tooltipIcon} size={16} />
                  Step 1
                </Text>
                <p>
                  Go to the{" "}
                  {portalUrl ? (
                    <TextLink
                      to={portalUrl}
                      targetType="new-tab"
                      className={css.portalLink}
                      eventName={EventNames.navToSourceDocs}
                      eventAttributes={{
                        "Action type": "Link clicked",
                      }}
                    >
                      portal
                    </TextLink>
                  ) : (
                    "portal"
                  )}{" "}
                  to download the tender documents published by the buyer
                </p>
              </div>
              <div>
                <Text h3>
                  <UIcon svg={upload01} className={css.tooltipIcon} size={16} />
                  Step 2
                </Text>
                <p>Upload the documents here</p>
              </div>
              <div>
                <Text h3>
                  <UIcon svg={starts02} className={css.tooltipIcon} size={16} />
                  Step 3
                </Text>
                <p>We'll generate a qualification report based on default criteria.</p>
              </div>
              <div>
                <Text h3>
                  <UIcon svg={fileCheck02} className={css.tooltipIcon} size={16} />
                  Step 4
                </Text>
                <p>Review & refine findings to reach a bid / no-bid decision</p>
              </div>
            </div>
            <div className={css.rightColumn}>
              <Controller
                control={control}
                name="files"
                render={({ field }) => (
                  <FileInput
                    {...field}
                    acceptedFileTypes=".doc, .docx, .pptx, .xlsx, .odt, .odp, .ods, .pdf, .zip"
                    errorMessage="Only certain file types are accepted"
                    onChange={(files) => updateFiles(files)}
                    onUnsupportedFilesError={(errors) => {
                      setFileErrors(errors);
                    }}
                    title="Click or drag tender documents here to upload"
                    listFileNamesInline={false}
                  />
                )}
              />
              {fileErrors.length > 0 && (
                <div className={css.errorContainer}>
                  <p className={css.errorText}>Invalid file types:</p>
                  {fileErrors.map((error, index) => (
                    <p key={index} className={css.errorText}>
                      {error}
                    </p>
                  ))}
                </div>
              )}
              <Text h3 className={css.uploadedDocuments}>
                Uploaded documents {files.length > 0 && `(${files.length})`}
              </Text>
              {files.length === 0 && <p>Your uploaded documents will appear here</p>}
              <div className={css.files}>
                {files.map((file, index) => (
                  <div key={file.name + index} className={css.file}>
                    <div className={css.uploadedFile}>
                      <UIcon svg={fileCheck02} className={css.tooltipIcon} size={16} />
                      <EllipsisTooltipText fullText={file.name} />
                    </div>
                    {uploadFileStatus[index]?.isUploaded ? (
                      <UIcon svg={checkOutlined} className={css.tooltipIcon} size={16} />
                    ) : (
                      <Tooltip title="Delete">
                        <Button
                          type="text"
                          shape="circle"
                          icon={<UIcon svg={trash01} className={css.tooltipIcon} size={16} />}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            const updatedFiles = [...files];
                            updatedFiles.splice(index, 1);
                            setValue("files", updatedFiles);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                ))}
              </div>
              <Button
                type="primary"
                disabled={files.length === 0}
                loading={filesUploading}
                className={css.generateBtn}
                onClick={startUpload}
              >
                Generate report
              </Button>
            </div>
          </div>
        </form>
      </DetailsContent>
    </DetailsPage>
  );
}
