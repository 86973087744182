import React from "react";

import { ButtonLink, TextLink } from "components/actions/Links";
import * as tracking from "lib/tracking";

import css from "./BidModuleExternalHeader.module.scss";

/**
 * Header which is used for external users that aren't logged in
 * @returns
 */
export const bidModuleCustomHeader: () => JSX.Element = () => {
  return (
    <div className={css.linksContainer}>
      <TextLink to="/users/sign_in" className={css.logInLink}>
        Log in
      </TextLink>

      <ButtonLink
        to="/get-started"
        className={css.signUpButton}
        eventName={tracking.EventNames.clickedSignUpCTA}
        eventAttributes={{
          "Context source": "Scorecard Screen",
        }}
      >
        Free sign up
      </ButtonLink>
    </div>
  );
};
