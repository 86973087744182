import React from "react";
import { Button, Modal } from "antd5";
import { useLocation } from "wouter";

import { useDeleteBid } from "../../../lib/hooks/api/bid/bids/useDeleteBid";
import { EventNames, useTracking } from "../../../lib/tracking";

import css from "./ReportModals.module.scss";

export function DeleteBidModal({
  bidId,
  noticeId,
  procurementStageId,
  isOpen,
  onClose,
}: {
  bidId: string;
  noticeId: string;
  procurementStageId: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { mutateAsync: deleteBidMutation } = useDeleteBid(procurementStageId, {
    onSuccess: async () => {
      setLocation(`/records/${noticeId}`);
    },
  });
  const { logEvent } = useTracking();
  const [, setLocation] = useLocation();

  async function deleteBid() {
    logEvent(EventNames.qualificationReportDeleted, {
      "Action type": "Button clicked",
    });
    await deleteBidMutation({ input: { bidId } });
  }

  return (
    <Modal
      title="About to delete report"
      open={isOpen}
      closable={false}
      footer={
        <div className={css.footer}>
          <Button onClick={onClose}>Keep report</Button>
          <Button htmlType="submit" onClick={deleteBid} className={css.deleteButton}>
            Delete report
          </Button>
        </div>
      }
      onCancel={onClose}
    >
      Are you sure you want to delete this report? This action cannot be undone.
    </Modal>
  );
}

export function RegenerateScorecardModal({
  isOpen,
  onClose,
  regenerateBidMutation,
}: {
  isOpen: boolean;
  onClose: () => void;
  regenerateBidMutation: () => void;
}) {
  const { logEvent } = useTracking();

  return (
    <Modal
      title="Regenerate report"
      open={isOpen}
      closable={false}
      footer={
        <div className={css.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              logEvent(EventNames.qualificationReportRegenerated, {
                "Action type": "Button clicked",
              });
              onClose();
              regenerateBidMutation();
            }}
          >
            Regenerate report
          </Button>
        </div>
      }
      onCancel={onClose}
    >
      You're about to regenerate all AI responses in this report. Please note, this action cannot be
      undone.
    </Modal>
  );
}
