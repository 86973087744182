import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import FilterCollapse from "components/filter_form/filter_collapse/FilterCollapse";
import FilterCollapseHeader from "components/filter_form/filter_collapse_header/FilterCollapseHeader";
import FilterFormTitle from "components/filter_form/filter_form_title/FilterFormTitle";
import { countFilters } from "components/filter_form/utils";
import { camelToSentence } from "lib/utils";
import { opportunityFilterContents } from "./filter_groups/utils";
import { EMPTY_OPPORTUNITY_FILTERS, OpportunityFilters } from "./utils";

export const groupedKeys: Record<FilterGroupKeys, (keyof OpportunityFilters)[]> = {
  assignedTo: ["assignedToIds"],
  stage: ["stageIds"],
  closeDate: ["closeDate"],
  value: ["value"],
  lastUpdated: ["lastUpdatedDate"],
  buyers: ["buyers", "buyerLists", "buyerCategories", "buyerCountryRegions"],
};

type FilterGroupKeys = keyof typeof opportunityFilterContents;

type FilterFormProps = {
  onChange: (filters: OpportunityFilters) => void;
  filters: OpportunityFilters;
  onClose: () => void;
  activeGroups: string[];
  onActiveGroupsChange: (group: string[]) => void;
};

export function FilterForm({
  onChange,
  filters,
  onClose,
  activeGroups,
  onActiveGroupsChange,
}: FilterFormProps) {
  const { control, watch, handleSubmit, reset, setValue } = useForm<OpportunityFilters>({
    mode: "onChange",
    defaultValues: filters,
  });

  const data = watch();

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        onChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, onChange, watch]);

  function clearAll() {
    reset({ ...EMPTY_OPPORTUNITY_FILTERS, searchText: filters.searchText });
  }

  const counts = countFilters(filters, groupedKeys);

  function clearFilter(key: FilterGroupKeys) {
    const keys = groupedKeys[key];
    keys.forEach((k) => {
      setValue(k, EMPTY_OPPORTUNITY_FILTERS[k]);
    });
  }

  const filterProps = {
    control,
    data,
  };

  function getFilterGroups() {
    const items = (Object.keys(groupedKeys) as FilterGroupKeys[]).map((key) => {
      const ContentComponent = opportunityFilterContents[key];

      return {
        key,
        header: (
          <FilterCollapseHeader
            title={camelToSentence(key)}
            count={counts[key]}
            clear={() => clearFilter(key)}
          />
        ),
        content: <ContentComponent {...filterProps} />,
      };
    });

    return items;
  }

  const items = getFilterGroups();

  return (
    <form aria-label="Opportunity filters">
      <FilterFormTitle
        clearFilters={clearAll}
        showClear={Object.values(counts).some(Boolean)}
        onClose={onClose}
      />
      <FilterCollapse items={items} value={activeGroups} onChange={onActiveGroupsChange} />
    </form>
  );
}
