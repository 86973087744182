import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { App, ConfigProvider } from "antd5";
import Tabs, { TabsProps } from "antd5/es/tabs";
import { breadcrumbHeaderHeight } from "styles/constants";

import { generateContactsUrl } from "components/contacts/utils";
import {
  DEFAULT_DOCUMENT_FILTERS,
  documentsUrl,
  simplifiedDocumentTypes,
} from "components/documents/utils";
import {
  DEFAULT_FILTERS as DEFAULT_FRAMEWORK_FILTERS,
  frameworksUrl,
} from "components/framework/utils";
import { generateNoticeSearchUrl } from "components/notices/utils";
import OpportunityBuyers from "components/opportunities/saved/OpportunityBuyers";
import { OpportunityContacts } from "components/opportunities/saved/OpportunityContacts";
import { OpportunitySuppliers } from "components/opportunities/saved/OpportunitySuppliers";
import Button from "components/ui/button/Button";
import EmptyState from "components/ui/empty_state/EmptyState";
import { ViewConfigurationFilterSettingsRelevanceScoreEnum as RelevanceScoreEnum } from "lib/generated/app-api";
import { DocumentCategory } from "lib/types/graphQLEnums";
import { ALL_KEYWORDS_TOKEN } from "lib/types/models";
import OpportunityCommentsSidebar from "../../components/opportunities/opportunity_comments_sidebar/OpportunityCommentsSidebar";
import OpportunityDetailSidebar from "../../components/opportunities/opportunity_detail_sidebar/OpportunityDetailSidebar";
import OpportunityDocuments from "../../components/opportunities/saved/OpportunityDocuments";
import OpportunityFrameworks from "../../components/opportunities/saved/OpportunityFrameworks";
import { OpportunityNotices } from "../../components/opportunities/saved/OpportunityNotices";
import OpportunitySavedRow from "../../components/opportunities/saved/OpportunitySavedRow";
import EditableText from "../../components/ui/editable_text/EditableText";
import CentredSpinner from "../../lib/core_components/CentredSpinner";
import {
  OpportunityDetails,
  useOpportunity,
} from "../../lib/hooks/api/opportunities/useOpportunity";
import { useUpdateOpportunity } from "../../lib/hooks/api/opportunities/useUpdateOpportunity";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { Flex, Text } from "../../styles/utility-components";

type OpportunityPageProps = {
  id: string;
};

const getEntitySearchUrls = (opportunity?: OpportunityDetails): Record<string, string> => {
  if (!opportunity) return {};

  const buyerIds = opportunity.buyers?.map((buyer) => buyer.id) ?? [];
  const relevantDocumentCategories = simplifiedDocumentTypes
    .filter((type) => type.value !== DocumentCategory.Other)
    .map((type) => type.value);

  const noticesSearchUrl = generateNoticeSearchUrl({
    relevanceScore: [RelevanceScoreEnum.Low, RelevanceScoreEnum.Medium, RelevanceScoreEnum.High],
    buyers: buyerIds,
  });

  const contactsSearchUrl = generateContactsUrl({ buyerGuids: buyerIds });

  const documentsSearchUrl = documentsUrl({
    ...DEFAULT_DOCUMENT_FILTERS,
    organisationIds: buyerIds,
    categories: relevantDocumentCategories,
  });

  const frameworksSearchUrl = frameworksUrl({
    ...DEFAULT_FRAMEWORK_FILTERS,
    buyerIds: buyerIds,
    keywords: [ALL_KEYWORDS_TOKEN],
  });

  return {
    noticesSearchUrl: noticesSearchUrl,
    contactsSearchUrl: contactsSearchUrl,
    documentsSearchUrl: documentsSearchUrl,
    frameworksSearchUrl: frameworksSearchUrl,
  };
};

export default function OpportunityPage({ id }: OpportunityPageProps) {
  const { message } = App.useApp();
  const theme = useTheme();

  const {
    data: opportunity,
    isLoading: opportunityLoading,
    isError: opportunityErrored,
  } = useOpportunity(id);

  const { mutate: updateOpportunity } = useUpdateOpportunity({
    onSuccess: () => {
      message.success("Opportunity updated successfully");
    },
    onError: (error) => {
      console.warn("Failed to update opportunity", error);
      message.error("Failed to update opportunity");
    },
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("details");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleTitleSave = (newValue: string) => {
    updateOpportunity({
      opportunity: {
        id,
        name: newValue,
      },
    });
  };

  const handleDescriptionSave = (newValue: string) => {
    updateOpportunity({
      opportunity: {
        id,
        description: newValue,
      },
    });
  };

  if (opportunityLoading) {
    return <CentredSpinner />;
  }

  if (opportunityErrored) {
    return (
      <Flex flexGrow={1} alignItems="center">
        <EmptyState
          type="loadError"
          heading="Sorry something has gone wrong loading this opportunity"
          description="If this error persists please contact support"
          width="100%"
          size="large"
        />
      </Flex>
    );
  }

  const tabs: TabsProps["items"] = [
    {
      key: "details",
      label: "Details",
      children: opportunity ? <OpportunityDetailSidebar opportunity={opportunity} /> : null,
    },
    {
      key: "comments",
      label: "Comments",
      children: opportunity ? <OpportunityCommentsSidebar opportunityId={opportunity.id} /> : null,
    },
  ];

  const { noticesSearchUrl, contactsSearchUrl, documentsSearchUrl, frameworksSearchUrl } =
    getEntitySearchUrls(opportunity);

  return (
    <Container>
      <BreadcrumbContainer>
        <Flex alignItems="center" gap={8} height={breadcrumbHeaderHeight}>
          <Button as="a" href="/opportunities" buttonType="tertiary">
            Opportunities
          </Button>
          <Text color={theme.colors.sysTextSecondary}> / </Text>
          <Text color={theme.colors.sysTextDefault}>{opportunity?.name}</Text>
        </Flex>
        <BreadcrumbButton onClick={toggleSidebar} aria-label="Toggle sidebar">
          <UIcon color={theme.colors.sysTextSecondary} icon="layoutRight" size={16} />
        </BreadcrumbButton>
      </BreadcrumbContainer>
      <Flex>
        <Content>
          <Header>
            <EditableText
              textProps={{ h2: true, bold: true, children: opportunity?.name ?? "" }}
              placeholder="Enter opportunity title"
              emptyText="Untitled"
              onSave={handleTitleSave}
            />
          </Header>
          <Body>
            <Flex column gap={16}>
              <Text h3 bold>
                Description
              </Text>
              <EditableText
                textProps={{
                  body: true,
                  color: "sysTextSecondary",
                  children: opportunity?.description ?? "",
                }}
                placeholder="Enter opportunity description"
                emptyText="No description"
                onSave={handleDescriptionSave}
              />
            </Flex>
            <Flex column gap={16}>
              <Text h3 bold>
                Saved
              </Text>
              <Flex column gap={64}>
                <OpportunitySavedRow entity="notice">
                  <OpportunityNotices
                    notices={opportunity?.notices ?? []}
                    emptyStateUrl={noticesSearchUrl}
                  />
                </OpportunitySavedRow>
                <OpportunitySavedRow entity="buyer">
                  <OpportunityBuyers buyers={opportunity?.buyers ?? []} />
                </OpportunitySavedRow>
                <OpportunitySavedRow entity="contact">
                  <OpportunityContacts
                    contacts={opportunity?.contacts ?? []}
                    emptyStateUrl={contactsSearchUrl}
                  />
                </OpportunitySavedRow>
                <OpportunitySavedRow entity="supplier">
                  <OpportunitySuppliers suppliers={opportunity?.suppliers ?? []} />
                </OpportunitySavedRow>
                <OpportunitySavedRow entity="document">
                  <OpportunityDocuments
                    documents={opportunity?.documents ?? []}
                    emptyStateUrl={documentsSearchUrl}
                  />
                </OpportunitySavedRow>
                <OpportunitySavedRow entity="framework">
                  <OpportunityFrameworks
                    frameworks={opportunity?.frameworks ?? []}
                    emptyStateUrl={frameworksSearchUrl}
                  />
                </OpportunitySavedRow>
              </Flex>
            </Flex>
          </Body>
        </Content>
        {isSidebarOpen && (
          <Sidebar aria-label="sidebar">
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    horizontalMargin: "0",
                    horizontalItemGutter: 0,
                    horizontalItemPaddingLG: "12px 68px",
                  },
                },
              }}
            >
              <SidebarTabs
                activeKey={activeTab}
                size="large"
                items={tabs}
                onChange={(key: string) => setActiveTab(key)}
              />
            </ConfigProvider>
          </Sidebar>
        )}
      </Flex>
    </Container>
  );
}

const Container = styled(Flex)({
  flexDirection: "column",
  flexGrow: 1,
  width: 480,
  overflowY: "hidden",
});

const BreadcrumbContainer = styled(Flex)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",

  padding: "0 16px",

  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const BreadcrumbButton = styled.button({
  all: "unset",
  cursor: "pointer",
});

const Header = styled(Flex)(({ theme }) => ({
  padding: "20px 32px",

  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const Content = styled(Flex)({
  flexDirection: "column",
  flexGrow: 1,
  width: "100%",
  height: `calc(100vh - ${breadcrumbHeaderHeight})`,
  overflowY: "auto",
});

const Body = styled(Flex)({
  flexDirection: "column",
  gap: 32,

  padding: 32,
});

const Sidebar = styled(Flex)(({ theme }) => ({
  width: 400,
  height: `calc(100vh - ${breadcrumbHeaderHeight})`,
  borderLeft: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const SidebarTabs = styled(Tabs)({
  width: "inherit", // prevents tabs from flickering
  overflowY: "auto",
});
