import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ClassNames } from "@emotion/react";
import { Input, Pagination } from "antd5";
import { isEmpty } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import FilterDrawer from "components/filter_form/filter_drawer/FilterDrawer";
import Button from "components/ui/button/Button";
import { useURLState } from "lib/hooks/useURLState";
import { useDebouncedValue } from "../../lib/debounce";
import { PaginationOptions } from "../../lib/generated/app-service-gql/graphql";
import { useOpportunities } from "../../lib/hooks/api/opportunities/useOpportunities";
import { Flex } from "../../styles/utility-components";
import { OpportunitiesTable } from "./opportunities_table/OpportunitiesTable";
import { FilterForm } from "./FilterForm";
import { QuickFilterBar } from "./QuickFilterBar";
import {
  convertOpportunityFiltersToSearchRequest,
  DEFAULT_PAGINATION,
  EMPTY_OPPORTUNITY_FILTERS,
  OpportunityFilters,
  parseOpportunitiesUrlState,
} from "./utils";

type FilterableOpportunitiesProps = {
  defaultFilters: OpportunityFilters;
};

export function FilterableOpportunities({ defaultFilters }: FilterableOpportunitiesProps) {
  const [filters = defaultFilters, setFilters] = useURLState<OpportunityFilters>(
    "opportunityFilters",
    defaultFilters,
    parseOpportunitiesUrlState,
  );
  const [debouncedFilters] = useDebouncedValue(filters, 500);
  const [pagination, setPagination] = useState<PaginationOptions>(DEFAULT_PAGINATION);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [activeGroups, setActiveGroups] = useState<string[]>([]);

  const { data, isLoading, isError } = useOpportunities(
    convertOpportunityFiltersToSearchRequest(debouncedFilters),
    pagination,
    { enabled: !isEmpty(debouncedFilters) },
  );

  useDeepCompareEffect(() => {
    setPagination(DEFAULT_PAGINATION);
  }, [debouncedFilters]);

  return (
    <Flex column gap={16}>
      <Flex gap={8}>
        <ClassNames>
          {({ css }) => (
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              value={filters.searchText ?? ""}
              onChange={(e) => {
                setFilters({ ...filters, searchText: e.target.value });
              }}
              allowClear
              className={css({
                maxWidth: 350,
              })}
            />
          )}
        </ClassNames>
        <Button
          as="button"
          buttonType="secondary"
          onClick={() => setIsFilterDrawerOpen(true)}
          icon="filterFunnel02"
        >
          All filters
        </Button>
      </Flex>
      <QuickFilterBar
        filters={filters}
        isLoading={isLoading}
        totalResults={data?.total ?? 0}
        onClearFilter={(key) => setFilters({ ...filters, [key]: undefined })}
        onFilterClick={() => setIsFilterDrawerOpen(true)}
        clearAllFilters={() =>
          setFilters({ ...EMPTY_OPPORTUNITY_FILTERS, searchText: filters.searchText })
        }
      />
      <OpportunitiesTable opportunities={data?.results} isLoading={isLoading} isError={isError} />
      <Pagination
        current={pagination.page}
        pageSize={pagination.pageSize}
        onChange={(page, pageSize) => setPagination({ page, pageSize })}
        total={data?.total}
        showSizeChanger
        align="center"
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onClose={() => setIsFilterDrawerOpen(false)}
            onChange={setFilters}
            filters={filters}
            activeGroups={activeGroups}
            onActiveGroupsChange={setActiveGroups}
          />
        )}
      </FilterDrawer>
    </Flex>
  );
}
