import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { GetOrQualifyBidQuery, QualifyBidRequest } from "lib/generated/app-service-gql/graphql";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "lib/hooks/api/utils";
import { useGraphQlQuery } from "../../useGraphQlClient";
import { getBidQualContentForExternalUser } from "../public/getBidQualContentForExternalUser";

const getOrQualifyBid = graphql(`
  query getOrQualifyBid($request: QualifyBidRequest!) {
    getOrQualifyBid(QualifyBidRequest: $request) {
      result {
        groupName
        questions {
          answerId
          title
          order
          answer {
            answer
          }
          sources {
            document
            documentId
            annotations
            chunkNumber
            locations {
              pageNumber
              bbox {
                x
                y
                width
                height
              }
            }
          }
        }
      }
    }
  }
`);

export function useGetOrQualifyBid(
  params: QualifyBidRequest,
  urlShareSignature: string | null,
  options?: UseQueryOptions<GetOrQualifyBidQuery, unknown, GetOrQualifyBidQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["bid_qualify_bid", params.procurementStageId, params.bidId],
    getOrQualifyBid,
    [{ request: params }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
      enabled: !!window.currentUser,
    },
  );

  const { data: nonLoggedInData, ...other } = useQuery(
    ["bid_qualify_bid", params.procurementStageId, params.bidId, urlShareSignature],
    () => {
      if (urlShareSignature) {
        return getBidQualContentForExternalUser(params.bidId, urlShareSignature);
      }
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      enabled: !window.currentUser,
    },
  );

  if (window.currentUser) {
    return {
      data: data?.getOrQualifyBid?.result,
      ...rest,
    };
  } else {
    return {
      data: nonLoggedInData?.result,
      ...other,
    };
  }
}
