import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  BidDocumentsQuery,
  BidDocumentsRequest,
  BidDocumentsResponse,
} from "../../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../../utils";
import { getBidDocumentsForExternalUser } from "../public/getBidDocumentsForExternalUser";

const BID_DOCUMENTS_QUERY = graphql(`
  query bidDocuments($request: BidDocumentsRequest!) {
    bidDocuments(BidDocumentsRequest: $request) {
      items {
        id
        fileName
      }
    }
  }
`);

export type BidDocumentsDto = BidDocumentsResponse["items"][0];

export function useBidDocuments(
  bidDocumentsRequest: BidDocumentsRequest,
  urlShareSignature: string | null,
  options?: UseQueryOptions<BidDocumentsQuery, unknown, BidDocumentsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["bidDocuments", bidDocumentsRequest],
    BID_DOCUMENTS_QUERY,
    [{ request: bidDocumentsRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
      enabled: !!window.currentUser,
    },
  );

  const { data: nonLoggedInData, ...other } = useQuery(
    ["bid_qualify_bid", bidDocumentsRequest.bidId, urlShareSignature],
    () => {
      if (urlShareSignature) {
        return getBidDocumentsForExternalUser(bidDocumentsRequest.bidId, urlShareSignature);
      }
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      enabled: !window.currentUser,
    },
  );

  if (window.currentUser) {
    return {
      data: data?.bidDocuments,
      ...rest,
    };
  } else {
    return {
      data: nonLoggedInData,
      ...other,
    };
  }
}
