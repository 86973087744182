import React from "react";
import { Skeleton } from "antd5";

import UserInitials, { UnassignedIcon } from "components/comments/UserInitials";
import { AnswerMarkdown } from "./AnswerMarkdown";

import css from "./AiQuestionAndAnswer.module.scss";

import SPARKLES from "../../../assets/images/icons/sparkles.svg";

type AiQuestionAndAnswerProps = {
  question: string;
  answer: string | undefined; // Undefined for when an answer is not available yet
  trackingLinkContext?: (guid: string) => void;
};
/**
 * Generic component for displaying a question and answer pair from our AI
 * @param param0
 * @returns
 */
export function AiQuestionAndAnswer({
  question,
  answer,
  trackingLinkContext,
}: AiQuestionAndAnswerProps) {
  const currentUser = window.currentUser;
  return (
    <>
      <div className={css.userAnswerRow}>
        {window.guestUser ? (
          <UnassignedIcon showTooltip={false} />
        ) : (
          <UserInitials
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            className={css.avatar}
          />
        )}
        <div className={css.userQuestion}>
          <p className={css.userHeader}>{window.guestUser ? "You" : currentUser?.first_name}</p>
          <p>{question}</p>
        </div>
      </div>
      {answer ? (
        <div className={css.ariAnswerRow}>
          <img src={SPARKLES} height={32} alt="sparkles" className={css.sparkles} />
          <div className={css.ariAnswer}>
            <p className={css.ariHeader}>Stotles AI</p>
            <AnswerMarkdown answer={answer} trackingLinkContext={trackingLinkContext} />
          </div>
        </div>
      ) : (
        <Skeleton active avatar />
      )}
    </>
  );
}
