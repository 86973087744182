import React from "react";
import { Button } from "antd5";

import { EventNames, useTracking } from "lib/tracking";

import css from "./SuggestedBidQuestions.module.scss";

type BidPrompt = {
  title: string;
  prompt: string;
};
type Props = {
  onPromptClick: (question: string) => void;
};

export default function SuggestedBidQuestions({ onPromptClick }: Props) {
  const prompts: BidPrompt[] = [
    {
      title: "Financial requirements",
      prompt:
        "Search the documents for mentions of financial standing, financial requirements, financial risk, or economic standing. Provide context and details about any financial criteria or assessments mentioned.",
    },

    {
      title: "Attachments Required",
      prompt:
        "Review all documents and return the names, contact details and roles of all the main contacts for this tender.",
    },
  ];

  const { logEvent } = useTracking();

  return (
    <div className={css.suggestedQuestions}>
      {prompts.map(({ title, prompt }, index) => (
        <Button
          key={`${index}`}
          onClick={() => {
            onPromptClick(prompt);
            logEvent(EventNames.promptActioned, {
              "Prompt selection": prompt,
              "Input actioned": "Suggestion button",
              "Context source": "Bid Module",
            });
          }}
        >
          <p className={css.title}>{title}</p>
          <p className={css.samplePrompt}>{prompt}</p>
        </Button>
      ))}
    </div>
  );
}
