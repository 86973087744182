import React, { useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd5";

import { TextArea } from "components/form_components/Inputs";
import { AiQuestionAndAnswer } from "lib/core_components/AiQuestionAndAnswer";
import { Scorecard_Result_Questions_Sources } from "lib/generated/app-service-gql/graphql";
import { useAskBidQuestion } from "lib/hooks/api/bid/scorecard/useAskBidQuestion";
import { EventData, EventNames, useTracking } from "lib/tracking";
import DocumentSources from "../DocumentSources";
import SuggestedBidQuestions from "./SuggestedBidQuestions";

import css from "./StotlesAiModal.module.scss";

type BidQaContent = {
  question: string;
  answer: string;
  sources: Scorecard_Result_Questions_Sources[];
};

type AIQuestionForm = {
  lastQuestion: string;
  qaPairs: BidQaContent[];
};

type Props = {
  bidId: string;
  procurementStageId: string;
  isOpen: boolean;
  onClose: () => void;
  onSelectDocument: (
    source: Scorecard_Result_Questions_Sources,
    trackingContext: EventNames,
    trackingData: EventData,
  ) => Promise<void>;
  documentDrawerOpen: boolean;
};

export default function StotlesAiModal({
  bidId,
  procurementStageId,
  isOpen,
  onClose,
  onSelectDocument,
  documentDrawerOpen,
}: Props) {
  const { logEvent } = useTracking();

  // Used to scroll to the bottom of the question list
  const containerRef = useRef<HTMLDivElement>(null);
  const { control, handleSubmit, setValue, watch } = useForm<AIQuestionForm>({
    defaultValues: {
      qaPairs: [],
      lastQuestion: "",
    },
  });

  const { lastQuestion } = watch();
  const { fields, append } = useFieldArray({
    control,
    name: "qaPairs", // This matches the defaultValues key
  });

  const scrollToBottomOfAiChat = () => {
    setTimeout(() => {
      containerRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const {
    mutate: askBidQuestion,
    isLoading: isAskingQuestion,
    reset: resetMutation,
  } = useAskBidQuestion({
    onSuccess: (data, variables) => {
      if (data.askBidQuestion.answer) {
        // Append new question-answer pair to the list
        append({
          question: variables.req.question,
          answer: data.askBidQuestion.answer,
          sources: data.askBidQuestion.sources ?? [],
        });
        setValue("lastQuestion", "");

        // Scroll to the bottom of the question list when question is answered
        scrollToBottomOfAiChat();
      }
    },
  });

  const handleQuestionSubmit = (question: string) => {
    if (question.length < 10) {
      return;
    }
    logEvent(EventNames.stotlesAiBidQuestionAsked, {
      "Action type": "Question asked",
      "Question asked": question,
    });

    askBidQuestion({
      req: { question, bidId, procurementStageId },
    });

    // Scroll to the bottom of the question list when question is asked
    scrollToBottomOfAiChat();
  };

  return (
    <Modal
      title={<h2 style={{ margin: 0 }}>Ask Stotles AI</h2>}
      open={isOpen}
      // When document drawer is open, make modal appear behind it
      zIndex={documentDrawerOpen ? 2 : undefined}
      footer={null}
      closable
      maskClosable
      className={css.modal}
      onCancel={() => {
        onClose();
        resetMutation();
      }}
    >
      <form onSubmit={handleSubmit(({ lastQuestion }) => handleQuestionSubmit(lastQuestion))}>
        <div className={css.qaPairs} ref={containerRef}>
          {/* Render existing Q&A pairs */}
          {fields.map(({ question, answer, sources }, index) => (
            <span key={index}>
              <AiQuestionAndAnswer
                key={index}
                question={question}
                answer={answer.replace(/\\n/g, "\n")}
              />
              {sources && sources.length > 0 && (
                <span className={css.sources}>
                  <DocumentSources
                    sources={sources}
                    onSelectDocument={(source) =>
                      onSelectDocument(source, EventNames.citationOpened, {
                        "Action type": "Link clicked",
                        "Criteria name": "Ad-hoc question",
                      })
                    }
                  />
                  {/* Could add the below if needed */}
                  {/* {debugMode && <pre>{JSON.stringify(question.sources, null, 2)}</pre>} */}
                </span>
              )}
            </span>
          ))}

          {/* Show suggested questions if there are no Q&A pairs */}
          {fields.length === 0 && !isAskingQuestion && (
            <SuggestedBidQuestions
              onPromptClick={(question) => {
                setValue("lastQuestion", question);
                handleQuestionSubmit(question);
              }}
            />
          )}
          {/* Show the last question as a loading state if it's being asked */}
          {isAskingQuestion && <AiQuestionAndAnswer question={lastQuestion} answer={undefined} />}

          {/* Used to scroll to the bottom of the question list */}
          <span style={{ visibility: "hidden" }} />
        </div>

        <div className={css.inputContainer}>
          <TextArea
            autoSize
            autoFocus
            name="lastQuestion"
            label={""}
            control={control}
            className={css.questionInput}
            placeholder="Ask a question about the documents..."
            rules={{
              required: "Please enter a question",
              minLength: { value: 10, message: "Please enter a question" },
            }}
            disabled={isAskingQuestion}
            aria-label="Ask a question"
            onPressEnter={(e) => {
              if (e.shiftKey) {
                // Allow newline when Shift + Enter is pressed
                return;
              }
              // Prevent newline and trigger submission on Enter without Shift
              e.preventDefault();
              e.stopPropagation();
              handleQuestionSubmit(e.currentTarget.value);
            }}
          />
          {isAskingQuestion ? (
            <Spin />
          ) : (
            <Button
              type={"primary"}
              htmlType={"submit"}
              shape="circle"
              aria-label="Ask question"
              icon={<ArrowUpOutlined />}
            />
          )}
        </div>

        <p className={css.disclaimer}>
          AI-generated responses may be inaccurate. Verify important information.
        </p>
      </form>
    </Modal>
  );
}
