import React from "react";
import styled from "@emotion/styled";
import { Flex } from "styles/utility-components";

import SendInviteModal from "components/modals/SendInviteModal";
import Button from "components/ui/button/Button";
import { useDialogManager } from "lib/providers/DialogManager";
import { routes, settingsRoutes } from "lib/routes";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { WidgetTitle } from "./util";

const eventData = {
  "Context source": "Homepage quick links",
  "Page source": "Homepage",
} as EventData;

function QuickLinks() {
  const dialogManager = useDialogManager();
  const { logEvent } = useTracking();

  return (
    <Flex column gap={16}>
      <WidgetTitle>Quick links</WidgetTitle>
      <Links>
        <Button
          icon="arrowUpRight"
          as="a"
          href={settingsRoutes.subscription}
          onClick={() => logEvent(EventNames.paywallActioned, { ...eventData, Action: "Upgrade" })}
          buttonStyle="inverse"
          justifyContent="left"
        >
          Upgrade plan
        </Button>
        <Button
          icon="usersPlus02"
          onClick={() => dialogManager.openDialog(SendInviteModal, {})}
          buttonStyle="inverse"
          justifyContent="left"
        >
          Add team members
        </Button>
        <Button
          icon="signal01"
          as="a"
          href={settingsRoutes.signalSettings}
          onClick={() => logEvent(EventNames.relevanceScoreUpdateSettings, eventData)}
          buttonStyle="inverse"
          justifyContent="left"
        >
          Change signal settings
        </Button>
        <Button
          icon="integration"
          as="a"
          href={settingsRoutes.integrations}
          buttonStyle="inverse"
          justifyContent="left"
        >
          Setup an integration
        </Button>
        <Button
          icon="helpCircle"
          as="a"
          href={routes.help}
          target="_blank"
          onClick={() =>
            logEvent(EventNames.clickedHelpCentre, {
              ...eventData,
              "Destination url": routes.help,
            })
          }
          buttonStyle="inverse"
          justifyContent="left"
        >
          Visit our help centre
        </Button>
      </Links>
    </Flex>
  );
}

const Links = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(216px, 1fr))",
  gap: "8px",
  marginLeft: "-8px",
  color: theme.colors.sysTextSecondary,
}));

export default QuickLinks;
