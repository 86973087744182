import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { FindBidByIdQuery } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../../utils";

const findBidByIdQuery = graphql(`
  query FindBidById($id: String!) {
    findBidById(id: $id) {
      id
      createdAt
      updatedAt
      procurementStageId
      companyId
      publicHash
    }
  }
`);

export function useFindBidById(
  bidId: string,
  options?: UseQueryOptions<FindBidByIdQuery, unknown, FindBidByIdQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["bid", bidId], findBidByIdQuery, [{ id: bidId }], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return { data: data?.findBidById, ...rest };
}
