import * as React from "react";
import { hot } from "react-hot-loader/root";
import { Skeleton } from "antd5";
import { Route, Router, Switch } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { BasicContentBanner } from "components/banners/Banners";
import { bidModuleCustomHeader } from "components/bid_module/BidModuleExternalHeader";
import { RelatedContactsPage } from "components/contacts/RelatedContactsPage";
import NoticeDetailsContent from "components/record_details/NoticeDetailsContent";
import NoticeHeader from "components/record_details/NoticeHeader";
import RecordStage from "lib/core_components/RecordStage";
import { NoticeDetails, useNotice } from "lib/hooks/api/notices/useNotice";
import { useNoticeHighlighting } from "lib/hooks/api/notices/useNoticeHighlighting";
import useElementInViewport from "lib/hooks/useElementInViewport";
import { useSubscription } from "lib/providers/Subscription";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { getSignalTypes, RecordDetails, TenderStage } from "lib/types/models";
import { BidQualificationPage } from "../../components/bid_module/BidQualificationPage";
import { PaywallBanner } from "../../components/paywall/PaywallBanner";

import css from "./NoticeDetailsPage.module.scss";

type Props = {
  notice: RecordDetails & {
    record_family_id: number;
  };
};

type NoticeDetailsProps = {
  notice: NoticeDetails;
};

type NoticeDetailsContainerProps = {
  noticeGuid: string;
};

function NoticeDetailsPageWrapper({ notice }: Props) {
  const { logEvent } = useTracking();

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (
      urlSearchParams.has("src") &&
      urlSearchParams.get("src") === "email" &&
      window.currentUser
    ) {
      logEvent(EventNames.emailLinkClicked, {
        email: window.currentUser.email,
        Link: window.location.href,
      });
    }
  }, [logEvent]);

  return (
    <Router base={`/records/${notice.guid}`}>
      <Switch>
        <Route path="/related_contacts">
          <RelatedContactsPage record={notice} />
        </Route>
        <Route path="/bid_qualification/:bidId?">
          <BidQualificationPage
            noticeId={notice.guid}
            procurementStageId={notice.procurement_stage.id}
            noticeTitle={notice.name}
            buyerName={notice.buyer.name}
            buyerId={notice.buyer.guid}
            portalUrl={notice.url}
          />
        </Route>
        <Route path={notice.canonical_url_path}>
          <NoticeDetailsContainer noticeGuid={notice.guid} />
        </Route>
        {/* Default */}
        <Route>
          <NoticeDetailsContainer noticeGuid={notice.guid} />
        </Route>
      </Switch>
    </Router>
  );
}

function NoticeDetailsContainer({ noticeGuid }: NoticeDetailsContainerProps) {
  const { data: notice, isLoading } = useNotice(noticeGuid);

  if (isLoading) {
    return (
      <div className={css.skeletonWrapper}>
        <Skeleton className={css.skeleton} />
      </div>
    );
  }

  if (!notice) {
    return <div>No data found for this notice.</div>;
  }

  if (notice.redacted) {
    return (
      <div>
        <div className={css.redactedHeader}>
          <RecordStage stage={TenderStage.AWARDED} />
          <h1>{notice.name}</h1>
        </div>
        <PaywallBanner featureType="AWARDS" />
      </div>
    );
  }

  return <NoticeDetailsPage notice={notice} />;
}

function NoticeDetailsPage({ notice }: NoticeDetailsProps) {
  const subscription = useSubscription();

  const [topOfPageRef, topOfPageIsVisible] = useElementInViewport<HTMLDivElement>();

  const signalTypes = getSignalTypes(notice.signals ?? []);

  const { highlightTerms } = useNoticeHighlighting(notice.procurementStage.id);

  const hasAwards = subscription.hasDataTypes("AWARDS");

  return (
    <TrackingProvider
      data={{
        "Record GUID": notice.guid,
        "Signal score": notice.score ?? 0,
        Stage: notice.stage ?? "Unknown",
        "Signal types": signalTypes ? Object.keys(signalTypes) : [],
        Signals: signalTypes,
      }}
    >
      <div className={css.pageWrapper}>
        {!hasAwards && notice.stage === "AWARDED" && <BasicContentBanner />}
        <NoticeHeader
          notice={notice}
          headerHighlights={highlightTerms?.title || []}
          trackingContextName="Title header"
          disableTitleLink
          headerClassName={css.header}
          scrollClassName={css.scroll}
          showStage={topOfPageIsVisible}
          isTopOfPageVisible={topOfPageIsVisible}
        />
        <div ref={topOfPageRef} />
        <div className={css.contentContainer}>
          <NoticeDetailsContent
            notice={notice}
            noticeHighlights={highlightTerms}
            trackingContextName="Title header"
            containerClassName={css.columnsContainer}
          />
        </div>
      </div>
    </TrackingProvider>
  );
}

function getRecordSignalTypes(notice: RecordDetails) {
  const signalTypes = getSignalTypes(notice.signals);

  return {
    "Signal types": signalTypes ? Object.keys(signalTypes) : [],
    Signals: signalTypes,
  };
}

function AdminBarComp({ notice }: Props) {
  return <a href={`/admin/record_families/${notice.record_family_id}`}> View family</a>;
}

export default hot(
  withAppLayout(NoticeDetailsPageWrapper, (props: Props) => ({
    pageName: "Record Details",
    pageViewProps: {
      "Record GUID": props.notice.guid,
      "Signal score": props.notice.relevance_score ?? "0",
      Stage: props.notice.stage,
      ...getRecordSignalTypes(props.notice),
    },
    // Hide top navbar when a user is logged in & in bid module
    hideNavBar: !!window.currentUser && window.location.href.includes("bid_qualification"),

    // Use custom login component for bid module when user is not logged in
    hideUserItems: !window.currentUser && window.location.href.includes("bid_qualification"),
    displayCustomAction:
      !window.currentUser &&
      window.location.href.includes("bid_qualification") &&
      bidModuleCustomHeader(),

    AdminBarComp,
  })),
);
