import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Input } from "antd5";

import CountryFilter from "components/form_components/CountryFilter";
import { useDebouncedValue } from "lib/debounce";
import { SearchOrganisationsRequest } from "lib/generated/app-service-gql/graphql";
import { useStotlesApi } from "lib/stotlesApiContext";
import { DEFAULT_COUNTRY_CODES } from "lib/utils/signalSettingsUtils";
import { OrgWithStats } from "./types";

export type FilterType = {
  countryCode?: string[];
  textSearch: string;
  primaryRole: "Buyer" | "Supplier";
};

const MIN_SEARCH_CHARACTERS = 2;

function isValidSearch(filters: FilterType) {
  return (
    !!filters.textSearch &&
    filters.textSearch.length >= MIN_SEARCH_CHARACTERS &&
    filters.countryCode &&
    filters.countryCode.length > 0
  );
}

// TODO: use generic org search API
export function useOrgSearch(filters: FilterType): OrgWithStats[] {
  const buyerSearchResults = useBuyerSearch(filters, { enabled: filters.primaryRole === "Buyer" });
  const supplierSearchResults = useSupplierSearch(filters, {
    enabled: filters.primaryRole === "Supplier",
  });

  if (filters.primaryRole === "Buyer") {
    return buyerSearchResults;
  } else {
    return supplierSearchResults;
  }
}

function useSupplierSearch(filters: FilterType, opts: { enabled: boolean }): OrgWithStats[] {
  const api = useStotlesApi();

  const [debouncedFilters] = useDebouncedValue(filters, 300);

  const { data: searchResults } = useQuery(
    ["supplier_search_results", debouncedFilters],
    async () => {
      const text = debouncedFilters.textSearch;
      const searchByGuid = !!text.match(/[0-9a-fA-F-]{32,36}/);

      const textParams = searchByGuid ? { guids: [text] } : { text };

      const suppliersResponse = await api.searchSuppliers({
        country: debouncedFilters.countryCode,
        limit: 500,
        sort_order: "DESC",
        sort: "relevance",
        ...textParams,
      });

      return suppliersResponse.results.map((s) => ({
        ...s,
        // TODO: migrate supplier search to openApi to avoid manual typing
        guid: s.guid as string,
        is_stotles_verified: s.is_stotles_verified as boolean,
        country: s.country || "",
        // TODO: return real categories from the back end
        categories: [],
      }));
    },
    { enabled: opts.enabled && isValidSearch(debouncedFilters) },
  );

  return searchResults || [];
}

// This component is built particularly for the merge tools and is
//  therefore using the filters required for that

// It also fetches the extra stats per buyer and should for now only
// be used in buyer cleaning context

function useBuyerSearch(filters: FilterType, opts: { enabled: boolean }): OrgWithStats[] {
  const api = useStotlesApi();

  const [debouncedFilters] = useDebouncedValue(filters, 300);

  const { data: searchResults } = useQuery(
    ["buyer_search_results", debouncedFilters],
    async () => {
      const text = debouncedFilters.textSearch;
      const searchByGuid = !!text.match(/[0-9a-fA-F-]{32,36}/);

      const textParams = searchByGuid ? { guids: [text] } : { text };

      const buyersResponse = await api.searchBuyers({
        country: debouncedFilters.countryCode,
        limit: 500,
        sort_order: "DESC",
        sort: "relevance",
        include_archived: false,
        ...textParams,
      });

      return buyersResponse.results;
    },
    { enabled: opts.enabled && isValidSearch(debouncedFilters) },
  );

  const { data: buyerStatsMap } = useQuery(
    ["buyer_stats", searchResults?.map((b) => b.guid)],
    async () => await api.getBuyerStats(searchResults?.map((b) => b.guid) || []),
    {
      enabled: opts.enabled && searchResults && searchResults.length > 0,
    },
  );

  const { data: resultsWithStats } = useQuery(
    ["buyer_results_with_stats", searchResults, buyerStatsMap],
    () => {
      if (searchResults?.length && buyerStatsMap?.stats) {
        return searchResults.map((b) => ({
          ...b,
          stats: buyerStatsMap.stats[b.guid],
        }));
      } else return [];
    },
  );

  return resultsWithStats || [];
}

type SearchFiltersProps = {
  filters: FilterType;
  setFilters: (value: React.SetStateAction<FilterType>) => void;
  className?: string;
};

type SearchOrganisationsRequestProps = {
  filters: SearchOrganisationsRequest;
  setFilters: (value: React.SetStateAction<SearchOrganisationsRequest>) => void;
  className?: string;
};

// The filters are reused across buyer searches in the cleaning tool so we have a common spot for them here
export function SearchOrganisationsFilters({
  filters,
  setFilters,
  className,
}: SearchOrganisationsRequestProps): JSX.Element {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilters((filters) => ({ ...filters, textSearch: value }));
  };
  return (
    <div className={className}>
      <Input
        autoFocus
        placeholder="Search by name or guid"
        value={filters.textSearch || ""}
        onChange={handleInputChange}
      />
      <CountryFilter
        value={filters.countryCodes ? filters.countryCodes : DEFAULT_COUNTRY_CODES}
        showLabel={false}
        placeholder="Filter by country"
        onChange={(newValue) =>
          setFilters((filters) => ({
            ...filters,
            countryCode: newValue[0],
          }))
        }
      />
    </div>
  );
}

export function OrgSearchFilters({
  filters,
  setFilters,
  className,
}: SearchFiltersProps): JSX.Element {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilters((filters) => ({ ...filters, textSearch: value }));
  };
  return (
    <div className={className}>
      <Input
        autoFocus
        placeholder="Search by name or guid"
        value={filters.textSearch || ""}
        onChange={handleInputChange}
      />
      <CountryFilter
        value={filters.countryCode}
        showLabel={false}
        placeholder="Filter by country"
        onChange={(newValue) =>
          setFilters((filters) => ({
            ...filters,
            countryCode: newValue,
          }))
        }
      />
    </div>
  );
}
