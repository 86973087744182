import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { NEW_NOTICES_SEARCH, useVariableValue } from "../../../featureFlags";
import { NoticeQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { useRecord } from "../useRecord";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const noticeQuery = graphql(`
  query notice($id: String!, $useLegacySignalScore: Boolean!) {
    notice(noticeId: $id) {
      guid
      name
      description
      entityType
      stage
      qualification
      legacyRelevanceScore @include(if: $useLegacySignalScore)
      score @skip(if: $useLegacySignalScore)
      value
      currency
      url
      expiryDate
      closeDate
      publishDate
      buyerContactName
      buyerContactEmail
      buyerContactPhone
      procurementStage {
        id
        stage
        procurementProcessId
      }
      lots {
        lotNumber
        lotTitle
        value
        currency
        endDate
        suppliers {
          id
          guid
          name
        }
      }
      cpvCodes {
        code
        name
      }
      buyers {
        id
        guid
        name
        buyerUrl
        country
        town
        postalCode
        address
      }
      assignee {
        guid
        firstName
        lastName
      }
      seenByUsers {
        guid
        firstName
      }
      relatedFrameworkProcess {
        frameworkId
        frameworkName
        processType
      }
      signals {
        id
        name
        category
      }
      redacted
    }
  }
`);

export type NoticeDetails = NoticeQuery["notice"];

export function useNotice(
  noticeId: string,
  options?: UseQueryOptions<NoticeQuery, unknown, NoticeQuery, QueryKey>,
) {
  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  const { data, ...rest } = useGraphQlQuery(
    ["notice", noticeId, useOpenSearch],
    noticeQuery,
    [{ id: noticeId, useLegacySignalScore: !useOpenSearch }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  // TODO: Remove when we get rid of NEW_NOTICES_SEARCH flag
  const { data: legacyNotice } = useRecord(noticeId, {
    enabled: !useOpenSearch,
  });

  const notice = data?.notice && {
    ...data.notice,
    signals: useOpenSearch ? data.notice.signals : legacyNotice?.signals,
    score: useOpenSearch ? data.notice.score : data.notice.legacyRelevanceScore,
  };

  return { data: notice, ...rest };
}
