import React, { useMemo, useState } from "react";
import { Control, Controller, UseFormReset } from "react-hook-form";
import { Tag, Tooltip } from "antd5";
import { MigrateSignalsFormState } from "pages/app/OrgSignalMigrationPage";

import CentredSpinner from "lib/core_components/CentredSpinner";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { CompanyToMigrate } from "lib/hooks/api/admin/signal_migration/useCompaniesToMigrate";
import {
  SignalToMigrate,
  useSignalsToMigrate,
} from "lib/hooks/api/admin/signal_migration/useSignalsToMigrate";
import { SimpleTeamObj } from "lib/hooks/api/teams/useAdminTeams";
import SelectUnmigratedCompanies from "./SelectUnmigratedCompanies";
import SelectUnmigratedTeams from "./SelectUnmigratedTeams";

import css from "./SelectCompanySignalsStep.module.scss";

type Props = {
  control: Control<MigrateSignalsFormState, unknown>;
  reset: UseFormReset<MigrateSignalsFormState>;
};

function getTagColour(
  signalToMigrate: SignalToMigrate,
  selectedSignals: SignalToMigrate[],
): "green" | "red" | "blue" {
  const signalIsSelected = selectedSignals.find((s) => s.name === signalToMigrate.name);

  if (signalIsSelected) {
    return "green";
  }

  return signalToMigrate.mappedOrgs.length > 0 ? "blue" : "red";
}

function SelectCompanySignalsStep({ control, reset }: Props): JSX.Element {
  const [selectedCompany, setSelectedCompany] = useState<CompanyToMigrate>();

  // Sets team of whose signals we're migrating
  const [selectedTeam, setSelectedTeam] = useState<SimpleTeamObj>();

  const { data, isLoading } = useSignalsToMigrate(
    {
      feedSettingId: selectedTeam?.feedSettingsId || "",
      textSearch: "",
    },
    {
      enabled: !!selectedTeam?.feedSettingsId,
    },
  );

  const allSignals = useMemo(
    (): SignalToMigrate[] =>
      data?.signalsToMigrate.sort((a, b) => b.ids.length - a.ids.length) || [],
    [data],
  );

  const renderEmptyState = () => {
    return (
      <h2 className={css.noSignals}>
        <span>
          <b>Company: </b>
          {selectedCompany?.name}
        </span>
        <span>
          <b>Team: </b>
          {selectedTeam?.name}
        </span>
        <span>Either has no supplier signals, or none left to map</span>
      </h2>
    );
  };

  const renderSignalTag = (
    signal: SignalToMigrate,
    selectedSignals: SignalToMigrate[],
    setSignalsToMigrate: (signals: SignalToMigrate[]) => void,
  ) => {
    const isMapped = signal.mappedOrgs.length > 0;
    const tooltipTitle = isMapped ? `Mapped to ${signal.mappedOrgs.length} orgs` : null;

    return (
      <Tooltip title={tooltipTitle} key={signal.name}>
        <Tag
          onClick={() => {
            const hasSignal = selectedSignals.find((s) => s.name === signal.name);
            if (hasSignal) {
              setSignalsToMigrate(selectedSignals.filter((s) => s.name !== signal.name));
            } else {
              setSignalsToMigrate([...selectedSignals, signal]);
            }
          }}
          color={getTagColour(signal, selectedSignals)}
          className={css.tag}
        >
          <EllipsisTooltipText
            fullText={`${signal.name} (${signal.ids.length})`}
            containerClassname={css.selectedItem}
          />
        </Tag>
      </Tooltip>
    );
  };

  const renderContent = (
    selectedSignals: SignalToMigrate[],
    setSignalsToMigrate: (signals: SignalToMigrate[]) => void,
  ) => {
    if (allSignals.length === 0) {
      return isLoading ? <CentredSpinner /> : renderEmptyState();
    }
    return allSignals.map((sig) => renderSignalTag(sig, selectedSignals, setSignalsToMigrate));
  };

  return (
    <>
      <div className={css.pageContent}>
        <div className={css.selectComponents}>
          <SelectUnmigratedCompanies
            selectedCompany={selectedCompany}
            onCompanyChange={(newCompany) => {
              reset();
              setSelectedCompany(newCompany);
              setSelectedTeam(undefined);
            }}
          />
          {selectedCompany && (
            <SelectUnmigratedTeams
              currentCompanyId={selectedCompany.id}
              selectedTeam={selectedTeam}
              onTeamChange={setSelectedTeam}
            />
          )}
        </div>
        {/* Need to also check team here, otherwise if user goes to next step, then comes back...
         these tags are populated */}
        {!!selectedTeam?.feedSettingsId && (
          <Controller
            name="signals"
            control={control}
            render={({ field: { value: selectedSignals, onChange: setSignalsToMigrate } }) => (
              <div className={css.section}>
                <ul className={css.tagsContainer}>
                  {renderContent(selectedSignals, setSignalsToMigrate)}
                </ul>
              </div>
            )}
          />
        )}
      </div>
    </>
  );
}

export default SelectCompanySignalsStep;
