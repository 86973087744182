import React from "react";

import { Checkbox, DateRange } from "components/form_components/Inputs";
import { FilterContentProps, StyledFilterContent } from "./utils";

function CloseDateFilterContent({ control }: FilterContentProps) {
  return (
    <StyledFilterContent aria-label="Close date filter">
      <DateRange name="closeDate.filter" control={control} label="" />
      <Checkbox
        label=""
        name="closeDate.hideNulls"
        control={control}
        fieldLabel="Hide opportunities that don't have a close date"
      />
    </StyledFilterContent>
  );
}

export default CloseDateFilterContent;
