import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import PaywallModal from "components/paywall/PaywallModal";
import { FeatureType } from "components/paywall/paywallUtils";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { ProDataTypeSeparateBuyersSuppliers } from "lib/types/models";
import { useSubscription } from "./Subscription";

export function useConfirmUserDataSubscription(
  dataSubscription: ProDataTypeSeparateBuyersSuppliers,
  featureType: FeatureType,
): void {
  const { confirmUserDataSubscription } = usePaywall();
  useEffect(() => {
    // If it's a guest user we don't want to call paywalls
    if (!window.authToken) {
      confirmUserDataSubscription(dataSubscription, featureType, "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

type OpenPaywallModalOptions = {
  trackingData?: EventData;
  contextSource?: string;
  requiredDataSubscription?: ProDataTypeSeparateBuyersSuppliers;
  onCloseRedirect?: string;
};

type PaywallState = {
  isVisible: boolean;
  onCloseRedirect?: string;
  featureType?: FeatureType;
  trackingData?: EventData;
  contextSource?: string;
};

type PaywallContextValue = PaywallState & {
  confirmUserDataSubscription: (
    requiredDataSubscription: ProDataTypeSeparateBuyersSuppliers,
    featureType?: FeatureType,
    onCloseRedirect?: string,
  ) => void;
  openPaywallModal: (featureType: FeatureType, options?: OpenPaywallModalOptions) => void;
};

const PaywallContext = React.createContext<PaywallContextValue | null>(null);

export function PaywallProvider({
  children,
  trackingPageName,
  trackingExtraProps,
}: {
  children: React.ReactNode;
  trackingPageName?: string;
  trackingExtraProps?: unknown;
}): JSX.Element {
  const [state, setState] = useState<PaywallState>({
    isVisible: false,
    featureType: undefined,
  });
  const { hasDataTypes } = useSubscription();
  const tracking = useTracking();

  const openPaywallModal: PaywallContextValue["openPaywallModal"] = useCallback(
    (
      featureType,
      { requiredDataSubscription, trackingData, onCloseRedirect, contextSource } = {},
    ) => {
      tracking.logEvent(EventNames.paywallViewed, {
        "Paywall reason": "User not subscribed to data source",
        "Data source(s) required": requiredDataSubscription ?? "",
        "Feature type": featureType ?? "SUPPLIERS",
        "User's current data sources": window.currentUser?.data_type_subscriptions,
        "Page name": trackingPageName,
        "Other page info": trackingExtraProps,
      });

      setState((prevState) => ({
        ...prevState,
        isVisible: true,
        featureType,
        onCloseRedirect,
        trackingData,
        contextSource,
      }));
    },
    [tracking, trackingExtraProps, trackingPageName],
  );

  const confirmUserDataSubscription: PaywallContextValue["confirmUserDataSubscription"] =
    useCallback(
      (
        requiredDataSubscription: ProDataTypeSeparateBuyersSuppliers,
        featureType?: FeatureType,
        onCloseRedirect?: string,
      ): void => {
        const isVisible = !hasDataTypes(requiredDataSubscription);

        if (isVisible) {
          return openPaywallModal(featureType ?? "SUPPLIERS", {
            requiredDataSubscription,
            onCloseRedirect,
          });
        }

        setState((prevState) => ({
          ...prevState,
          isVisible: false,
          featureType,
        }));
      },
      [hasDataTypes, openPaywallModal],
    );

  const value = useMemo(
    () => ({ ...state, confirmUserDataSubscription, openPaywallModal }),
    [confirmUserDataSubscription, openPaywallModal, state],
  );
  return (
    <PaywallContext.Provider value={value}>
      {children}
      {state.isVisible && (
        <PaywallModal
          featureType={state.featureType ?? "SUPPLIERS"}
          onClose={() =>
            state.onCloseRedirect
              ? (window.location.href = state.onCloseRedirect)
              : setState({ ...state, isVisible: false })
          }
          isOpen={true}
          trackingData={state.trackingData}
          contextSource={state.contextSource}
        />
      )}
    </PaywallContext.Provider>
  );
}
export function usePaywall() {
  const context = useContext(PaywallContext);
  if (!context) {
    throw new Error("usePaywall must be used within a PaywallProvider");
  }
  return context;
}
