import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import HomepageContent from "components/homepage/HomepageContent";

export function HomepagePage() {
  const urlParams = new URLSearchParams(window.location.search);
  const currentView = urlParams.get("current_view_id");
  const showNoView = urlParams.get("showNoView");

  // Redirect to notices (aka old homepage) if query params present are specific to the old homepage
  if (currentView || showNoView) {
    window.location.pathname = "/notices"; // Can't use wouter's redirect here, since they aren't under the same router
    return null;
  }

  return <HomepageContent />;
}

export default hot(
  withAppLayout(HomepagePage, {
    pageName: "Homepage",
  }),
);
