import React from "react";

import { QuickFilterBar as FilterBar } from "components/form_components/QuickFilterBar";
import { getAppliedFilters } from "components/form_components/utils";
import { OpportunityFilters, SortField } from "components/opportunities/utils";
import { EventDataTypes } from "lib/tracking";
import { OpportunitySortBy } from "lib/types/graphQLEnums";
import { capitalize, convertCamelCaseToSpaceSeparatedWords } from "lib/utils";

export const filterKeyToLabel: Record<keyof OpportunityFilters, string> = {
  assignedToIds: "Assignee",
  stageIds: "Stage",
  value: "Value",
  closeDate: "Close date",
  lastUpdatedDate: "Last updated",
  buyers: "Buyers",
  buyerCategories: "Buyer type",
  buyerLists: "Buyer lists",
  buyerCountryRegions: "Buyer location",
  searchText: "",
  orderBy: "",
  order: "",
};

const getSortKey = (key: SortField) => {
  switch (key) {
    case "updatedAt":
      return "lastUpdated";
    default:
      return key;
  }
};

export function QuickFilterBar({
  filters,
  onClearFilter,
  onFilterClick,
  clearAllFilters,
  isLoading,
  totalResults,
}: {
  filters: OpportunityFilters;
  onClearFilter: (value: keyof OpportunityFilters) => void;
  onFilterClick: (value: keyof OpportunityFilters) => void;
  clearAllFilters: () => void;
  isLoading: boolean;
  totalResults: number;
}) {
  const appliedFiltersMapped = getAppliedFilters(
    {
      ...filters,
      closeDate: {
        ...filters.closeDate?.filter,
        hideNulls: filters.closeDate?.hideNulls,
      },
      lastUpdatedDate: {
        ...filters.lastUpdatedDate?.filter,
        hideNulls: filters.lastUpdatedDate?.hideNulls,
      },
    },
    ["order", "orderBy"],
  );

  const sortKey = getSortKey(filters.orderBy ?? OpportunitySortBy.UpdatedAt);
  const sortField: string = capitalize(convertCamelCaseToSpaceSeparatedWords(sortKey));

  return (
    <FilterBar<OpportunityFilters>
      appliedFilters={appliedFiltersMapped}
      isLoading={isLoading}
      totalResults={totalResults}
      filterKeyToLabel={filterKeyToLabel}
      onClear={onClearFilter}
      onFilterClick={onFilterClick}
      sortField={sortField}
      dataType={EventDataTypes.buyer}
      hiddenFields={["searchText"]}
      clearAllFilters={clearAllFilters}
    />
  );
}
