import * as Sentry from "@sentry/react";

import { BidDocumentUrlResponse } from "lib/generated/app-service-gql/graphql";

// TODO: Long-term plan is to remove this public API and implement graphql federation instead

/**
 * Fetches a bid document URL for a non-logged in user
 * @returns
 */
export async function getBidDocumentUrlForExternalUser(
  bidId: string,
  documentId: string,
  urlShareSignature: string,
): Promise<BidDocumentUrlResponse> {
  try {
    const response = await fetch(`${window.app_service_url}/bid-api/getBidDocUrl`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bidId, documentId, urlShareSignature }),
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
    }

    return (await response.json()) as BidDocumentUrlResponse;
  } catch (error) {
    console.error("Failed to fetch bid document url: ", error);
    Sentry.captureException(error);
    throw error;
  }
}
