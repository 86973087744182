import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { countryToCountryCode } from "lib/data/optionItems";
import { GetSignalSettingsResponse, SignalDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { SignalCategory } from "lib/StotlesApi";
import { isDefined } from "lib/utils";
import { DEFAULT_COUNTRY_CODES } from "lib/utils/signalSettingsUtils";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

/**
 * Hook to fetch the signal settings for a team
 * @param options
 * @returns
 */
export function useSignalSettings(
  options?: UseQueryOptions<
    GetSignalSettingsResponse,
    unknown,
    GetSignalSettingsResponse,
    string[]
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["signalSettings"],
    async () => {
      const data = await api.getTeamSignalSettings();
      return data;
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
      enabled: !!window.currentUser,
    },
  );
}

/**
 * Gets user country codes from signal settings
 * @returns
 */
export function useSignalSettingCountryCodes() {
  const { data: signalSettings } = useSignalSettings();

  return (
    signalSettings?.countries
      ?.map((countryName) => countryToCountryCode(countryName))
      .filter(isDefined) ?? DEFAULT_COUNTRY_CODES
  );
}

export type NameKeys = "partnerNames" | "competitorNames" | "keywordNames" | "cpvCodeNames";
export type SignalCategories = {
  partners: SignalDto[];
  partnerNames: string[];
  competitors: SignalDto[];
  competitorNames: string[];
  keywords: SignalDto[];
  keywordNames: string[];
  cpvCodes: SignalDto[];
  cpvCodeNames: string[];
};
export function useSignalCategories(
  options?: UseQueryOptions<
    GetSignalSettingsResponse,
    unknown,
    GetSignalSettingsResponse,
    string[]
  >,
) {
  const { data: signals, ...rest } = useSignalSettings(options);
  const sortedSignals = signals?.signals.sort((a, b) => a.name.localeCompare(b.name));
  const categories = {
    keywords: sortedSignals?.filter((s) => s.category === SignalCategory.KEYWORD) || [],
    partners: sortedSignals?.filter((s) => s.category === SignalCategory.PARTNER) || [],
    competitors: sortedSignals?.filter((s) => s.category === SignalCategory.COMPETITOR) || [],
    cpvCodes: sortedSignals?.filter((s) => s.category === SignalCategory.CPV_CODE) || [],
  };

  const data: SignalCategories = {
    ...categories,
    keywordNames: categories.keywords.map((p) => p.name),
    partnerNames: categories.partners.map((p) => p.name),
    competitorNames: categories.competitors.map((p) => p.name),
    cpvCodeNames: categories.cpvCodes.map((p) => p.name),
  };

  return { ...rest, data };
}
