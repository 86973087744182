import * as Sentry from "@sentry/react";

import { Scorecard } from "lib/generated/app-service-gql/graphql";

// TODO: Long-term plan is to remove this public API and implement graphql federation instead

/**
 * Fetches bid qualification content for a non-logged in user
 * @returns
 */
export async function getBidQualContentForExternalUser(
  bidId: string,
  urlShareSignature: string,
): Promise<Scorecard> {
  try {
    const response = await fetch(`${window.app_service_url}/bid-api/getScorecard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bidId, urlShareSignature }),
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
    }

    return (await response.json()) as Scorecard;
  } catch (error) {
    console.error("Failed to fetch scorecard: ", error);
    Sentry.captureException(error);
    throw error;
  }
}
