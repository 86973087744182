import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { generateNoticeSearchUrl } from "components/notices/utils";
import Button from "components/ui/button/Button";
import { ModalBackgroundless } from "components/ui/modal/Modal";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { EventData, EventNames, logEvent } from "lib/tracking";
import { DEFAULT_VIEW_IDS } from "./Opportunities";
import { Box } from "./util";

import face from "../../../assets/images/homepage_video_face.png";
import thumbnail from "../../../assets/images/homepage_video_thumbnail.png";

const videoUrl =
  "https://5746318.fs1.hubspotusercontent-na1.net/hub/5746318/Tutorial%20-%20Find%20contract%20expiries%20-%202024_12.mp4";
const videoLength = "2 mins";

const learnMoreUrl = "https://help.stotles.com/how-to-use-expiring-contracts";

const eventData = {
  "Page source": "Homepage",
  "Context source": "Homepage banner",
} as EventData;

function Banner() {
  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [homepageBannerDisplay, setHomepageBannerDisplay] = useLocalStorage(
    "homepageBannerDisplay",
    true,
  );
  const onOpenVideo = async () => {
    logEvent(EventNames.videoViewed, eventData);
    void videoRef.current?.play();
  };

  if (!homepageBannerDisplay) {
    return null;
  }
  return (
    <BannerContainer>
      <BannerSideLeft>
        <Flex column gap={24}>
          <Flex column gap={4}>
            <Text h1 color="sysTextWhite">
              Build pipeline with expiring contracts
            </Text>
            <Text color="sysTextWhite">
              Use expiring contracts to pre-empt when a buyer is preparing a renewal, before it goes
              live to tender.
            </Text>
          </Flex>
          <Flex gap={8}>
            <Button
              as="a"
              href={generateNoticeSearchUrl({}, DEFAULT_VIEW_IDS.upcomingContractExpiries)}
              buttonType="secondary"
              onClick={() =>
                logEvent(EventNames.findOpportunityStarted, {
                  ...eventData,
                  "Data type": "AWARDS",
                  "Saved view id": DEFAULT_VIEW_IDS.upcomingContractExpiries,
                  "Saved view name": "Upcoming contract expiries",
                })
              }
            >
              Find expiring contracts
            </Button>
            <Button
              as="a"
              href={learnMoreUrl}
              target="_blank"
              onClick={() =>
                logEvent(EventNames.clickedHelpCentre, {
                  ...eventData,
                  "Destination url": learnMoreUrl,
                })
              }
            >
              Learn more
            </Button>
          </Flex>
        </Flex>
      </BannerSideLeft>
      <BannerSideRight>
        <ModalBackgroundless
          isOpen={modalOpen}
          modalClose={() => setModalOpen(false)}
          onOpen={() => onOpenVideo()}
          onClose={() => void videoRef.current?.pause()}
        >
          <VideoContainer
            autoFocus
            autoPlay
            controls
            controlsList="nodownload"
            poster={thumbnail}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setModalOpen(true);
              }
            }}
            ref={videoRef}
          >
            <source src={videoUrl} type="video/mp4" />
          </VideoContainer>
        </ModalBackgroundless>

        <ThumbnailButton onClick={() => setModalOpen(true)}>
          <ThumbnailImage src={thumbnail} />
          <FaceImage src={face} />
          <PlayIconContainer>
            <UIcon icon="playCircle" size={24} color="inherit" />
            {videoLength}
          </PlayIconContainer>
        </ThumbnailButton>
      </BannerSideRight>
      <CloseButtonWrapper>
        <CloseButtonContainer onClick={() => setHomepageBannerDisplay(false)}>
          <UIcon icon="xClose" size={20} color="inherit" />
        </CloseButtonContainer>
      </CloseButtonWrapper>
    </BannerContainer>
  );
}

const BannerSide = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
  flexBasis: 0,
}));

const BannerSideLeft = styled(BannerSide)({
  padding: "32px 24px",

  // Only other option with ButtonLink is to have a .scss file
  "& a": {
    width: "fit-content",
  },
});

const BannerSideRight = styled(BannerSide)({
  backgroundRepeat: "no-repeat",
  position: "relative",
  alignSelf: "normal",
});

const PlayIconContainer = styled.div(({ theme }) => ({
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translateX(-50%)", // Not doing translateY since it's already centered vertically
  color: theme.colors.sysTextDefault,
  backgroundColor: theme.colors.sysBrandYellowComplimentary,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "8px 16px",
  borderRadius: "200px",
  fontSize: "17px",
  fontWeight: 500,
}));

const ThumbnailButton = styled.button(({ theme }) => ({
  zIndex: 1,
  backgroundColor: "transparent",
  borderWidth: 0,
  padding: 0,
  aspectRatio: "16 / 9",
  cursor: "pointer",
  width: "100%",

  [`&:hover ${PlayIconContainer}`]: {
    backgroundColor: theme.colors.sysBrandYellowDefault,
    color: theme.colors.sysTextBlack,
  },
}));

const ThumbnailImage = styled.img();

const FaceImage = styled.img(({ theme }) => ({
  position: "absolute",
  borderWidth: "2px",
  borderColor: theme.colors.sysBackgroundDefault,
  borderStyle: "solid",
  borderRadius: "8px",
  backgroundColor: theme.colors.sysBackgroundDefault,
  width: "96px",
  aspectRatio: "1 / 1",
}));

const CloseButtonWrapper = styled.div({
  position: "relative",
  height: 0,
  width: 0,
});

const BannerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 0,
  flexWrap: "wrap",
  padding: 0,
  backgroundColor: theme.colors.sysPrimaryDefault,

  // To avoid repeating this breakpoint in multiple places, using [`${Component}] to target the component

  // Video to right of text
  [`@media screen and (min-width: ${992 + 0}px)`]: {
    flexDirection: "row",
    width: "100%",
    maxHeight: "250px",
    alignItems: "center",

    [`${BannerSideRight}`]: {
      padding: "22px 56px 0 0",
      maxHeight: "250px",
      maxWidth: "50%",
      clipPath: "inset(0% 0% 0% -20%)", // Need to clip thumbnail but not the face image
      marginBottom: "-1px", // Need as otherwise the background shows under with a 1px at some widths
    },
    [`${ThumbnailButton}`]: {
      alignSelf: "end",
      maxHeight: "225px",
      marginLeft: "min(52px, 5%)",
    },
    [`${ThumbnailImage}`]: {
      boxShadow: "6.154px 6.154px 18.462px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px 8px 0 0",
      width: "100%",
    },
    [`${FaceImage}`]: {
      left: "-24px", // There is already 24px padding on the left it uses
      bottom: "20px",
      boxShadow: "0px 4.8px 14.4px 0px rgba(0, 0, 0, 0.12)",
    },
    [`${CloseButtonWrapper}`]: {
      alignSelf: "start",
    },
  },
  // Video above text
  [`@media screen and (max-width: ${991 + 0}px)`]: {
    flexDirection: "column-reverse",
    height: "100%",

    [`${BannerSideRight}`]: {
      padding: "22px 56px 0 56px",
      alignSelf: "center",
    },
    [`${ThumbnailButton}`]: {
      maxHeight: "275px",
      width: "auto",
    },
    [`${ThumbnailImage}`]: {
      boxShadow: "5.215px 5.215px 15.646px 0px rgba(0, 0, 0, 0.25);",
      borderRadius: "8px",
      height: "100%",
      maxWidth: "100%",
    },
    [`${FaceImage}`]: {
      top: "36px",
      right: "32px", // Should really be 16px but that looks horrible on smaller screens and I couldn't figure out how to make it dynamic
      maxWidth: "16%",
      boxShadow: "0px 4.068px 12.204px 0px rgba(0, 0, 0, 0.12)",
    },
    [`${CloseButtonWrapper}`]: {
      alignSelf: "end",
    },
  },
}));

const VideoContainer = styled.video(() => ({
  aspectRatio: "16 / 9",
  borderRadius: "8px",
  width: "100%",
  "&:focus-visible": {
    outline: "none",
  },
}));

const CloseButtonContainer = styled.button(({ theme }) => ({
  position: "absolute",
  top: "4px",
  right: "4px",
  padding: "8px",
  color: theme.colors.sysTextWhite,
  backgroundColor: "transparent",
  border: "none",
  lineHeight: 0,
  cursor: "pointer",
  zIndex: 2,

  "&:hover": {
    color: theme.colors.sysBackgroundInactive,
  },
}));

export default Banner;
