import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { ContactsQuery, ContactsQueryVariables } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const contacts = graphql(`
  query contacts($request: SearchContactsRequest!) {
    contacts(SearchContactsRequest: $request) {
      results {
        id
        jobTitle
        title
        firstName
        lastName
        seniority
        jobFunction
        orgId
        buyer {
          id
          name
          isStotlesVerified
          categoryAssignments {
            categoryId
            source
          }
          logoUrl
        }
        email
        phone
        orgUrn
        oscarOrgName
        opportunities {
          id
        }
      }
      totalResults
    }
  }
`);

export type ContactResult = ContactsQuery["contacts"]["results"][number];

export function useContacts(
  request: ContactsQueryVariables["request"],
  options?: UseQueryOptions<ContactsQuery, unknown, ContactsQuery, QueryKey>,
) {
  return useGraphQlQuery(["contacts", request], contacts, [{ request }], { ...options });
}
