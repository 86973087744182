import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  UpdateBidPublicityMutation,
  UpdateBidPublicityMutationVariables,
} from "../../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const updateBidPublicity = graphql(`
  mutation updateBidPublicity($input: UpdateBidPublicityRequest!) {
    updateBidPublicity(UpdateBidPublicityRequest: $input) {
      id
    }
  }
`);

export function useUpdateBidPublicity(
  procurementStageId: string,
  options?: UseMutationOptions<
    UpdateBidPublicityMutation,
    unknown,
    UpdateBidPublicityMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useGraphQlMutation(updateBidPublicity, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      void queryClient.invalidateQueries(["bid", variables.input.bidId]);
      void queryClient.invalidateQueries(["bids", [procurementStageId]]);
    },
  });
  return { data: data?.updateBidPublicity, ...rest };
}
