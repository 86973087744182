import { AllBuyersStatsRequestAllBuyersStatsFilters } from "lib/generated/app-api";
import { parseNumberValue, useURLState } from "lib/hooks/useURLState";
import { SortState } from "lib/search/types";
import { isDefined } from "lib/utils";

const EMPTY_BUYER_FILTERS: AllBuyersStatsRequestAllBuyersStatsFilters = {};
export const DEFAULT_BUYER_FILTERS: AllBuyersStatsRequestAllBuyersStatsFilters = {
  supplierSme: "",
};
export const DEFAULT_BUYER_SORT: SortState = { field: "buyer_name", order: "ASC" };

function parseUrlFilterState(
  val?: AllBuyersStatsRequestAllBuyersStatsFilters,
  defaultFilters?: AllBuyersStatsRequestAllBuyersStatsFilters,
) {
  if (typeof val !== "object" || val === null) {
    return defaultFilters || DEFAULT_BUYER_FILTERS;
  }

  const filters = val as AllBuyersStatsRequestAllBuyersStatsFilters;

  return {
    ...filters,
    supplierIds: filters.supplierIds?.map(parseNumberValue).filter(isDefined),
  };
}

export function useFilterableBuyerTableState(
  defaultFilters: AllBuyersStatsRequestAllBuyersStatsFilters,
  defaultSort: SortState,
) {
  const [buyerTableFilters, setBuyerTableFilters] = useURLState(
    "buyerFilters",
    defaultFilters,
    (val) =>
      parseUrlFilterState(val as AllBuyersStatsRequestAllBuyersStatsFilters, EMPTY_BUYER_FILTERS),
  );

  const [buyerSort, setBuyerSort] = useURLState<SortState>(
    "sortState",
    defaultSort,
    (val) => val as SortState,
  );

  return {
    buyerTableFilters: buyerTableFilters || defaultFilters,
    setBuyerTableFilters,
    buyerSort: buyerSort || defaultSort,
    setBuyerSort,
  };
}
