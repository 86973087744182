import { Dispatch, SetStateAction } from "react";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { z } from "zod";

import { OptionItem } from "../data/optionItems";
import { SignalSettings } from "../hooks/api/teams/useSignalSettingsGQL";
import { SubscriptionProvider } from "../providers/Subscription";
import { SelfServeQuery } from "../StotlesApi";
import { Status } from "../types/models";

const signalSettingsErrorMessages = {
  countries: "Please select at least one country",
  languages: "Please select at least one language",
};

const ONE_MONTH_AGO = DateTime.now().minus({ months: 1 }).startOf("day").toJSDate();

export const createSignalSettingsSchema = (internationalDataAccess: boolean) =>
  z.object({
    countries: z.array(z.string()).min(1, { message: signalSettingsErrorMessages.countries }),
    languages: z
      .array(z.string())
      // If international data access is false,
      // then the languages field is required
      .refine((languages) => !internationalDataAccess || languages.length > 0, {
        message: signalSettingsErrorMessages.languages,
      }),
    keywords: z.array(z.string()).optional(),
    competitors: z.array(z.string()).optional(),
    partners: z.array(z.string()).optional(),
    cpvCodes: z.array(z.string()).optional(),
  });

export type SignalSettingsForm = {
  countries: string[];
  languages: string[];
  keywords: string[];
  competitors: string[];
  partners: string[];
  cpvCodes: string[];
};

export const defaultSignalSettings: SignalSettingsForm = {
  countries: [],
  languages: [],
  keywords: [],
  competitors: [],
  partners: [],
  cpvCodes: [],
};

export const getDefaultSignalSettings = (signals: SignalSettings): SignalSettingsForm => {
  return {
    countries: signals.countries ?? [],
    languages: signals.languages ?? [],
    keywords: signals.keywords ?? [],
    competitors: signals.competitors?.map((competitor) => competitor.id) ?? [],
    partners: signals.partners?.map((partner) => partner.id) ?? [],
    cpvCodes: signals.cpvCodes ?? [],
  };
};

export function parseSignalSettings({
  countries,
  languages,
  keywords,
  competitors,
  partners,
  cpvCodes,
}: {
  countries: string[];
  languages: string[];
  keywords: string[];
  competitors: { toString: () => string }[];
  partners: { toString: () => string }[];
  cpvCodes: string[];
}) {
  return {
    input: {
      countries,
      languages,
      keywords,
      competitorIds: competitors.map((competitor) => competitor.toString()),
      partnerIds: partners.map((partner) => partner.toString()),
      cpvCodes: cpvCodes,
    },
  };
}

export const generatePreviewFeedCriteria = (signals?: SignalSettingsForm): SelfServeQuery => {
  if (!signals) {
    const emptyQuery: SelfServeQuery = {
      countries: [],
      languages: [],
      keywords: {
        keywords: [],
        from_date: ONE_MONTH_AGO,
        status: [],
      },
    };

    return emptyQuery;
  }

  const SIX_MONTHS_AGO = DateTime.now().minus({ months: 6 }).toJSDate();
  const KEYWORD_STATUS: Status[] = ["PRE_TENDER", "OPEN"];

  return {
    countries: signals.countries ?? [],
    languages: signals.languages ?? [],
    keywords: {
      keywords: signals.keywords ?? [],
      from_date: ONE_MONTH_AGO,
      status: KEYWORD_STATUS,
    },
    competitors: {
      org_ids: signals.competitors ?? [],
      supplier_ids: [],
      supplier_names: [],
      from_date: SIX_MONTHS_AGO,
    },
    partners: {
      org_ids: signals.partners ?? [],
      supplier_ids: [],
      supplier_names: [],
      from_date: SIX_MONTHS_AGO,
    },
    cpv_codes: {
      codes: signals.cpvCodes ?? [],
    },
  };
};

export const updatePreviewFeedCriteria = (
  values: SignalSettingsForm,
  subscription: SubscriptionProvider,
  setPreviewFeedCriteria: Dispatch<SetStateAction<SelfServeQuery>>,
) => {
  const newCriteria = generatePreviewFeedCriteria(values);
  const hasAwards = subscription.hasDataTypes("AWARDS");
  const canSetBuyers = subscription.hasDataTypes("BUYERS");
  const canSetSuppliers = subscription.hasDataTypes("SUPPLIERS");

  // Remove anything that the user does not have access to.
  if (!hasAwards) {
    newCriteria.keywords.status = newCriteria.keywords.status.filter((x) => x !== "AWARDED");
  }
  if (!canSetSuppliers) {
    delete newCriteria["competitors"];
    delete newCriteria["partners"];
  }
  if (!canSetBuyers) {
    delete newCriteria["buyers"];
  }

  setPreviewFeedCriteria((prevCriteria) => {
    // Perform a deep comparison to avoid unnecessary state updates
    if (!isEqual(prevCriteria, newCriteria)) {
      return newCriteria;
    }
    return prevCriteria;
  });
};

const mapOptionItemsToSelectOptions = (optionItems: OptionItem[]) => {
  return optionItems.map((optionItem) => ({
    id: optionItem.code,
    label: optionItem.name,
    value: optionItem.name,
  }));
};

export const DEFAULT_COUNTRY_CODES = ["UK", "GB", "IE"];

const sortCountries = (a: OptionItem, b: OptionItem) => {
  const indexA = DEFAULT_COUNTRY_CODES.indexOf(a.code);
  const indexB = DEFAULT_COUNTRY_CODES.indexOf(b.code);

  if (indexA !== -1 && indexB !== -1) return indexA - indexB;
  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;
  return a.name.localeCompare(b.name);
};

const sortLanguages = (a: OptionItem, b: OptionItem) => {
  if (a.code === "EN") return -1;
  if (b.code === "EN") return 1;
  return a.name.localeCompare(b.name);
};

export const generateCountryOptions = (countryCodes: OptionItem[]) => {
  return mapOptionItemsToSelectOptions(countryCodes.sort(sortCountries));
};

export const generateLanguageOptions = (langCodes: OptionItem[]) => {
  return mapOptionItemsToSelectOptions(langCodes.sort(sortLanguages));
};
