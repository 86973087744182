import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { NoticesQuery, NoticesSearchRequest } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

export type NoticeSearchQueryKey = ["notices", NoticesSearchRequest, boolean | undefined];

const noticesQuery = graphql(`
  query notices($request: NoticesSearchRequest!, $includeSignals: Boolean!) {
    notices(NoticesSearchRequest: $request) {
      results {
        redacted
        score
        guid
        procurementStage {
          id
          stage
        }
        signals @include(if: $includeSignals) {
          id
          name
          category
        }
        country
        stage
        name
        entityType
        currency
        value
        publishDate
        closeDate
        awardDate
        expiryDate
        url
        buyers {
          id
          guid
          name
          categoryAssignments {
            categoryId
            categoryName
            parentCategoryId
            parentCategoryName
            source
          }
        }
        qualification
        suppliers {
          id
          name
          guid
          isSme
        }
        lists {
          id
          name
        }
        assignee {
          guid
          firstName
          lastName
        }
        comments {
          id
          content
        }
        seenByUsers {
          firstName
          guid
        }
        relatedFrameworkProcess {
          frameworkId
          frameworkName
          processType
        }
        opportunities {
          id
        }
      }
      totalResults
    }
  }
`);
type NoticesSearchResponse = NoticesQuery["notices"];
export type NoticesDto = NoticesSearchResponse["results"][0];

export function useNotices(
  searchNoticesRequest: NoticesSearchRequest,
  includeSignals: boolean = false,
  options?: UseQueryOptions<NoticesQuery, unknown, NoticesQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery<
    NoticesQuery,
    {
      request: NoticesSearchRequest;
      includeSignals: boolean;
    }
  >(
    ["notices", searchNoticesRequest, includeSignals],
    noticesQuery,
    [{ request: searchNoticesRequest, includeSignals }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: {
      ...data,
      results: data?.notices?.results ?? [],
      pagingInfo: { totalResults: data?.notices?.totalResults ?? 0 },
    },
    ...rest,
  };
}
